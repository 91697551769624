import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PagedQueryParameter, createProxy, toast } from '@nstep-common/utils';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten } from 'lodash';

import {
	DisableUserModel,
	DisableUserModelValidator,
	NationWithHqDtos,
	OperatorCardDto,
	ResetPasswordModel,
	ResetPasswordModelValidator,
	RoleDto,
	SubRoleDto,
	UserDto,
	UserModelValidator
} from '@nstep-internal/pages';

import { UserService, PosLocationService, NationService } from '@nstep-internal/shared';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html'
})
export class UsersComponent extends BaseComponent implements OnInit {
	@ViewChild('userModal') userModal!: ModalComponent;
	@ViewChild('enableUserConfimationModal') enableUserConfimationModal!: ModalComponent;
	@ViewChild('disableUserConfimationModal') disableUserConfimationModal!: ModalComponent;
	@ViewChild('resetPasswordModal') resetPasswordModal!: ModalComponent;

	errors: string[] = [];

	modalIsLoading = false;

	maxDate: string = '9999-12-31T23:59:59Z';

	tableData: any[] = [];
	tableDataReady = false;

	today = new Date(new Date().toDateString());

	calendarSettings = {
		type: 'date',
		minDate: this.today
	}

	searchSettings = {
		apiSettings: {
			responseAsync: (settings: any, callback: any) => {
				if (settings.urlData.query) {
					this.subscriptions.push(
						this.posLocationService.getOperatorCards(settings.urlData.query).subscribe({
							next: response => {
								callback(response)
							}
						})
					)
				}
			}
		},
		fields: {
			title: 'cardNumber'
		},
		minCharacters: 3
	}

	tableColumns: TableColumn[] = [
		{ name: 'Username', key: 'userName', sortAsc: true, isHeaderCentered: true, isCellCentered: true },
		{ name: 'Name', key: 'name', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Country', key: 'nation', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Type', key: 'type', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Telephone', key: 'telephone', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Other', key: 'other', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Start Date', key: 'startDate', isHeaderCentered: true, isCellCentered: true },
		{ name: 'End Date', key: 'endDate', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Actions', isHeaderCentered: true, isCellCentered: true },
	];

	roleDropdownValues: DropdownOption[] = [];
	roleDropdownDataReady = false;
	//rolesWithCountryDropdown: number[] = [3, 5];

	subRoleDropdownValues: DropdownOption[] = [];
	subRoleDropdownDataReady = false;


	countryDropdownValues: DropdownOption[] = [];
	countryDropdownDataReady = false;

	headquartersDropdownValues: DropdownOption[] = [];

	roles!: RoleDto[];
	subRoles!: SubRoleDto[]
	nations!: NationWithHqDtos[];
	selectedUser: UserDto = {} as UserDto;

	passwordType: string = 'auto';
	editModal: boolean = false;

	validation: ValidationErrors<UserDto> = {};
	disableValidation: ValidationErrors<DisableUserModel> = {};
	passwordResetValidation: ValidationErrors<ResetPasswordModel> = {};
	isValid: boolean = false;

	userModel: UserDto = createProxy(new UserDto(), {
		set: () => this.validate(this.userModel)
	});

	validate(value: UserDto): void {
		const validator = new UserModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	disableUserModel: DisableUserModel = createProxy(new DisableUserModel(), {
		set: () => this.validateDisableUser(this.disableUserModel)
	});

	validateDisableUser(value: DisableUserModel): void {
		const validator = new DisableUserModelValidator();
		this.disableValidation = validator.validate(value);
		this.isValid = Object.keys(this.disableValidation).length === 0;
	}

	resetPasswordModel: ResetPasswordModel = createProxy(new ResetPasswordModel(), {
		set: () => this.validateResetPassword(this.resetPasswordModel)
	});

	validateResetPassword(value: ResetPasswordModel): void {
		const validator = new ResetPasswordModelValidator();
		this.passwordResetValidation = validator.validate(value);
		this.isValid = Object.keys(this.passwordResetValidation).length === 0;
	}

	pagedQueryModel: PagedQueryParameter = {
		itemsPerPage: 1000,
		page: 1,
		orderField: '',
		searchBy: '',
		isMultiWordSerch: false
	}

	constructor(private userService: UserService,
		private nationService: NationService,
		private posLocationService: PosLocationService) {
		super();
	}

	ngOnInit(): void {

		this.userModel.role = new RoleDto();

		this.initializeTable();

		this.subscriptions.push(
			this.userService.getRoles().subscribe({
				next: response => {
					this.roles = response;

					this.roleDropdownValues = chain(this.roles)
						.map(e => new DropdownOption({
							value: e.roleId,
							name: e.roleName
						}))
						.orderBy(e => e.name)
						.value();

					this.roleDropdownDataReady = true;
				},
				error: () => {
					this.roleDropdownDataReady = true;
				}
			})
		)

		this.subscriptions.push(
			this.nationService.getNations().subscribe({
				next: response => {
					this.nations = response;

					this.countryDropdownValues = chain(this.nations)
						.map(e => new DropdownOption({
							value: e.id,
							name: e.nationName
						}))
						.orderBy(e => e.name)
						.value();

					this.countryDropdownDataReady = true;
				},
				error: () => {
					this.countryDropdownDataReady = true;
				}
			})
		)

		this.subscriptions.push(
			this.userService.getSubRoles().subscribe({
				next: response => {
					this.subRoles = response;

					this.subRoleDropdownValues = chain(this.subRoles)
						.map(e => new DropdownOption({
							value: e.id,
							name: e.name
						}))
						.orderBy(e => e.name)
						.value();

					this.subRoleDropdownDataReady = true;
				},
				error: () => {
					this.subRoleDropdownDataReady = true;
				}
			})
		)
	}

	initializeTable(): void {
		this.tableDataReady = false;
		this.tableData = [];

		this.subscriptions.push(
			this.userService.getUsers(this.pagedQueryModel).subscribe({
				next: response => {

					const dateNow = new Date();
					const today = new Date(new Date().toDateString());

					this.tableData = chain(response.body)
						.map(e => {
							return {
								id: e.id,
								userName: e.userName,
								name: e.name,
								email: e.email,
								telephone: e.telephone,
								other: e.other,
								startDate: formatDate(new Date(e.startDate), 'dd/MM/yyyy', 'en'),
								endDate: e.endDate && e.endDate !== this.maxDate ? formatDate(new Date(e.endDate), 'dd/MM/yyyy', 'en') : 'N/A',
								nationId: e.nationId,
								disabled: e.endDate ? new Date(new Date(e.endDate).toDateString()) < today : false,
								resetted: e.resetted,
								cardOperatorId: e.cardOperatorId,
								nation: e.nation?.nationName,
								type: e.role?.roleName,
								card: e.card,
								headquarters: e.headquarters,
								subRoles: e.subRoles,
								actions: e.endDate ? new Date(e.startDate).getTime() <= dateNow.getTime() && dateNow.getTime() <= new Date(e.endDate).getTime() ? ['Disable', 'Reset Password'] : ['Enable', 'Reset Password'] : ['Disable', 'Reset Password'],
							}
						})
						.value();

					this.tableDataReady = true;
					this.modalIsLoading = false;
				},
				error: () => {
					this.tableDataReady = true;
					this.modalIsLoading = false;
				}
			})
		)
	}

	pageChange(value: any): void {
		//this.pagedQueryModel.page = value.currentPage;
		//this.pagedQueryModel.itemsPerPage = value.pageSize;
		//console.log('pagination info:', value);
	}

	openUserModal(): void {
		this.editModal = false;

		this.userModel = createProxy(new UserDto(), {
			set: () => this.validate(this.userModel)
		});

		const defaultRole = this.roles.find(r => r.roleId === 3)!;

		this.userModel.role = new RoleDto({
			roleId: defaultRole.roleId
		});

		this.userModal.toggle();
	}

	search(): void {
		this.initializeTable();
	}

	changePasswordType(type: string, modal: string): void {
		this.passwordType = type;

		switch (modal) {
			case 'userModal':
				this.userModel.password = type === 'typed' ? '' : null;
				break;
			case 'resetPasswordModal':
				this.resetPasswordModel.password = type === 'typed' ? '' : null;
				break;
		}
	}

	close(modal: string): void {
		switch (modal) {
			case 'userModal':
				this.userModal.toggle();
				this.passwordType = 'auto';
				break;
			case 'enableUserConfimationModal':
				this.enableUserConfimationModal.toggle();
				break;
			case 'disableUserConfimationModal':
				this.disableUserConfimationModal.toggle();
				break;
			case 'resetPasswordModal':
				this.resetPasswordModal.toggle();
				this.passwordType = 'auto';
				break;
		}

		this.errors = [];
	}

	save(): void {
		this.errors = [];
		this.modalIsLoading = true;

		if (this.editModal) {
			this.subscriptions.push(
				this.userService.updateUser(this.userModel).subscribe({
					next: () => {
						this.userService.clearGetUsersCache();
						this.initializeTable();
						toast('Success', `User ${this.userModel.userName} successfully modified!`, 'green');

						this.userModal.toggle();
					},
					error: (response: { [key: string]: string[] }) => {
						this.modalIsLoading = false;

						toast('Error', `User ${this.userModel.userName} could not be modified!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		} else {
			this.subscriptions.push(
				this.userService.createUser(this.userModel).subscribe({
					next: () => {
						this.userService.clearGetUsersCache();
						this.initializeTable();
						toast('Success', `User ${this.userModel.userName} successfully created!`, 'green');

						this.userModal.toggle();
					},
					error: (response: { [key: string]: string[] }) => {
						this.modalIsLoading = false;

						toast('Error', `User ${this.userModel.userName} could not be created!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		}
	}

	onUserAction(item: UserDto, action: string): void {
		this.editModal = false;
		this.selectedUser = item;

		if (action === 'Edit') {
			this.editModal = true;
			this.subscriptions.push(
				this.userService.getUser(item.id).subscribe({
					next: (response) => {
						response.headquartersIds = response.headquarters.flatMap(h => h.id);

						this.userModel = createProxy(response, {
							set: () => this.validate(this.userModel)
						});

						this.validate(this.userModel);

						this.userService.clearGetUserCache(item.id);
						this.userModal.toggle();
					},
					error: () => {
						this.userService.clearGetUserCache(item.id);
					}
				})
			)

			return;
		}

		if (action === 'Reset Password') {
			this.subscriptions.push(
				this.userService.getUser(item.id).subscribe({
					next: (response) => {

						this.resetPasswordModel = createProxy(new ResetPasswordModel(), {
							set: () => this.validateResetPassword(this.resetPasswordModel)
						});

						this.resetPasswordModel.userId = response.id;
						this.resetPasswordModel.email = response.email;

						this.userService.clearGetUserCache(item.id);
						this.resetPasswordModal.toggle();
					},
					error: () => {
						this.userService.clearGetUserCache(item.id);
					}
				})
			)

			return;
		}

		this.openConfirmationModal(action);
	}

	openConfirmationModal(action: string): void {

		this.disableUserModel = createProxy(new DisableUserModel(), {
			set: () => this.validateDisableUser(this.disableUserModel)
		});

		switch (action) {
			case 'Enable':
				this.enableUserConfimationModal.toggle();
				break;
			case 'Disable':
				this.validateDisableUser(this.disableUserModel);
				this.disableUserConfimationModal.toggle();
				break;
		}
	}

	enableOrDisable(action: string | null): void {
		this.disableUserModel.userId = this.selectedUser.id;
		this.errors = [];
		this.modalIsLoading = true;

		this.subscriptions.push(
			this.userService.disableUser(this.disableUserModel).subscribe({
				next: () => {
					this.userService.clearGetUsersCache();
					this.initializeTable();
					if (action === 'Disable') {
						toast('Success', `User ${this.selectedUser.userName} successfully disabled!`, 'green');
						this.disableUserConfimationModal.toggle();
					} else {
						toast('Success', `User ${this.selectedUser.userName} successfully enabled!`, 'green');
						this.enableUserConfimationModal.toggle();
					}

				},
				error: (response) => {
					this.modalIsLoading = false;

					if (action === 'Disable') {
						toast('Error', `User ${this.selectedUser.userName} disabling encountered a problem!`, 'red');
						//this.disableUserConfimationModal.toggle();
					} else {
						toast('Error', `User ${this.selectedUser.userName} enabling encountered a problem!`, 'red');
						//this.enableUserConfimationModal.toggle();
					}

					this.errors = flatten(Object.values(response));
				}
			})
		)
	}

	resetPassword(): void {
		this.errors = [];
		this.modalIsLoading = true;

		this.subscriptions.push(
			this.userService.resetPassword(this.resetPasswordModel).subscribe({
				next: () => {
					this.modalIsLoading = false;

					toast('Success', `Password succcessfully reset for user ${this.selectedUser.userName}!`, 'green');
					this.resetPasswordModal.toggle();
				},
				error: (response) => {
					this.modalIsLoading = false;

					toast('Error', `"Error resetting password for user ${this.selectedUser.userName}!`, 'red');
					this.errors = flatten(Object.values(response));
				}
			})
		)
	}

	onCountryValueChange(): void {
		this.userModel.headquarters = [];
		this.userModel.headquartersIds = [];

		const currentNation = this.nations.find(n => n.id === this.userModel.nationId)!;

		if (currentNation) {
			this.headquartersDropdownValues = chain(currentNation.headquarters)
				.map(e => new DropdownOption({
					value: e.id,
					name: e.name
				}))
				.orderBy(e => e.name)
				.value();
		}
		else {
			this.headquartersDropdownValues = [];
		}
	}

	onSubRoleValueChange(): void {
		this.userModel.subRoles = [];

		if (!this.userModel.subRolesIds) {
			return;
		}

		this.userModel.subRolesIds.forEach(id => {
			const subRole = this.subRoles.find(sb => sb.id === id)!;
			this.userModel.subRoles.push({ id: subRole.id, name: subRole.name, roleId: subRole.roleId })
		});
	}

	onHeadquartersValueChange(): void {
		this.userModel.headquarters = [];

		if (!this.userModel.headquartersIds) {
			return;
		}

		this.userModel.headquartersIds.forEach(id => {
			const nation = this.nations.find(n => n.id === this.userModel.nationId)!;

			const headquarter = nation.headquarters.find(d => d.id === id)!;

			this.userModel.headquarters.push(headquarter);
		});
	}

	onAmisCardValueChange(value: OperatorCardDto): void {
		this.userModel.cardOperatorId = value?.id;
	}
}
