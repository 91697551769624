import { ExtraValidator } from "@nstep-common/utils";
import { BalanceHistoryModel } from "@nstep-internal/pages";

export class BalanceHistoryModelValidator extends ExtraValidator<BalanceHistoryModel> {
	constructor() {
		super();

		this.ruleFor('cardNumber')
			.must(cardNumber => !!cardNumber)
				.withMessage('Card number is required')
			.matches(/^([A-Za-z]{3})(-[0-9]{7})(-[0-9]{2})$/)
				.withMessage('Please provide a valid card number');
		
		this.ruleFor('date')
			.must(date => !!date)
				.withMessage('Date is required');
	}
}