import { ExtraValidator } from "@nstep-common/utils";
import { ExportModel, HeadquarterMatrixVersionModel } from "@nstep-internal/pages";

export class HeadquarterMatrixVersionModelValidator extends ExtraValidator<HeadquarterMatrixVersionModel> {
	constructor() {
		super();

		this.ruleFor('headquarterId')
			.must(headquarterId => !!headquarterId)
			.withMessage('Headquarter is required');

		this.ruleFor('matrixVersionId')
			.must(matrixVersionId => !!matrixVersionId)
			.withMessage('Version is required');
	}
}

export class ExportModelValidator extends ExtraValidator<ExportModel> {
	constructor() {
		super();

		this.ruleFor('from')
			.must(from => !!from)
			.withMessage('Please provide the start date')
			.must((value, model) => {

				if (!model.to) {
					return true;
				}

				if (model.from && model.to) {
					return new Date(model.from.toDateString()) <= new Date(model.to.toDateString());
				}

				return false;
			})
			.withMessage('Starting date cannot be greater than the end date')

		this.ruleFor('to')
			.must(to => !!to)
			.withMessage('Please provide the end date');
	}
}