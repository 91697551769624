export class AppPermissions {
	static entitlementCodes = '75FCF40E';
	static entitlementTypes = '70CEFEEC';
	static entitlementUnits = '53DBDCD6';
	static entitlementMatrtix = '21204AC9';

	static posLocations = 'FACA1B09';
	static posDevices = 'B7240A80';

	static users = 'FAEE57A3';
	static clients = 'A94CB955';
	static roles = '30535014';

	static nonRationedTransactions = '17195B74';
	static reporting = '17195B74' //to be replaced with 'C54E5078' when db data exists
	static reportsManagement = '17195B74' //to be replaced with 'D83CC257' when db data exists

	static adminAdjustements = '0BEE39D3';
	static balanceHistory = 'D7724779';

	static importHistory = 'EDBE6A2F';
	static exportTransactions = '1740A9C0';
	static updateTransactions = 'ABC90312';
	static exportArchive = 'CB097336';
}