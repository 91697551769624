import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { BulkAdvanceAmisSettings, BulkAdvanceSettings } from '@nstep-internal/pages';
import { Observable, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BulkAdvanceSettingsService {
	constructor(private apiService: ApiService) { }

	getBulkAdvanceSettings(headquarterId: number): Observable<any> {
		return this.apiService.get(Any, `bulk-advance-settings/${headquarterId}`);
	}

	createBulkAdvanceSettings(model: BulkAdvanceSettings): Observable<any> {
		return this.apiService.post(Any, 'bulk-advance-settings', model)
			.pipe(tap(() => this.invalidateCache()));
	}

	updateBulkAdvanceSettings(model: BulkAdvanceSettings): Observable<any> {
		return this.apiService.putNoContent(`bulk-advance-settings/${model.id}`, model)
			.pipe(tap(() => this.invalidateCache()));
	}

	deleteBulkAdvanceSettings(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`bulk-advance-settings/${id}`)
			.pipe(tap(() => this.invalidateCache()));;
	}

	getBulkAdvanceAmisSettings(headquarterId: number): Observable<any> {
		return this.apiService.get(Any, `bulk-advance-settings/${headquarterId}/amis`);
	}

	createBulkAdvanceAmisSettings(model: BulkAdvanceAmisSettings): Observable<any> {
		return this.apiService.post(Any, 'bulk-advance-settings/amis', model)
			.pipe(tap(() => this.invalidateCache()));
	}

	updateBulkAdvanceAmisSettings(model: BulkAdvanceAmisSettings): Observable<any> {
		return this.apiService.put(Any, `bulk-advance-settings/amis/${model.id}`, model)
			.pipe(tap(() => this.invalidateCache()));
	}

	deleteBulkAdvanceAmisSettings(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`bulk-advance-settings/amis/${id}`)
			.pipe(tap(() => this.invalidateCache()));;
	}

	searchAmisCards(headquarterId: number, searchTerm: string) {
		return this.apiService.get(Any, `bulk-advance-settings/${headquarterId}/amis/${searchTerm}`)
			.pipe(tap(() => this.invalidateCache()));;
	}

	invalidateCache() {
		this.apiService.invalidateCache('bulk-advance-settings');
	}
}
