import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, BaseComponent, TableColumn } from '@nstep-common/core';
import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import {
	AdminAdjustmentsPostModel,
	AdminAdjustmentsViews,
	AdminAjustmentsModelValidator,
	AttachmentData,
	EntitlementType,
	GroupedTransactionDto,
	AttachmentDataValidator,
	AdminAdjustmentsModel
} from '@nstep-internal/pages';
import { AdminAdjustmentService, EntitlementTypeService } from '@nstep-internal/shared';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten } from 'lodash';

@Component({
	selector: 'app-admin-adjustments',
	templateUrl: './admin-adjustments.component.html'
})
export class AdminAdjustmentsComponent extends BaseComponent implements OnInit {
	@ViewChild('adjustmentsConfimationModal') adjustmentsConfimationModal!: ModalComponent;

	currentView: AdminAdjustmentsViews = AdminAdjustmentsViews.EditAdjustments;

	tableData: any[] = [];
	tableDataBackup: any[] = [];
	tableDataReady = true;

	modalIsLoading = false;

	isValid = false;

	tableColumns: TableColumn[] = [
		{ name: 'Card Number', key: 'cardNumber', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Id', key: 'transactionId', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Date', key: 'transactionDate', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Headquarter', key: 'headquarterName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Entitlement Type', key: 'entitlementTypeName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Quantity', key: 'transactionQuantity', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Units', key: 'measurementUnitName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Source', key: 'transactionTypeName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Status', key: 'transactionStatusName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Notes', key: 'notes', isCellCentered: true, isHeaderCentered: true },
	];

	entitlementTypesDropdownValues: DropdownOption[] = [];

	minDate = new Date('01/01/2014');
	today = new Date(new Date().toDateString());

	calendarSettings = {
		type: 'date',
		minDate: this.minDate,
		maxDate: this.today
	}

	groupedTransaction!: GroupedTransactionDto[];
	groupedTransactionBackup!: GroupedTransactionDto[];

	purchaseIndex = 0;
	purchasedItems: AttachmentData[] = [];

	adminAjustmentsModel: AdminAdjustmentsModel = createProxy(new AdminAdjustmentsModel(), {
		set: () => this.validateAdminAdjustment(this.adminAjustmentsModel)
	});

	adminAdjustmentsValidation: ValidationErrors<AdminAdjustmentsModel> = {};

	validateAdminAdjustment(value: AdminAdjustmentsModel): void {
		const validator = new AdminAjustmentsModelValidator();
		this.adminAdjustmentsValidation = validator.validate(value);
		this.isValid = Object.keys(this.adminAdjustmentsValidation).length === 0;
	}

	transactionAmountValidations: any = {};

	validateTransactionAmount(value: AttachmentData): void {
		const validator = new AttachmentDataValidator(value.transactionBalance);
		this.transactionAmountValidations[value.transactionBulkId!] = validator.validate(value);
	}

	constructor(private adminAdjustmentService: AdminAdjustmentService,
		private entitlementTypeService: EntitlementTypeService,
		private authService: AuthService) {
		super();
	}

	ngOnInit(): void {
		this.adminAjustmentsModel.entitlementTypeId = 0;
		this.adminAjustmentsModel.from = this.minDate;
		this.adminAjustmentsModel.to = this.today;

		this.getAllEntitlementTypes();
	}

	getAllEntitlementTypes(): void {
		this.entitlementTypesDropdownValues = [];

		this.subscriptions.push(
			this.entitlementTypeService.getAllEntitlementTypes().subscribe({
				next: (response: EntitlementType[]) => {

					const entitlementTypesDropdownItems: DropdownOption[] = [
						new DropdownOption({ name: 'All', value: 0 })
					];

					response.forEach(type => {
						const dropdownItem = new DropdownOption({
							name: type.name,
							value: type.id
						});

						entitlementTypesDropdownItems.push(dropdownItem);
					});

					this.entitlementTypesDropdownValues = entitlementTypesDropdownItems;
				}
			})
		)
	}

	submit(): void {
		this.purchasedItems = [];
		this.purchaseIndex = 0;
		this.initializeTableData();
	}

	initializeTableData(): void {
		this.tableData = [];
		this.tableDataReady = false;

		this.subscriptions.push(
			this.adminAdjustmentService.getTransactions(this.adminAjustmentsModel).subscribe({
				next: (response: GroupedTransactionDto[]) => {

					this.groupedTransaction = JSON.parse(JSON.stringify(response));
					this.groupedTransactionBackup = JSON.parse(JSON.stringify(response));

					this.populateTableData();

					this.tableDataReady = true;
				},
				error: (response: { [key: string]: string[] }) => {
					this.tableDataReady = true;

					const errors = flatten(Object.values(response));
					errors.forEach(error => {
						toast('Error', error, 'red');
					});
				}
			})
		)
	}

	addAdjustment(item: AttachmentData): void {
		const currentTransaction = this.tableData.find(t => t.transactionId === item.transactionId);

		const attachmentIndex = currentTransaction.attached.length - 1

		const attachedTransaction = new AttachmentData({
			cardNumber: item.cardNumber,
			transactionBulkId: item.transactionId,
			transactionId: 0,
			transactionDate: '',
			transactionBalance: item.transactionBalance,
			headquarterName: item.headquarterName,
			entitlementTypeName: item.entitlementTypeName,
			measurementUnitName: item.measurementUnitName,
			transactionTypeName: 'Admin Adjustment',
			transactionStatusName: 'Transacted',
			purchaseIndex: this.purchaseIndex,
			attachmentIndex: attachmentIndex + 1,
			notes: "Adjusted by admin " + this.authService.JWT!.name + " on " + formatDate(new Date(), 'dd/MM/yyyy - h:mm:ss a', 'en') + " for Bulk " + item.transactionId
		});

		const adjustment: AttachmentData = createProxy(attachedTransaction, {
			set: () => this.validateTransactionAmount(attachedTransaction)
		});

		adjustment.transactionAmount = null;

		currentTransaction.originalBalance = currentTransaction.transactionBalance;
		currentTransaction.attached.push(adjustment);
		this.purchasedItems.push(adjustment);
		this.purchaseIndex += 1;
		item.hasPurchase = true;
		item.expanded = true;
	}

	removeAdjustment(item: AttachmentData, adjustment: AttachmentData): void {
		item.attached.splice(adjustment.attachmentIndex!, 1);
		this.purchasedItems.splice(adjustment.purchaseIndex!, 1);

		item.transactionBalance! = item.originalBalance!;
		item.hasPurchase = false;

		delete this.transactionAmountValidations[adjustment.transactionBulkId!];
	}

	cancelAjustments(): void {
		this.purchasedItems = [];
		this.transactionAmountValidations = {};

		this.groupedTransaction = JSON.parse(JSON.stringify(this.groupedTransactionBackup))
		this.tableData = [];

		this.tableDataReady = false;
		this.populateTableData();
		this.tableDataReady = true;

		this.purchaseIndex = 0;
	}

	checkPurchaseAmounts(): void {
		let hasErrors = false;

		this.purchasedItems.forEach(pi => {
			if (pi.transactionAmount === 0 || pi.transactionAmount === null) {
				toast('', `Please insert a valid adjustment amount for entitlement type ${pi.entitlementTypeName} from parent transaction ${pi.transactionBulkId}`, 'orange');
				hasErrors = true;
			}
		});

		if (!hasErrors) {
			this.currentView = AdminAdjustmentsViews.ConfirmAdjustments;

			this.tableDataReady = false;
			this.tableDataBackup = JSON.parse(JSON.stringify(this.tableData));

			const adjustedTransactionIds = this.purchasedItems.flatMap(d => d.transactionBulkId);

			this.tableData = this.tableData
				.filter(e => adjustedTransactionIds.includes(e.transactionId))
				.map(e => new AttachmentData({
					cardNumber: e.cardNumber,
					transactionId: e.transactionId,
					transactionDate: e.transactionDate,
					headquarterName: e.headquarterName,
					entitlementTypeName: e.entitlementTypeName,
					originalBalance: e.originalBalance,
					transactionBalance: e.transactionBalance,
					transactionAmount: e.transactionAmount,
					measurementUnitName: e.measurementUnitName,
					transactionTypeName: e.transactionTypeName,
					transactionStatusName: e.transactionStatusName,
					notes: e.notes ? e.notes : '-',
					attached: e.attached.filter((a: any) => a.transactionId === 0),
					hasPurchase: e.hasPurchase,
					expanded: e.expanded,
				}));

			this.tableDataReady = true;
		}
	}

	setPurchaseAmount(item: AttachmentData, adjutment: AttachmentData): void {
		if (adjutment.transactionAmount && adjutment.transactionAmount > 0 && adjutment.transactionAmount <= item.originalBalance!) {
			item.transactionBalance = item.originalBalance! - adjutment.transactionAmount;
		} else {
			adjutment.transactionAmount = null;
			item.transactionBalance = item.originalBalance!;
		}
	}

	displayEditAdjustmentsView(): void {
		this.currentView = AdminAdjustmentsViews.EditAdjustments;
		this.tableDataReady = false;
		this.tableData = JSON.parse(JSON.stringify(this.tableDataBackup));
		this.tableDataReady = true;
	}

	confirmAdjustments(): void {
		this.adjustmentsConfimationModal.toggle();
	}

	close(): void {
		this.adjustmentsConfimationModal.toggle();
	}

	createAdjustment(): void {
		this.modalIsLoading = true
		const adjustments: AdminAdjustmentsPostModel[] = [];

		this.purchasedItems.forEach(pi => {
			let originalBalance;

			const currentParent = this.tableData.find(d => d.transactionId === pi.transactionBulkId)!;
			originalBalance = currentParent.originalBalance;

			adjustments.push({
				transactionId: pi.transactionBulkId,
				transactionAmount: originalBalance - pi.transactionAmount!,
				notes: pi.notes
			});
		});

		this.subscriptions.push(
			this.adminAdjustmentService.createTransactionAdjustments(adjustments).subscribe({
				next: () => {
					this.adminAdjustmentService.clearGetTransactionsCache();
					this.initializeTableData();

					this.purchasedItems = [];
					this.purchaseIndex = 0;

					this.currentView = AdminAdjustmentsViews.EditAdjustments;
					this.modalIsLoading = false
					this.adjustmentsConfimationModal.toggle();

					toast('Success', 'The adjustment has been successfully saved!', 'green');
				},
				error: () => {
					this.modalIsLoading = false;
					this.adjustmentsConfimationModal.toggle();
				}
			})
		)
	}

	private populateTableData(): void {
		this.tableData = chain(this.groupedTransaction)
			.map(e => new AttachmentData({
				cardNumber: e.bulk.cardNumber,
				transactionId: e.bulk.transactionId,
				transactionDate: formatDate(new Date(e.bulk.timeStamp), 'dd/MM/yyyy - h:mm a', 'en'),
				headquarterName: e.bulk.headquarterName,
				entitlementTypeName: e.bulk.entitlementTypeName,
				transactionBalance: e.bulk.transactionBalance,
				transactionAmount: e.bulk.transactionAmount,
				measurementUnitName: e.bulk.measurementUnitName,
				transactionTypeName: e.bulk.transactionTypeName,
				transactionStatusName: e.bulk.transactionStatusName,
				notes: e.bulk.notes ? e.bulk.notes : '-',
				attached: e.attached.map(a => new AttachmentData({
					cardNumber: a.cardNumber,
					transactionId: a.transactionId,
					transactionDate: formatDate(new Date(a.timeStamp), 'dd/MM/yyyy - h:mm a', 'en'),
					headquarterName: a.headquarterName,
					entitlementTypeName: a.entitlementTypeName,
					transactionBalance: e.bulk.transactionBalance,
					transactionAmount: a.transactionAmount,
					measurementUnitName: a.measurementUnitName,
					transactionTypeName: a.transactionTypeName,
					transactionStatusName: a.transactionStatusName,
					notes: a.notes ? a.notes : '-',
				})),
				hasPurchase: false,
				expanded: false,
			}))
			.value();
	}

	//pageChange(event: any): void {}
}
