import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';

import {
	LoadingComponent,
	FieldComponent,
	TableViewComponent,
	TextAreaComponent,
	PathPipe,
	SearchTextPipe,
	AuthenticationComponent,
	ErrorMessageComponent,
	TrimDirective,
	ChangePasswordComponent,
	DashPipe,
	AcccessHiderDirective,
	HasKeysPipe,
	ContextMenuComponent,
	MonthSelectorComponent,
	AuthService,
	BaseLayoutComponent,
	ReportViewComponent,
	ReportBaseComponent,
	StartCasePipe
} from '@nstep-common/core';
import { SemanticUiModule } from '@nstep-common/semantic-ui';
import { QrCodeModule } from 'ng-qrcode';

@NgModule({
	declarations: [
		AcccessHiderDirective,
		LoadingComponent,
		FieldComponent,
		TableViewComponent,
		TextAreaComponent,
		PathPipe,
		SearchTextPipe,
		AuthenticationComponent,
		ErrorMessageComponent,
		TrimDirective,
		ChangePasswordComponent,
		DashPipe,
		HasKeysPipe,
		ContextMenuComponent,
		MonthSelectorComponent,
		BaseLayoutComponent,
		ReportViewComponent,
		ReportBaseComponent,
		StartCasePipe
	],
	imports: [
		HttpClientModule,
		NgxEditorModule,
		FormsModule,
		CommonModule,
		SemanticUiModule,
		QrCodeModule
	],
	exports: [
		AcccessHiderDirective,
		LoadingComponent,
		FieldComponent,
		TableViewComponent,
		TextAreaComponent,
		PathPipe,
		SearchTextPipe,
		AuthenticationComponent,
		ErrorMessageComponent,
		TrimDirective,
		ChangePasswordComponent,
		DashPipe,
		HasKeysPipe,
		ContextMenuComponent,
		MonthSelectorComponent,
		BaseLayoutComponent,
		ReportViewComponent,
		ReportBaseComponent,
		StartCasePipe
	],
	providers: [
		DatePipe,
		AuthService
	]
})
export class CoreModule {
}