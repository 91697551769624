<h3 class="ui header">
	<i class="cubes icon"></i>
	<div class="content">
		Entitlement Codes
		<div class="sub header">Entitlements</div>
	</div>
</h3>
<br />

<div class="ui stackable  grid">
	<div class="row">
		<div class="three wide column">
			<div class="ui icon fluid input">
				<i class="search link icon" (click)="search()"></i>
				<input type="text"
					placeholder="Search..."
					[(ngModel)]="pagedQueryModel.searchBy"
					(keydown.enter)="search()" />
			</div>
		</div>
		<div class="thirteen wide column">
			<button class="ui right floated primary button"
				(click)="openAddCodeModal()">
				<i class="plus icon"></i>Add Entitlement Code
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="entitlementCodeItemTemplate"
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #entitlementCodeItemTemplate let-item="item">
	<tr>
		<td style="text-align: center;">
			<a href="javascript:void(0);" (click)="onEditAction(item)">{{item.code}}</a>
		</td>
		<td style="text-align: center;">
			<i *ngIf="item.isDependent" class="large check icon"></i>
			<i *ngIf="!item.isDependent" class="large times icon"></i>
		</td>
		<td>{{item.headquarter}}</td>
		<td>{{item.group}}</td>
		<td>{{item.description}}</td>
	</tr>
</ng-template>

<sm-modal [isLoading]="modalIsLoading" #entitlementCodesModal (closeEvent)="close()" [isTiny]="true">
	<ng-container header>{{editModal ? 'Modify Entitlement Code: ' + entitlementCode.majorCode + '.' +entitlementCode.minorCode : 'Add Entitlement Code'}}</ng-container>

	<ng-container content>
		<div class="ui form">

			<div *ngIf="!editModal" class="field">
				<label>Code*</label>
				<div class="fields">
					<div class="three wide field" app-field [error]="validation | path: 'majorCode'">
						<input type="text" [(ngModel)]="entitlementCode.majorCode" app-trim>
					</div>
					<div class="three wide field" app-field [error]="validation | path: 'minorCode'">
						<input type="text" [(ngModel)]="entitlementCode.minorCode" app-trim>
					</div>
				</div>
			</div>

			<div *ngIf="!editModal" class="field" app-field [error]="validation | path: 'headquarterId'">
				<label>Headquarter*</label>
				<sm-dropdown class="ui fluid selection dropdown label"
					[class.loading]="!hqReady"
					[(value)]="entitlementCode.headquarterId"
					[options]="hqDropdownValues">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>

			<div class="field" app-field [error]="validation | path: 'group'">
				<label>Group*</label>
				<input type="text" [(ngModel)]="entitlementCode.group" app-trim>
			</div>

			<div class="field" app-field [error]="validation | path: 'description'">
				<label>Description*</label>
				<textarea [(ngModel)]="entitlementCode.description" app-trim></textarea>
			</div>

			<div *ngIf="!editModal" class="inline field">
				<div class="ui checkbox">
					<input type="checkbox" [(ngModel)]="entitlementCode.isDependent">
					<label>Is Dependent</label>
				</div>
			</div>

		</div>

		<app-error-message [errors]="errors"></app-error-message>

		<h4>All fields marked with * are mandatory</h4>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="save()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>