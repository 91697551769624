<h3 class="ui header">
	<i class="file archive icon"></i>
	<div class="content">
		Export Archive
		<div class="sub header">System</div>
	</div>
</h3>
<br />

<div class="ui grid"
	app-loading
	[enabled]="isExporting">
	<div class="row">
		<div class="five wide column">
			<div class="ui form">
				<div class="field" app-field>
					<label>AMIS Card</label>
					<app-search [onSearch]="searchCard" [(result)]="searchResult" />
				</div>

				<div class="field">
					<label>Rationed*</label>
					<sm-dropdown
						class="ui fluid selection dropdown label"
						[options]="filters.rationed"
						[(value)]="rationed">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="field">
					<label>Year*</label>
					<sm-dropdown
						class="ui fluid selection dropdown label"
						[options]="filters.years"
						[(value)]="year">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="field">
					<label>POS Location</label>
					<sm-dropdown
						class="ui fluid selection dropdown label"
						[options]="filters.posLocations"
						[(value)]="posLocationId">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="field">
					<label>POS Operator</label>
					<sm-dropdown
						class="ui fluid selection dropdown label"
						[options]="filters.posOperators"
						[(value)]="posOperatorId">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="field">
					<label>Entitlement Code</label>
					<sm-dropdown
						class="ui fluid selection dropdown label"
						[options]="filters.entitlementCodes"
						[(value)]="entitlementCodeId">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="field">
					<label>Entitlement Type</label>
					<sm-dropdown
						class="ui fluid selection dropdown label"
						[options]="filters.entitlementTypes"
						[(value)]="entitlementTypeId">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>


			</div>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<div class="ui primary button"
				(click)="export()">
				<i class="file download icon"></i>
				Export
			</div>
		</div>
	</div>
</div>