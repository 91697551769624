<h3 class="ui header">
	<i class="cube icon"></i>
	<div class="content">
		Entitlement Types
		<div class="sub header">Entitlements</div>
	</div>
</h3>
<br />

<div class="ui stackable  grid">
	<div class="row">
		<div class="three wide column">
			<div class="ui icon fluid input">
				<i class="search link icon" (click)="search()"></i>
				<input type="text"
					placeholder="Search..."
					[(ngModel)]="pagedQueryModel.searchBy"
					(keydown.enter)="search()">
			</div>
		</div>
		<div class="thirteen wide column">
			<button class="ui right floated primary button"
				(click)="openAddTypeModal()">
				<i class="plus icon"></i>Add Entitlement Type
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="entitlementTypeItemTemplate"
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true"
				(pageChangeEvent)="pageChange($event)">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #entitlementTypeItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>
			<a href="javascript:void(0);" [ngStyle]="{'color': item.disabled ? 'red' : null}" (click)="onUserAction(item, 'Edit')">{{item.name}}</a>
		</td>
		<td>
			<i *ngIf="item.rationed" class="large check icon"></i>
			<i *ngIf="!item.rationed" class="large times icon"></i>
		</td>
		<td>{{item.minBuyAmount}}</td>
		<td>
			<a href="javascript:void(0);" (click)="onUserAction(item, item.action)">{{item.action}}</a>
		</td>
	</tr>
</ng-template>

<sm-modal [isLoading]="modalIsLoading" #entitlementTypesModal (closeEvent)="close('entitlementTypesModal')" [isTiny]="true">
	<ng-container header>{{editModal ? 'Modify Entitlement Type: ' + entitlementType.name : 'Add Entitlement Type'}}</ng-container>

	<ng-container content>
		<div class="ui form">

			<div class="field" app-field [error]="validation | path: 'name'">
				<label>Type*</label>
				<input type="text" [(ngModel)]="entitlementType.name" app-trim>
			</div>

			<div class="inline field">
				<div class="ui checkbox">
					<input type="checkbox" [(ngModel)]="entitlementType.rationed">
					<label>Rationed</label>
				</div>
			</div>

			<div *ngIf="entitlementType.rationed" class="field" app-field [error]="validation | path: 'minBuyAmount'">
				<label>Minimum Buy Amount*</label>
				<input type="number" min="1" max="9999" [(ngModel)]="entitlementType.minBuyAmount">
			</div>

		</div>

		<app-error-message [errors]="errors"></app-error-message>

		<h4>All fields marked with * are mandatory</h4>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close('entitlementTypesModal')">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="save()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal [isLoading]="modalIsLoading" #entitlementTypeConfimationModal [isTiny]="true">
	<ng-container *ngIf="action==='Enable'" header>Enable entitlement type: {{entitlementType.name}}</ng-container>
	<ng-container *ngIf="action==='Disable'" header>Disable entitlement type: {{entitlementType.name}}</ng-container>

	<ng-container *ngIf="action==='Enable'" content>
		<p>Are you sure you want to enable Entitlement Type: <b>{{entitlementType.name}}</b>?</p>
	</ng-container>
	<ng-container *ngIf="action==='Disable'" content>
		<p>Are you sure you want to disable Entitlement Type: <b>{{entitlementType.name}}</b>?</p>
		<div class="ui grid">
			<div class="two wide column">
				<i class="huge red exclamation triangle icon"></i>
			</div>
			<div class="fourteen wide column">
				<p><span class="ui inverted red text">Warning!</span> If you remove an entitlement type, it will be deleted from all entitlement matrixes available!</p>
			</div>
		</div>

		<app-error-message [errors]="errors"></app-error-message>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close('entitlementTypeConfimationModal')">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			(click)="enableOrDisable()">
			{{ action }}
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>