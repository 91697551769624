import { ReportSettingsDetailsDto } from "@nstep-common/core";

export enum ReportManagementViews {
	Main = 0,
	AddReport = 1,
	EditReport = 2
}

export class AddReportModel {
	name: string | null = null;
	nationId: number | null = null;
	description: string | null = null;
	availableRolesIds: number[] = [];
	templateId: number | null = null;
}

export class AvailableRoleModel {
	roleId!: number;
	roleName!: string;
	isChecked: boolean = false;

	constructor(data: Partial<AvailableRoleModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class GridFeature {
	key!: number;
	name!: string;
	initialValue: boolean = true;
	afterSetValue: boolean = true;

	constructor(data: Partial<GridFeature> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ManageReportSettingsDto {
	name!: string
	description: string | null = null;
	reportTemplateId!: number;
	nationId!: number;
	rolesList: number[] = [];
	reportSettingsDetails!: ReportSettingsDetailsDto;

	constructor(data: Partial<ManageReportSettingsDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}