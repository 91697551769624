import { formatDate } from "@angular/common";
import { Component } from "@angular/core";
import {
	BaiscFilterSection,
	BaseComponent,
	CellData,
	Filter,
	FilterChangeModel,
	FilterComparisonOperator,
	FilterDescriptor,
	FilterOperator,
	FilterType,
	FilterValue,
	GridEndpoints,
	ReportBalanceDto,
	ReportColumn,
	ReportColumnData,
	ReportColumnSortDirection,
	ReportNRTransactionDto,
	ReportPagedQueryParameter,
	ReportSettingsColumnDto,
	ReportTransactionHistoryDto,
	SortDescriptor,
	SortDirection
} from "@nstep-common/core";
import { JsonMapper } from "@nstep-common/utils";
import { camelCase, chain, startCase } from "lodash";
import { map, Observable, of } from "rxjs";

@Component({
	template: ''
})
export class ReportBaseComponent extends BaseComponent {

	SortDirection = SortDirection;
	FilterTypeEnum = FilterType;
	BaiscFilterSection = BaiscFilterSection;
	FilterComparisonOperatorEnum = FilterComparisonOperator;

	agregateOperators = [FilterOperator.AND, FilterOperator.OR];

	reportTemplateColumns: { [key: number]: ReportColumn[] } = {};

	reportTemplateDataType: { [key: number]: any } = {
		1: ReportBalanceDto,
		2: ReportNRTransactionDto,
		3: ReportTransactionHistoryDto
	};

	filtersWithoutValue = [FilterComparisonOperator.IsEmpty, FilterComparisonOperator.IsNotEmpty, FilterComparisonOperator.IsNull, FilterComparisonOperator.IsNotNull];

	getColumnFilterCollection(endpoint: string, gridService: any): Observable<string[]> {
		switch (endpoint) {
			case GridEndpoints.Headquarters:
				return gridService.getHeadquarters()
					.pipe(map((response: string[]) => response));
			case GridEndpoints.EntitlementCodes:
				return gridService.getCodes()
					.pipe(map((response: string[]) => response));
			case GridEndpoints.EntitlementTypes:
				return gridService.getTypes()
					.pipe(map((response: string[]) => response));
			case GridEndpoints.EntitlementUnits:
				return gridService.getUnits()
					.pipe(map((response: string[]) => response));
			case GridEndpoints.PosLocations:
				return gridService.getLocations()
					.pipe(map((response: string[]) => response));
			case GridEndpoints.TransactionStates:
				return gridService.getTransactionStates()
					.pipe(map((response: string[]) => response));
			case GridEndpoints.TransactionTypes:
				return gridService.getTransactionTypes()
					.pipe(map((response: string[]) => response));
			case GridEndpoints.RationedTypes:
				return gridService.getRationedTypes()
					.pipe(map((response: string[]) => response));
			default:
				return of([]);
		}
	}

	getDefaultFilters(filterType: FilterType, results: string[], isTimestampColumn: boolean = false): Filter[] {
		switch (filterType) {
			case FilterType.Basic:
				return [
					new Filter({ operator: isTimestampColumn ? FilterComparisonOperator.IsLessThan : FilterComparisonOperator.IsEqualTo, value: '' }),
					new Filter({ operator: isTimestampColumn ? FilterComparisonOperator.IsLessThan : FilterComparisonOperator.IsEqualTo, value: '' })
				];
			case FilterType.Bool:
				return [
					new Filter({ operator: FilterComparisonOperator.IsEqualTo, value: '', text: 'true' }),
					new Filter({ operator: FilterComparisonOperator.IsEqualTo, value: '', text: 'false' })
				];
			case FilterType.List:
				return results.map(r => new Filter({ operator: FilterComparisonOperator.IsEqualTo, value: '', text: r }));
		}
	}

	getProccessedReportColumnsSettings(defaultTemplateColumns: ReportColumn[], reportSettingsColumns: ReportSettingsColumnDto[]): ReportColumnData {
		const proccessedReportColumnSettings = new ReportColumnData();

		const reportColumns = chain(reportSettingsColumns)
			.orderBy(c => c.order)
			.map(c => {
				const defaultColumnData = defaultTemplateColumns.find(d => d.key === camelCase(c.name))!;
				const availableColumnFilters = defaultColumnData.assignedFilters!;

				if (c.sortDirection) {
					const sortation = new SortDescriptor({
						columnName: c.name,
						sortDirection: c.sortDirection
					})

					proccessedReportColumnSettings.sortList.push(sortation);
				}

				const filter = new FilterDescriptor({
					columnName: c.name,
					agregateOperator: defaultColumnData.filterType === FilterType.Basic ? FilterOperator.AND : FilterOperator.OR,
					filterValue: c.filters
						.map(f => new FilterValue({
							operator: f.operator,
							value: f.value
						}))
				})

				if (filter.filterValue.length) proccessedReportColumnSettings.filterList.push(filter);

				const savedColumnFilters = c.filters.map(f => new Filter({
					operator: f.operator,
					value: f.value
				}));

				const assignedFilters = this.getAssignedFilter(availableColumnFilters, savedColumnFilters, defaultColumnData!.isDate, defaultColumnData!.filterType);

				return new ReportColumn({
					key: camelCase(c.name),
					name: startCase(c.name),
					orderNumber: c.order,
					isShown: c.show,
					isDate: defaultColumnData?.isDate,
					sortDirection: c.sortDirection,
					sortOrder: c.sortOrder,
					filterType: defaultColumnData.filterType,
					validOperators: defaultColumnData?.validOperators,
					agregateOperator: defaultColumnData.filterType === FilterType.Basic ? FilterOperator.AND : FilterOperator.OR,
					assignedFilters: assignedFilters,
					hasFilterApplied: assignedFilters.some(f => defaultColumnData.filterType === FilterType.Basic && (f.value || f.dateValue) || defaultColumnData.filterType !== FilterType.Basic && f.isChecked)
				})
			})
			.value();

		proccessedReportColumnSettings.reportColumns = reportColumns;

		return proccessedReportColumnSettings;
	}

	private getAssignedFilter(availableColumnFilters: Filter[], savedColumnFilters: Filter[], isDate: boolean, filterType: FilterType): Filter[] {

		let result: Filter[] = [];

		switch (filterType) {
			case FilterType.Basic:
				result[0] = savedColumnFilters[0] ? new Filter({
					operator: savedColumnFilters[0].operator,
					value: !isDate ? savedColumnFilters[0].value : '',
					dateValue: isDate ? new Date(savedColumnFilters[0].value) : null
				}) : availableColumnFilters[0];

				result[1] = savedColumnFilters[1] ? new Filter({
					operator: savedColumnFilters[1].operator,
					value: !isDate ? savedColumnFilters[1].value : '',
					dateValue: isDate ? new Date(savedColumnFilters[1].value) : null
				}) : availableColumnFilters[1];

				break;
			default:
				result = availableColumnFilters.map(f => {
					const nonBasicSavedFilter = savedColumnFilters.find(s => s.value === f.text);
					return new Filter({
						text: f.text,
						value: nonBasicSavedFilter ? nonBasicSavedFilter.value : '',
						operator: nonBasicSavedFilter ? nonBasicSavedFilter.operator : FilterComparisonOperator.IsEqualTo,
						dateValue: nonBasicSavedFilter && isDate ? new Date(nonBasicSavedFilter!.value) : null,
						isChecked: nonBasicSavedFilter && nonBasicSavedFilter.value !== ''
					})
				})

				break;
		}

		return result;
	}

	getTemplatePaginatedData(data: any[], templateId: number): any[][] {
		const jsonMapper = new JsonMapper();
		const mappedData: any[] = [];

		data.forEach(element => {
			const mapResult = jsonMapper.deserializeObject(element, this.reportTemplateDataType[templateId]);
			mappedData.push(mapResult.value);
		});

		return mappedData.map(item => {
			const row: CellData[] = [];

			Object.keys(item).forEach(key => {
				const value = item[key];
				row.push(new CellData({ key: key, value: key === 'timestamp' ? formatDate(value, 'dd/MM/yyyy - h:mm a', 'en') : Object.prototype.toString.call(value) === '[object Date]' ? formatDate(value, 'dd/MM/yyyy', 'en') : value }))
			});

			return row;
		})
	}

	processColumnSortation(column: ReportColumnSortDirection, reportColumns: ReportColumn[], reportPagedQueryModel: ReportPagedQueryParameter): void {
		const formattedColumnName = column.name.replace(/\s/g, '');
		const currentSortation = reportPagedQueryModel.sortList.find(s => s.columnName === formattedColumnName);

		if (currentSortation) {
			reportPagedQueryModel.sortList.splice(reportPagedQueryModel.sortList.indexOf(currentSortation), 1);

			//remake sort order when user changes a column sortation
			reportPagedQueryModel.sortList.forEach((sortation, index) => {
				const reportColumn = reportColumns.find(r => r.name.replace(/\s/g, '') === sortation.columnName)!
				reportColumn.sortOrder = index + 1;
			});
		}

		const reportColumn = reportColumns.find(r => r.name === column.name)!
		reportColumn.sortDirection = column.sortDirection;
		reportColumn.sortOrder = null;

		if (column.sortDirection) {
			reportColumn.sortOrder = reportPagedQueryModel.sortList.length + 1;

			const sortation = new SortDescriptor({
				columnName: formattedColumnName,
				sortDirection: column.sortDirection
			})

			reportPagedQueryModel.sortList.push(sortation);
		}
	}

	proccessColumnFilters(model: FilterChangeModel, reportPagedQueryModel: ReportPagedQueryParameter): void {
		const formattedColumnName = model.col.name.replace(/\s/g, '');
		reportPagedQueryModel.filterList = reportPagedQueryModel.filterList.filter(d => d.columnName !== formattedColumnName);

		if (model.clearColumnFilter) {

			if (model.col.filterType === FilterType.Basic) {
				model.col.assignedFilters = [
					new Filter({ operator: FilterComparisonOperator.IsEqualTo, value: '' }),
					new Filter({ operator: FilterComparisonOperator.IsEqualTo, value: '' })
				];
			}

			if (model.col.filterType === FilterType.List) {
				model.col.assignedFilters.forEach(filter => {
					filter.isChecked = false;
					filter.value = '';
				});
			}

			if (model.col.filterType === FilterType.Bool) {
				model.col.assignedFilters = [
					new Filter({ operator: FilterComparisonOperator.IsEqualTo, value: '', text: 'true' }),
					new Filter({ operator: FilterComparisonOperator.IsEqualTo, value: '', text: 'false' })
				];
			}
		}

		reportPagedQueryModel.page = 1;

		const filter = new FilterDescriptor({
			columnName: formattedColumnName,
			agregateOperator: model.col.agregateOperator!,
			filterValue: model.col.assignedFilters?.filter(f => this.filtersWithoutValue.includes(f.operator) || f.value)
				.map(f => new FilterValue({
					operator: f.operator,
					value: f.value
				}))
		})

		reportPagedQueryModel.filterList.push(filter);

		model.col.hasFilterApplied = model.col.assignedFilters.some(f => model.col.filterType === FilterType.Basic && (f.value || f.dateValue || this.filtersWithoutValue.includes(f.operator))
			|| model.col.filterType !== FilterType.Basic && f.isChecked);
	}
}