<app-base-layout>
	<ng-container title>
		<div class="ui red label">Internal</div>
	</ng-container>

	<ng-container menu>
		<div id="side-menu"
			class="ui vertical fluid large menu">
			<div class="item"
				[appAccessHider]="[permissions.entitlementCodes, permissions.entitlementTypes, permissions.entitlementUnits, permissions.entitlementMatrtix]"
				[fullAccessRequired]="false">
				<div class="header">
					Entitlements
				</div>
				<div class="menu">
					<a class="blue item"
						[routerLink]="['/entitlements/codes']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.entitlementCodes]">
						<i class="cubes icon" style="float: none"></i>&nbsp;&nbsp;Entitlement Codes
					</a>
					<a class="blue item"
						[routerLink]="['/entitlements/types']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.entitlementTypes]">
						<i class="cube icon" style="float: none"></i>&nbsp;&nbsp;Entitlement Types
					</a>
					<a class="blue item"
						[routerLink]="['/entitlements/units']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.entitlementUnits]">
						<i class="tape icon" style="float: none"></i>&nbsp;&nbsp;Entitlement Units
					</a>
					<a class="blue item"
						[routerLink]="['/entitlements/matrix']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.entitlementMatrtix]">
						<i class="table icon" style="float: none"></i>&nbsp;&nbsp;Entitlement Matrix
					</a>
				</div>
			</div>

			<div class="item"
				[appAccessHider]="[permissions.posLocations, permissions.posDevices]"
				[fullAccessRequired]="false">
				<div class="header">
					POS
				</div>
				<div class="menu">
					<a class="blue item"
						[routerLink]="['/pos/locations']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.posLocations]">
						<i class="map marker alternate icon" style="float: none"></i>&nbsp;&nbsp;POS Locations
					</a>
					<a class="blue item"
						[routerLink]="['/pos/devices']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.posDevices]">
						<i class="mobile alternate icon" style="float: none"></i>&nbsp;&nbsp;POS Devices
					</a>
				</div>
			</div>

			<div class="item"
				[appAccessHider]="[permissions.users, permissions.clients, permissions.roles]"
				[fullAccessRequired]="false">
				<div class="header">
					Membership
				</div>
				<div class="menu">
					<a class="blue item"
						[routerLink]="['/membership/users']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.users]">
						<i class="user friends icon" style="float: none"></i>&nbsp;&nbsp;Users
					</a>
					<a class="blue item"
						[routerLink]="['/membership/clients']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.clients]">
						<i class="project diagram icon" style="float: none"></i>&nbsp;&nbsp;Clients
					</a>
					<a class="blue item"
						[routerLink]="['/membership/roles']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.roles]">
						<i class="users cog icon" style="float: none"></i>&nbsp;&nbsp;Roles
					</a>
				</div>
			</div>

			<div class="item"
				[appAccessHider]="[permissions.nonRationedTransactions]"
				[fullAccessRequired]="false">
				<div class="header">
					Reports
				</div>
				<div class="menu">
					<a class="blue item"
						[routerLink]="['/reports/non-rationed-transactions']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.nonRationedTransactions]">
						<i class="exchange alternate icon" style="float: none"></i>&nbsp;&nbsp;Non-Rationed Transactions
					</a>

					<a class="blue item"
						[routerLink]="['/reports/reporting']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.reporting]">
						<i class="chart pie icon" style="float: none"></i>&nbsp;&nbsp;Reporting
					</a>

					<a class="blue item"
						[routerLink]="['/reports/management']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.reportsManagement]">
						<i class="wrench icon" style="float: none"></i>&nbsp;&nbsp;Reports Management
					</a>
				</div>
			</div>

			<div class="item"
				[appAccessHider]="[permissions.adminAdjustements, permissions.balanceHistory]"
				[fullAccessRequired]="false">
				<div class="header">
					Rations
				</div>
				<div class="menu">
					<a class="blue item"
						[routerLink]="['/rations/admin-adjustments']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.adminAdjustements]">
						<i class="sliders horizontal icon" style="float: none"></i>&nbsp;&nbsp;Admin Adjustments
					</a>

					<a class="blue item"
						[routerLink]="['/rations/balance-history']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.balanceHistory]">
						<i class="balance scale left icon" style="float: none"></i>&nbsp;&nbsp;Balance History
					</a>
				</div>
			</div>

			<div class="item"
				[appAccessHider]="[permissions.importHistory, permissions.exportTransactions, permissions.updateTransactions, permissions.exportArchive]"
				[fullAccessRequired]="false">
				<div class="header">
					System
				</div>
				<div class="menu">
					<a class="blue item"
						[routerLink]="['/system/import']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.importHistory]">
						<i class="file upload icon" style="float: none"></i>&nbsp;&nbsp;Import History
					</a>
					<a class="blue item"
						[routerLink]="['/system/export']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.exportTransactions]">
						<i class="file download icon" style="float: none"></i>&nbsp;&nbsp;Export Transactions
					</a>
					<a class="blue item"
						[routerLink]="['/system/update']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.updateTransactions]">
						<i class="sync alternate icon" style="float: none"></i>&nbsp;&nbsp;Update Transactions
					</a>
					<a class="blue item"
						[routerLink]="['/system/archive']"
						[routerLinkActive]="['active']"
						[appAccessHider]="[permissions.exportArchive]">
						<i class="archive icon" style="float: none"></i>&nbsp;&nbsp;Export Archive
					</a>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container content>
		<router-outlet></router-outlet>
	</ng-container>
</app-base-layout>