import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HeadquarterService {

	constructor(private apiService: ApiService) { }

	getHeadquarters(): Observable<any> {
		return this.apiService.get(Any, 'hq');
	}

	getHeadquartersEx(): Observable<any> {
		return this.apiService.get(Any, 'hq/ex');
	}

	clearHeadquartersExCache(): void {
		this.apiService.invalidateCache('hq/ex');
	}
}
