import { Injectable } from '@angular/core';
import { LogPublisher, LogConsole, LogWebApi } from "@nstep-common/utils";
import { ApiService } from '@nstep-common/core';

@Injectable({
	providedIn: 'root'
})
export class LogPublishersService {

	constructor(private apiService: ApiService) {
		this.buildPublishers();
	}

	publishers: LogPublisher[] = [];

	buildPublishers(): void {
		this.publishers.push(new LogConsole());
		this.publishers.push(new LogWebApi(this.apiService));
	}
}
