<div class="ui top attached tabular menu">
	<div class="item"
		[class.active]="!showAmis"
		(click)="changeTab(false)"
		style="cursor: pointer">
		General
	</div>
	<div class="item"
		[class.active]="showAmis"
		(click)="changeTab(true)"
		style="cursor: pointer">
		AMIS
	</div>
</div>

<br />

<div class="ui compact stackable grid">
	<div class="row">
		<div class="column">
			<ng-container
				*ngIf="!selectedSetting">
				<button class="ui primary button"
					(click)="addSetting()">
					<i class="plus icon"></i>Add Setting
				</button>

				<br /><br />
			</ng-container>

			<app-table-view
				[itemTemplate]="itemTemplate"
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true">
			</app-table-view>
		</div>
	</div>

	<div class="row"
		*ngIf="showAmis && selectedSetting">
		<div class="column">
			<div class="ui form">
				<div class="inline fields">
					<label>Search By</label>
					<div class="field">
						<div class="ui radio checkbox">
							<input type="radio"
								name="searchBy"
								[(ngModel)]="byValue"
								[value]="true"
								(change)="searchTypeChanged()">
							<label>Value</label>
						</div>
					</div>
					<div class="field">
						<div class="ui radio checkbox">
							<input type="radio"
								name="searchBy"
								[(ngModel)]="byValue"
								[value]="false"
								(change)="searchTypeChanged()">
							<label>Regex *</label>
						</div>
					</div>
				</div>

				* Use '_' as a wildcard character (e.g. OSC-00000<strong>__</strong>-00 will match cards like OSC-00000<strong>19</strong>-00)
			</div>

			<br />

			<div class="ui form">
				<div class="inline field">
					<label>AMIS Card</label>
					<input type="text" [(ngModel)]="userValue">

					<button class="ui primary small button"
						style="margin-left: 10px;"
						[disabled]="!userValue || userValue.length < 14"
						(click)="byValue ? addCard() : searchCards()">
						<i class="icon" [class.add]="byValue" [class.search]="!byValue"></i> {{ byValue ? 'Add' : 'Search' }}
					</button>
				</div>

				<ol *ngIf="this.amisCards.length"
					style="max-height: 150px; max-width: 250px; overflow: auto;">
					<li *ngFor="let card of this.amisCards">
						{{card}}
						<ng-container *ngIf="byValue">
							&nbsp;
							<button class="ui tertiary tiny compact red icon button"
								(click)="removeCard(card)">
								<i class="times icon"></i>
							</button>
						</ng-container>
					</li>
				</ol>

				<p *ngIf="!this.amisCards.length">No AMIS cards specified.</p>
			</div>

			<br />

			<button class="ui right floated green small button"
				(click)="saveSetting(selectedSetting)"
				[disabled]="(byValue ? !selectedSetting.amisCards : !selectedSetting.amisSearchTerm) && !amisCards.length">
				<i class="save icon"></i>Save
			</button>
		</div>
	</div>


	<div class="row">
		<div class="sixteen wide column">
			<app-error-message [errors]="errors"></app-error-message>
		</div>
	</div>
</div>

<ng-template #itemTemplate let-item="item">
	<tr>
		<td>
			<app-month-selector
				[isDisabled]="item.id"
				[(value)]="item.issueMonth">
			</app-month-selector>
		</td>
		<td>
			<app-month-selector
				[isRelative]="true"
				[isDisabled]="item.id"
				[(value)]="item.month">
			</app-month-selector>
		</td>

		<td style="text-align: center;">
			<input class="ui checkbox"
				[disabled]="!item.id"
				type="checkbox"
				[ngModel]="item.enabled"
				(ngModelChange)="toggleSetting(item)" />
		</td>

		<td style="text-align: center;">
			<button class="ui blue small button"
				*ngIf="showAmis"
				(click)="!selectedSetting ? editCards(item) : editCards(null)">
				<ng-container *ngIf="!selectedSetting">
					<i class="edit icon"></i>Edit AMIS Cards
				</ng-container>
				<ng-container *ngIf="selectedSetting">
					<i class="arrow left icon"></i>Back
				</ng-container>
			</button>

			<button class="ui green small button"
				*ngIf="!item.id && !showAmis"
				(click)="saveSetting(item)">
				<i class="save icon"></i>Save
			</button>

			<button class="ui red small button"
				*ngIf="item.id && !selectedSetting"
				(click)="deleteSetting(item)">
				<i class="delete icon"></i>Delete
			</button>
		</td>
	</tr>
</ng-template>