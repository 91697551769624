import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { hasChanged } from '@nstep-common/utils';
import { merge } from 'lodash';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'sm-calendar',
	template: '<ng-content></ng-content>'
})
export class CalendarComponent implements OnInit, OnDestroy, OnChanges {
	jQueryElement: any;
	@Input() settings: any = {};

	@Input() value: Date | null = null;
	@Output() valueChange = new EventEmitter<Date | null>();

	@Input() minValue: Date | null = null;
	@Input() maxValue: Date | null = null;

	constructor(private elementRef: ElementRef) {
	}

	ngOnInit(): void {
		this.jQueryElement = $(this.elementRef.nativeElement);

		const settings = merge({
			onChange: (date: Date | null) => {
				this.value = date;
				this.valueChange.emit(date);
			}
		}, this.settings);

		this.jQueryElement.calendar(settings);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (hasChanged(changes['minValue'])) {
			setTimeout(() => this.jQueryElement.calendar('set minDate', this.minValue));
		}

		if (hasChanged(changes['maxValue'])) {
			setTimeout(() => this.jQueryElement.calendar('set maxDate', this.maxValue));
		}

		if (hasChanged(changes['value'])) {
			setTimeout(() => this.jQueryElement.calendar('set date', this.value, true, false));
		}
	}

	ngOnDestroy(): void {
		this.jQueryElement.calendar('destroy');
	}
}