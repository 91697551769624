<div class="ui centered grid">
	<div class="center aligned column"
		style="width: 400px; margin-top: 15%">

		<div *ngIf="loading" class="ui basic segment" style="width: 100%; height: 250px;">
			<div class="ui active inverted dimmer">
				<div class="ui medium text blue slow double loader">Loading</div>
			</div>
		</div>

		<div *ngIf="!loading" class="ui segment">
			<img src="assets/images/NSTEP-logo.png"
				class="image"
				style="margin-top: 20px; margin-bottom: 40px;" />

			<form class="ui large form" (submit)="onSubmit()">
				<div class="ui segment">
					<ng-container *ngIf="!requires2F">
						<div class="field" app-field [error]="validation | path: 'userName'">
							<div class="ui left icon input">
								<i class="user icon"></i>
								<input type="text"
									autoComplete="off"
									name="userName"
									[placeholder]="userNamePlaceHolder"
									[maxlength]="userNameMaxLength"
									[(ngModel)]="model.userName">
							</div>
						</div>

						<div class="field" app-field [error]="validation | path: 'password'">
							<div class="ui left icon input">
								<i class="lock icon"></i>
								<input type="text"
									style="-text-security:disc; -webkit-text-security:disc;"
									autoComplete="off"
									name="password"
									[placeholder]="passwordPlaceHolder"
									[maxlength]="passwordMaxLength"
									[(ngModel)]="model.password">
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="requires2F">
						<div class="field" app-field [error]="validation | path: 'twoFactorCode'">
							<label>
								Please enter code from authenticator:<br />
							</label>
							<div class="ui left icon input">
								<i class="shield alternate icon"></i>
								<input type="text"
									autoComplete="off"
									name="twoFactorCode"
									[placeholder]="'xxxxxx'"
									[maxlength]="6"
									[(ngModel)]="model.twoFactorCode">
							</div>
						</div>
					</ng-container>

					<button class="ui fluid large blue button" type="submit">Login</button>
				</div>

				<div class="field" app-field *ngIf="errors.length" [errors]="errors"></div>
			</form>
		</div>
	</div>
</div>