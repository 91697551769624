import { Component } from '@angular/core';
import { createProxy } from '@nstep-common/utils';
import { BaseComponent, GroupedCardBalanceOnline, TableColumn } from '@nstep-common/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain } from 'lodash';
import { BalanceHistoryService } from '@nstep-internal/shared';
import { BalanceHistoryModel, BalanceHistoryModelValidator } from '@nstep-internal/pages';

@Component({
	selector: 'app-balance-history',
	templateUrl: './balance-history.component.html',
	styles: [
	]
})
export class BalanceHistoryComponent extends BaseComponent {

	tableData: any[] = [];
	tableDataReady = true;

	cardBalanceHistoryData: GroupedCardBalanceOnline[] = [];

	today = new Date(new Date().toDateString());

	calendarSettings = {
		type: 'date',
		disabledDaysOfWeek: [0, 6],
		initialDate: this.today
	}

	rationsDropdownValues: DropdownOption[] = [
		new DropdownOption({ name: 'Rationed Items', value: 0 }),
		new DropdownOption({ name: 'Non Rationed Items', value: 1 })
	];
	selectedRationType = 0;

	displayTableColumns: TableColumn[] = [];

	rationedTableColumns: TableColumn[] = [
		{ name: 'Entitlement Type', key: 'entitlementType', sortAsc: true },
		{ name: 'Balance', key: 'balance' },
		{ name: 'Measurement Unit', key: 'measurementUnit' },
		{ name: 'Headquarter', key: 'headquarter' },
		{ name: 'Entitlement Code', key: 'entitlementCode' }
	];

	nonRationedTableColumns: TableColumn[] = [
		{ name: 'Entitlement Type', key: 'entitlementType', sortAsc: true },
		{ name: 'Headquarter', key: 'headquarter' },
		{ name: 'Entitlement Code', key: 'entitlementCode' }
	];

	balanceHistoryModel: BalanceHistoryModel = createProxy(new BalanceHistoryModel(), {
		set: () => this.validate(this.balanceHistoryModel)
	});

	validation: ValidationErrors<BalanceHistoryModel> = {};

	validate(value: BalanceHistoryModel): void {
		const validator = new BalanceHistoryModelValidator();
		this.validation = validator.validate(value);
		if (Object.keys(this.validation).length === 0) {
			this.onValidCardNumber();
		}
	}

	constructor(private balanceHistoryService: BalanceHistoryService) {
		super();
		this.balanceHistoryModel.date = this.today;
	}

	onValidCardNumber(): void {
		this.tableDataReady = false;
		this.tableData = [];

		this.subscriptions.push(
			this.balanceHistoryService.getCardBalanceHistory(this.balanceHistoryModel)
				.subscribe({
					next: response => {
						this.cardBalanceHistoryData = response;
						this.initializeTable(true);
					},
					error: () => {
						this.tableDataReady = true;
					}
				})
		);
	}

	initializeTable(rationed: boolean): void {
		this.displayTableColumns = rationed ? this.rationedTableColumns : this.nonRationedTableColumns;

		this.tableData = chain(this.cardBalanceHistoryData)
			.filter(c => c.cardNumber === this.balanceHistoryModel.cardNumber)
			.flatMap(c => c.entitlements)
			.filter(e => e.rationed === rationed)
			.map(e => ({
				entitlementType: e.entitlementType,
				balance: e.balance,
				measurementUnit: e.measurementUnit,
				headquarter: e.headquarter,
				entitlementCode: e.entitlementCode
			}))
			.value();

		this.tableDataReady = true;
	}
}
