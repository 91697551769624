export class ClientModel {
	id!: number;
	type: number = 1;
	name: string = '';
	description: string | null = null;

	clientId: string = '';
	clientSecret: string = '';
	validFrom: Date | null = null;
	validTo: Date | null = null;

	whitelistIp: string | null = null;

	allowUserImpersonation: boolean | null = null;
	posLocationId: number | null = null;


	constructor(data?: Partial<ClientModel> | null) {
		if (data) {
			Object.assign(this, data);
		}
		else {
			this.clientId = crypto.randomUUID();
			this.clientSecret = crypto.randomUUID();
		}
	}
}