export class JsonMapperResult<T> {
	value!: T;
	errors = {};

	constructor(data: Partial<JsonMapperResult<T>> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	hasErrors(): boolean {
		return Object.keys(this.errors).length > 0;
	}
}