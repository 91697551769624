import { ChangePasswordModel } from "@nstep-common/core";
import { ExtraValidator } from "@nstep-common/utils";

export class ChangePasswordModelValidator extends ExtraValidator<ChangePasswordModel> {
	constructor() {
		super();

		this.ruleFor('newPassword').matches(/^(?=(.*\d){2,})(?=.*[$@#-\/:-?{-~!^_'\[\]]{1,})(?=(.*[A-Z]){1,})(?=.*\w).{8,20}$/)
			.withMessage('Your password strength is too low! The password must be between 8 and 20 characters. You must have at least 2 digits, 1 special character and one uppercase letter.')
			.must((_, model) => model.oldPassword !== model.newPassword)
			.withMessage('The current password must be different than the old password.');

		this.ruleFor('confirmPassword').must((_, model) => model.newPassword === model.confirmPassword)
			.withMessage('The new password and confirm password must match.');
	}
}