import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any, PagedQueryParameter } from '@nstep-common/utils';
import { PosLocationDto, PosLocationInformationDto, PosOperatorDto, PoslocationInformation, PosoperatorContactDto, SubRoleDto } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PosLocationService {

	constructor(private apiService: ApiService) { }

	getOperatorCards(query: string): Observable<any> {
		return this.apiService.get(Any, `pos-locations/posoperators/cards?query=${query}`);
	}

	getPosLocations(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'pos-locations', { observe: 'response', params: model });
	}

	getAllPosLocations(): Observable<any> {
		return this.apiService.get(Any, 'pos-locations/all');
	}

	getHqAssociations(id: number): Observable<any> {
		return this.apiService.get(Any, `pos-locations/${id}/hq-associations`);
	}

	clearHqAssociationsCache(id: number): void {
		this.apiService.invalidateCache(`pos-locations/${id}/hq-associations`);
	}

	getSubRole(id: number): Observable<any> {
		return this.apiService.get(Any, `pos-locations/${id}/sub-role`);
	}

	clearSubRoleCache(id: number): void {
		this.apiService.invalidateCache(`pos-locations/${id}/sub-role`);
	}

	getPosTypeAssociations(id: number): Observable<any> {
		return this.apiService.get(Any, `pos-locations/types/${id}`);
	}

	clearPosTypeAssociationsCache(id: number): void {
		this.apiService.invalidateCache(`pos-locations/types/${id}`);
	}

	getPosOperators(id: number, model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, `pos-locations/${id}/pos-operators`, { observe: 'response', params: model });
	}

	clearPosOperatorsCache(id: number): void {
		this.apiService.invalidateCache(`pos-locations/${id}/pos-operators`);
	}

	getAmisCards(query: string): Observable<any> {
		return this.apiService.get(Any, `pos-locations/amis-cards/${query}`);
	}

	savePosLocation(model: PoslocationInformation): Observable<any> {
		return this.apiService.post(Any, 'pos-locations', model);
	}

	updatePosLocation(model: PoslocationInformation): Observable<any> {
		return this.apiService.put(Any, `pos-locations/${model.id}`, model);
	}

	saveSubrole(posLocationInformationId: number, subroles: SubRoleDto[]): Observable<PosLocationInformationDto> {
		return this.apiService.post(PosLocationInformationDto, `pos-locations/${posLocationInformationId}/sub-role`, subroles);
	}

	assignPosLocationToHeadquarter(posLocationId: number, headquarterId: number, startDate: string): Observable<PosLocationDto> {
		return this.apiService.post(PosLocationDto, `pos-locations/${posLocationId}/hq-associations/${headquarterId}/${startDate}`);
	}

	assignTypeToPOSLocation(posLocationId: number, headquarterId: number, model: any): Observable<any> {
		return this.apiService.postNoContent(`pos-locations/types/${posLocationId}/${headquarterId}`, model);
	}

	revokeTypeFromPOSLocation(posLocationId: number, entitlementTypeId: number): Observable<any> {
		return this.apiService.deleteNoContent(`pos-locations/types/${posLocationId}/${entitlementTypeId}`);
	}

	updatePosLocationHqAssociation(model: PosLocationDto): Observable<PosLocationDto> {
		return this.apiService.put(PosLocationDto, 'pos-locations/location', model);
	}

	enrollPosOperator(model: PosOperatorDto): Observable<PosOperatorDto> {
		return this.apiService.post(PosOperatorDto, 'pos-locations/operator', model);
	}

	updatePosOperator(model: PosOperatorDto): Observable<PosOperatorDto> {
		return this.apiService.put(PosOperatorDto, 'pos-locations/operator', model);
	}

	assignPosOperatorContact(posOperatorId: number, model: PosoperatorContactDto): Observable<PosoperatorContactDto> {
		return this.apiService.post(PosoperatorContactDto, `pos-locations/${posOperatorId}/contact`, model);
	}

	updatePosOperatorContact(posOperatorId: number, model: PosoperatorContactDto): Observable<PosoperatorContactDto> {
		return this.apiService.put(PosoperatorContactDto, `pos-locations/${posOperatorId}/contact`, model);
	}

	deletePosOperatorContact(posOperatorId: number): Observable<any> {
		return this.apiService.deleteNoContent(`pos-locations/${posOperatorId}/contact`);
	}

	disablePosLocation(id: number, endDate: string): Observable<any> {
		return this.apiService.deleteNoContent(`pos-locations/${id}/${endDate}`);
	}

	clearPosLocationsCache(): void {
		this.apiService.invalidateCache('pos-locations');
	}
}
