import { Injectable } from '@angular/core';
import {
	ApiService,
	ExportDataRequest,
	ReportPagedQueryParameter
} from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ReportingService {

	constructor(private apiService: ApiService) { }

	getReportTemplate(templateId: number, model: ReportPagedQueryParameter): Observable<any> {
		return this.apiService.post(Any, `reporting/${templateId}`, model, { observe: 'response' });
	}

	exportReportTemplate(reportId: number, model: ExportDataRequest): Observable<any> {
		return this.apiService.post(Any, `reporting/${reportId}/export`, model, { observe: 'response', responseType: 'blob' });
	}
}
