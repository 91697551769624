import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { arrayHasChanged } from '@nstep-common/utils';
import { chain } from 'lodash';
import { DropdownOption } from 'projects/common/semantic-ui/components/dropdown/dropdown-option';

@Component({
	selector: 'sm-multiple-select',
	template: `<ng-content></ng-content>`
})
export class MultipleSelectComponent implements AfterViewInit, OnDestroy, OnChanges {
	jQueryElement: any;

	@Input() values: any[] = [];
	@Output() valuesChange = new EventEmitter<any[]>();

	@Input() options: DropdownOption[] = [];

	preventCycle = false;

	constructor(private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		if (this.jQueryElement) {
			return;
		}

		for (const option of this.options) {
			option.selected = this.values.some(v => option.value == v);
		}

		setTimeout(() => {
			this.jQueryElement = $(this.elementRef.nativeElement).dropdown({
				values: this.options,
				allowAdditions: true,
				showOnFocus: false,
				onChange: (values: string) => {
					const split = values.split(",");

					this.values = chain(this.options)
						.filter(o => split.includes(o.value.toString()))
						.map(o => o.value)
						.value();

					setTimeout(() => {
						this.preventCycle = true;
						this.valuesChange.emit(this.values);
					});
				}
			});
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.jQueryElement) {
			this.ngAfterViewInit();
		}
		else {
			if (this.preventCycle) {
				this.preventCycle = false;
				return;
			}

			if (arrayHasChanged(changes['options'])) {
				this.jQueryElement.dropdown('clear', true);
				this.jQueryElement.dropdown('change values', this.options);
			}

			if (arrayHasChanged(changes['values'])) {
				if (!this.values.length) {
					this.jQueryElement.dropdown('clear', true);
				}
				else {
					this.jQueryElement.dropdown('set selected', [...this.values]);
				}
			}
		}
	}

	ngOnDestroy(): void {
		this.jQueryElement?.dropdown('destroy');
	}
}