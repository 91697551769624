<h3 class="ui header">
	<i class="balance scale left icon"></i>
	<div class="content">
		Balance History
		<div class="sub header">Rations</div>
	</div>
</h3>
<br />

<div class="ui form">
	<div class="three fields">
		<div class="field" app-field [error]="validation | path: 'cardNumber'">
			<label>Card Number:</label>
			<div class="ui fluid input">
				<input type="text" [(ngModel)]="balanceHistoryModel.cardNumber">
			</div>
		</div>

		<div class="field" app-field [error]="validation | path: 'date'">
			<label>From:</label>
			<sm-calendar class="ui calendar"
				[settings]="calendarSettings"
				[(value)]="balanceHistoryModel.date">
				<div class="ui fluid input left icon">
					<i class="calendar icon"></i>
					<input type="text" readonly placeholder="Date" />
				</div>
			</sm-calendar>
		</div>

		<div class="field">
			<label>Rationed Type:</label>
			<sm-dropdown class="ui fluid selection dropdown label"
				[(value)]="selectedRationType"
				[options]="rationsDropdownValues"
				(valueChange)="initializeTable($event === 0)">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
		</div>
	</div>

	<div class="field">
		<app-table-view
			[data]="tableData"
			[noSearch]="true"
			[dataReady]="tableDataReady"
			[columns]="displayTableColumns">
		</app-table-view>
	</div>
</div>