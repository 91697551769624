import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ValidationErrors } from 'fluentvalidation-ts';
import { flatten } from 'lodash';
import { EMPTY, catchError, finalize, tap } from 'rxjs';

import { AuthService, AuthenticationModel, BaseComponent } from '@nstep-common/core';
import { AppSource } from '@nstep-common/utils';
import { LoginModelValidator } from '@nstep-internal/pages';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit {

	validation: ValidationErrors<AuthenticationModel> = {};
	errors: string[] = [];
	loading: boolean = false;
	requires2F: boolean = false;

	constructor(private authService: AuthService,
		private router: Router) {
		super();
	}

	ngOnInit(): void {
		if (this.authService.JWT) {
			this.router.navigate(['']);
		}
	}
	
	validate(value: AuthenticationModel): void {
		const validator = new LoginModelValidator();
		this.validation = validator.validate(value)
	}

	onSubmit(value: AuthenticationModel): void {
		this.validate(value);

		if (Object.keys(this.validation).length != 0) {
			return;
		}

		this.loading = true;

		this.subscriptions.push(this.authService.logIn(value)
			.pipe(
				tap(() => this.router.navigate([''])),
				catchError((resp: any) => {
					const errors = flatten(Object.values(resp)) as string[];

					if (errors.includes('Requires 2F.')) {
						this.requires2F = true;
						return EMPTY;
					}

					if (errors.includes('Password requires reset.')) {
						this.router.navigate(['change-password'], {
							state: {
								userName: value.userName,
								appSource: AppSource.Internal
							}
						});

						return EMPTY;
					}

					this.errors = errors;

					return EMPTY;
				}),
				finalize(() => this.loading = false)
			)
			.subscribe());
	}
}
