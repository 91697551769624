import { Component } from '@angular/core';
import { BaseComponent } from '@nstep-common/core';
import { createProxy, toast } from '@nstep-common/utils';
import { UpdateTransactionsModel, UpdateTransactionsModelValidator } from '@nstep-internal/pages';
import { SystemService } from '@nstep-internal/shared';
import { ValidationErrors } from 'fluentvalidation-ts';

@Component({
	selector: 'app-update-transactions',
	templateUrl: './update-transactions.component.html',
})
export class UpdateTransactionsComponent extends BaseComponent {

	loading = false;
	isValid = false;

	updateModel: UpdateTransactionsModel = createProxy(new UpdateTransactionsModel(), {
		set: () => this.validate(this.updateModel)
	});

	validation: ValidationErrors<UpdateTransactionsModel> = {};

	validate(value: UpdateTransactionsModel): void {
		const validator = new UpdateTransactionsModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private systemConfigurationService: SystemService) {
		super();
	}

	onUpdateTransactions(): void {
		this.loading = true;

		this.systemConfigurationService.updateTransactions(this.updateModel.amisCardNumber).subscribe({
			next: () => {
				this.loading = false;
				toast('Success', `AMIS Card ${this.updateModel.amisCardNumber} successfully updated!`, 'green');
			},
			error: (response: { [key: string]: string[] }) => {
				this.loading = false;

				toast('Error', `AMIS Card ${this.updateModel.amisCardNumber} could not be updated!`, 'red');
			}
		})
	}
}



