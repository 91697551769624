<div class="ui centered grid">
	<div class="center aligned column" app-loading [enabled]="loading" style="width: 450px; margin-top: 15%">
		<div class="ui segment">
			<img src="assets/images/logo.png" style="width: 170px;">
			<div class="ui divider"></div>
			
			<p *ngIf="!requires2F">
				Your password has been recently reset.<br /> 
				For security reasons you should change your password now!
			</p>

			<form autocomplete="off" class="ui large form">
				<ng-container *ngIf="!requires2F">
					<div class="ui segment">
						<div class="field">
							<div class="field" app-field [error]="validation | path: 'odlPassword'">
								<div class="ui left icon input">
									<i class="lock icon"></i>
									<input type="text"
										style="-text-security:disc; -webkit-text-security:disc;"
										autoComplete="off"
										name="odlPassword"
										[placeholder]="'Old Password'"
										[(ngModel)]="model.oldPassword">
								</div>
							</div>
						</div>
	
						<div class="field">
							<div class="field" app-field [error]="validation | path: 'newPassword'">
								<div class="ui left icon input">
									<i class="lock icon"></i>
									<input type="text"
										style="-text-security:disc; -webkit-text-security:disc;"
										autoComplete="off"
										name="newPassword"
										[placeholder]="'New Password'"
										[(ngModel)]="model.newPassword">
								</div>
							</div>
						</div>
	
						<div class="field">
							<div class="field" app-field [error]="validation | path: 'confirmPassword'">
								<div class="ui left icon input">
									<i class="lock icon"></i>
									<input type="text"
										style="-text-security:disc; -webkit-text-security:disc;"
										autoComplete="off"
										name="confirmPassword"
										[placeholder]="'Confirm Password'"
										[(ngModel)]="model.confirmPassword">
								</div>
							</div>
						</div>						
					</div>
				</ng-container>

				<ng-container *ngIf="requires2F">
					<div class="field" app-field [error]="validation | path: 'twoFactorCode'">
						<label>
							Please enter code from authenticator:<br />
						</label>
						<div class="ui left icon input">
							<i class="shield alternate icon"></i>
							<input type="text"
								autoComplete="off"
								name="twoFactorCode"
								[placeholder]="'xxxxxx'"
								[maxlength]="6"
								[(ngModel)]="model.twoFactorCode">
						</div>
					</div>
				</ng-container>

				<button class="ui fluid large blue button" type="button" (click)="onSubmit()">Change Password</button>

				<div class="field" app-field *ngIf="errors.length" [errors]="errors"></div>
			</form>
		</div>
	</div>
</div>