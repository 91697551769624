import { merge } from "lodash";

import { JsonMapperSettings } from "./json-settings";
import { JsonMapperResult } from "./json-mapper-result";
import { JsonSerializer } from "./json-serializer";
import { JsonDeserializer } from "./json-deserializer";
import { JsonPropertyMetadata } from "./json-reflect";

export class JsonMapper {
	private settings = new JsonMapperSettings();
	private serializer: JsonSerializer;
	private deserializer: JsonDeserializer;

	constructor(settings: Partial<JsonMapperSettings> | null = null) {
		if (settings) {
			merge(this.settings, settings);
		}

		this.serializer = new JsonSerializer(this.settings);
		this.deserializer = new JsonDeserializer(this.settings);
	}

	public serializeObject<T>(source: any, type: { new(): T }): JsonMapperResult<any> {
		const errors = {};
		const obj: any= {};//this._serializeObject(source, type, errors);

		const result = new JsonMapperResult<T[]>({
			value: obj,
			errors: errors
		});

		return result;
	}

	public serializeArray<T>(source: any[], type: { new(): T }): JsonMapperResult<any[]> {
		const errors: any[] = [];
		const arr: T[] = [];// this._serializeArray(source, type, errors);

		const result = new JsonMapperResult<T[]>({
			value: arr,
			errors: errors
		});

		return result;
	}

	public deserializeObject<T>(source: any, type: { new(): T }): JsonMapperResult<T> {
		const errors = {};
		const obj = this.deserializer.deserializeObject<T>(source, type, errors);

		const result = new JsonMapperResult<T>({
			value: obj,
			errors: errors
		});

		return result;
	}
	
	public deserializeArray<T>(source: any[], metadata: Partial<JsonPropertyMetadata<T>>): JsonMapperResult<T[]> {
		const errors: any[] = [];
		const arr = this.deserializer.deserializeArray<T>(source, new JsonPropertyMetadata<T>(metadata), errors);

		const result = new JsonMapperResult<T[]>({
			value: arr,
			errors: errors
		});

		return result;
	}
}