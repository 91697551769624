import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';

import { Observable, catchError, of, throwError } from 'rxjs';
import { toastRequestUnauthorized } from "@nstep-common/utils";
import { AuthService } from '@nstep-common/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private authService: AuthService) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getAccessToken()) })

		return next.handle(request).pipe(catchError(err => {
			if (err instanceof HttpErrorResponse && [401, 403].includes(err.status) && !request.url.endsWith('account/log-out')) {
				toastRequestUnauthorized(err.status);
				this.authService.logOut(true);
				return of();
			} else {
				return throwError(() => err);
			}

		}));
	}
}
