<h3 class="ui header">
	<i class="users cog icon"></i>
	<div class="content">
		Roles
		<div class="sub header">Membership</div>
	</div>
</h3>
<br />

<div class="ui stackable grid">
	<div class="row">
		<div class="three wide column">
		</div>
		<div class="thirteen wide column">
			<button class="ui right floated primary button"
				(click)="createRole()">
				<i class="plus icon"></i>Add Role
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #roleActionCell let-item="item">
	<td style="text-align: center;">
		<ng-container *ngIf="!item.isDisabled">
			<a href="javascript:void(0);" (click)="editRole(item)">Edit</a> &#47;
		</ng-container>

		<a href="javascript:void(0);" (click)="toggleRole(item)">{{ item.isDisabled ? "Enable" : "Disable" }}</a>

		<ng-container *ngIf="!item.isDisabled">
			&#47; <a href="javascript:void(0);" (click)="confirm('ask-delete', item)">Delete</a>
		</ng-container>
	</td>
</ng-template>

<ng-template #apiTree let-permissions="permissions">
	<div class="ui list">
		<div class="item" *ngFor="let perm of permissions">
			<ng-container *ngIf="(perm.children && perm.children.length) else renderLeaf">
				<strong class="ui text">{{perm.name}}</strong>
				<ng-template
					[ngTemplateOutlet]="apiTree"
					[ngTemplateOutletContext]="{ permissions: perm.children }">
				</ng-template>
			</ng-container>

			<ng-template #renderLeaf>
				<div class="ui checkbox"
					[class.disabled]="dependants[perm.value] > 0">
					<input type="checkbox"
						[(ngModel)]="state[perm.value]"
						[disabled]="dependants[perm.value] > 0" />
					<label>{{perm.name}}</label>
				</div>
			</ng-template>
		</div>
	</div>
</ng-template>

<ng-template #appTree let-permissions="permissions">
	<div class="ui list">
		<div class="item" *ngFor="let perm of permissions">
			<ng-container *ngIf="(perm.children && perm.children.length) else renderLeaf">
				<strong class="ui text">{{perm.name}}</strong>

				<ng-template
					[ngTemplateOutlet]="appTree"
					[ngTemplateOutletContext]="{ permissions: perm.children }">
				</ng-template>
			</ng-container>

			<ng-template #renderLeaf>
				<div class="item">
					<div class="ui checkbox">
						<input type="checkbox"
							[(ngModel)]="state[perm.id]"
							(ngModelChange)="toggleDepedencies($event, perm)">
						<label>{{perm.name}}</label>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</ng-template>

<sm-modal #upsertModal
	[isBig]="!role.id || !users.length"
	[isFullscreen]="!!role.id && !!users.length"
	[isLoading]="waitingForServer"
	[loadingText]="'Saving'">
	<ng-container header>
		<i class="users cog icon"></i> {{ role.id ? ("Edit Role: " + role.name + "") : "Create Role" }}
	</ng-container>

	<ng-container content>
		<div class="ui grid">
			<div [attr.class]="role.id ? 'twelve wide column' : 'sixteen wide column'">
				<div class="ui equal width internally celled grid">
					<div class="row">
						<div class="sixteen wide column">
							<div class="ui form">
								<div class="fields">
									<div class="five wide field" app-field [error]="validation | path: 'group'">
										<label>Name*</label>
										<input type="text" [(ngModel)]="role.name" app-trim />
									</div>

									<div class="eleven wide field" app-field [error]="validation | path: 'description'">
										<label>Description*</label>
										<input type="text" [(ngModel)]="role.description" app-trim />
									</div>
								</div>

								! All fields marked with '*' are mandatory.
							</div>

							<br />

							<h4 class="ui header">
								<i class="user lock icon"></i>
								<div class="content">
									Access Level
									<div class="sub header">Define role permissions for Apps & APIs</div>
								</div>
							</h4>

							<div style="display: flex;">
								<ng-container *ngFor="let target of targets">
									<b class="ui segment"
										style="margin-right: 10px; margin-top: 10px; margin-bottom: 10px;"
										*ngIf="!state[target.id]">
										<i class="{{target.icon}}"></i>
										<span class="ui text">{{target.name}}</span>
										<div class="ui small toggle checkbox"
											style="margin-left: 15px;">
											<input type="checkbox"
												[(ngModel)]="state[target.id]"
												(ngModelChange)="target?.onToggled($event)" />
											<label>{{ state[target.id] ? 'ON' : 'OFF' }}</label>
										</div>
									</b>
								</ng-container>
							</div>
						</div>
					</div>

					<div class="row">
						<ng-container *ngFor="let target of targets">
							<div class="column"
								style="padding-left: 2em"
								*ngIf="state[target.id]">
								<b>
									<i class="{{target.icon}}"></i>
									<span class="ui blue text">{{target.name}}</span>
									<div class="ui small toggle checkbox"
										style="margin-left: 15px;">
										<input type="checkbox"
											[(ngModel)]="state[target.id]"
											(ngModelChange)="target?.onToggled($event)" />
										<label>{{ state[target.id] ? 'ON' : 'OFF' }}</label>
									</div>
								</b>
								<br />
								<br />
								<button class="ui tiny compact secondary basic button"
									(click)="toggleAll(true, target.permissions)">
									<i class="check icon"></i>&nbsp;Enable All
								</button>

								<button class="ui tiny compact secondary basic button"
									(click)="toggleAll(false, target.permissions)">
									<i class="times icon"></i>&nbsp;Reset All&nbsp;
								</button>
								<br />
								<br />

								<div style="max-height: 500px; overflow-y: auto;">
									<ng-template
										[ngTemplateOutlet]="target.hasDependencies ? appTree : apiTree"
										[ngTemplateOutletContext]="{ permissions: target.permissions }">
									</ng-template>
								</div>
							</div>
						</ng-container>
					</div>

					<div class="row">
						<div class="sixteen wide column">
							<app-error-message [errors]="errors"></app-error-message>
						</div>
					</div>
				</div>
			</div>

			<div class="four wide column"
				*ngIf="role.id">
				<div class="ui very long scrolling segment"
					style="height: 100%;">
					<h5 class="ui header">Users:</h5>

					<div class="ui list"
						*ngIf="users.length else noUsers">
						<div class="item" *ngFor="let user of users">
							<label>
								<i class="user icon"></i>
								{{user}}
							</label>
						</div>
					</div>

					<ng-template #noUsers>
						<br />
						No users with this role.
					</ng-template>
				</div>
			</div>
		</div>

	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="upsertModal.toggle()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="saveRole()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal #confirmModal [isTiny]="true">
	<ng-container header>
		Confirm
	</ng-container>

	<ng-container content>
		<p>{{confirmMessage}}</p>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="confirmModal.toggle()">
			No
			<i class="cancel icon"></i>
		</button>
		<button class="ui green right labeled icon button"
			(click)="confirm('delete-role')">
			Yes
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>