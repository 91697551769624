import { SimpleChange } from "@angular/core";
import { isArray } from "lodash";

export function hasChanged(change: SimpleChange, notEmpty = false): boolean {
	let hasChanged = change &&
		change.currentValue != change.previousValue &&
		(!notEmpty || change.currentValue);

	if (!hasChanged &&
		change &&
		change.currentValue &&
		change.previousValue &&
		isArray(change.currentValue) &&
		isArray(change.previousValue)) {
		hasChanged = change.currentValue.length != change.previousValue.length;
	}

	return hasChanged;
}