import { ExtraValidator } from "@nstep-common/utils";
import { AdminAdjustmentsModel, AttachmentData } from "@nstep-internal/pages";

export class AdminAjustmentsModelValidator extends ExtraValidator<AdminAdjustmentsModel> {
	constructor() {
		super();

		this.ruleFor('amisCardNumber')
			.must(amisCardNumber => !!amisCardNumber)
			.withMessage('Please provide a valid card number')
			.matches(/^([A-Za-z]{3})(-[0-9]{7})(-[0-9]{2})$/)
			.withMessage('Please provide a valid card number');

		this.ruleFor('from')
			.must(from => !!from)
			.withMessage('Please provide the start date')
			.must((value, model) => {

				if (!model.to) {
					return true;
				}

				if (model.from && model.to) {
					return new Date(model.from.toDateString()) <= new Date(model.to.toDateString());
				}

				return false;
			})
			.withMessage('Starting date cannot be greater than the end date')

		this.ruleFor('to')
			.must(to => !!to)
			.withMessage('Please provide the end date');
	}
}

export class AttachmentDataValidator extends ExtraValidator<AttachmentData> {
	constructor(currentTransctionBalance: number) {
		super();

		this.ruleFor('transactionAmount')
			.must(transactionAmount => !!transactionAmount && transactionAmount > 0 && transactionAmount <= currentTransctionBalance)
			.withMessage(`Value must be a positive number < ${currentTransctionBalance + 1}`)
			.when(model => model.transactionId === 0);
	}
}