import { ExtraValidator } from "@nstep-common/utils";
import { EntitlementMatrixModel, EntitlementMatrixGroupValidations, AssociationItem } from "@nstep-internal/pages";

export class EntitlementMatrixModelValidator extends ExtraValidator<EntitlementMatrixModel> {
	constructor() {
		super();

		//this.ruleFor('headquarterId')
		//	.must(headquarterId => !!headquarterId)
		//	.withMessage('Headquarter is required')
		//	.when(model => model.groupPropertiesValidation === EntitlementMatrixGroupValidations.MainPage);

		this.ruleFor('matrixVersionId')
			.must(matrixVersionId => !!matrixVersionId)
			.withMessage('Matrix version is required')
			.when(model => model.groupPropertiesValidation === EntitlementMatrixGroupValidations.MainPage);

		this.ruleFor('matrixVersionStartDate')
			.must(matrixVersionStartDate => !!matrixVersionStartDate)
			.withMessage('Start Date is required')
			.when(model => model.groupPropertiesValidation === EntitlementMatrixGroupValidations.AddMatrix);

		this.ruleFor('matrixVersionEndDate')
			.must(matrixVersionEndDate => !!matrixVersionEndDate)
			.withMessage(`End Date is required when 'End of time' is not checked`)
			.when(model => model.groupPropertiesValidation === EntitlementMatrixGroupValidations.AddMatrix && !model.endOfTime);

		this.ruleFor('entitlementCodeId')
			.must(entitlementCodeId => !!entitlementCodeId)
			.withMessage('Code is required')
			.when(model => model.groupPropertiesValidation === EntitlementMatrixGroupValidations.EntitlementCodeInfo);

		this.ruleFor('entitlementCodeGroup')
			.must(entitlementCodeGroup => !!entitlementCodeGroup)
			.withMessage('Group is required')
			.matches(/^.{2,100}$/)
			.withMessage('A valid input is required with the length between 2-100 characters!')
			.when(model => model.groupPropertiesValidation === EntitlementMatrixGroupValidations.EntitlementCodeInfo);

		this.ruleFor('entitlementCodeDescription')
			.must(entitlementCodeDescription => !!entitlementCodeDescription)
			.withMessage('Description is required')
			.matches(/^.{2,100}$/)
			.withMessage('A valid input is required with the length between 2-100 characters!')
			.when(model => model.groupPropertiesValidation === EntitlementMatrixGroupValidations.EntitlementCodeInfo);
	}
}

export class AssociationItemValidator extends ExtraValidator<AssociationItem> {
	constructor() {
		super();

		this.ruleFor('amount')
			.notNull()
			.withMessage('Value must be a positive number of maximum 5 digits!')
			.inclusiveBetween(0, 99999)
			.withMessage('Value must be a positive number of maximum 5 digits!')
	}
}