export class NonRationedTransactionDto {
	amisCardId!: number;
	amisCardNumber!: string;
	entitlementTypeId!: number;
	entitlementTypeName!: string;
	id!: number;
	operatorAMISCardId!: number;
	operatorAMISCardNumber!: string;
	posLocationId!: number;
	posLocationName!: string;
	timestamp!: string;
	validity!: boolean;
}

export class NonRationedTransactionModel {
	from!: Date | null;
	to!: Date | null;
}
