import { AppPermissions } from "@nstep-internal/shared";

export const routePermissions: { [route: string]: string[] } = {
	'entitlements/codes': [AppPermissions.entitlementCodes],
	'entitlements/types': [AppPermissions.entitlementTypes],
	'entitlements/units': [AppPermissions.entitlementUnits],
	'entitlements/matrix': [AppPermissions.entitlementMatrtix],
	'reports/non-rationed-transactions': [AppPermissions.nonRationedTransactions],
	'reports/reporting': [AppPermissions.reporting],
	'reports/management': [AppPermissions.reportsManagement],
	'pos/locations': [AppPermissions.posLocations],
	'pos/devices': [AppPermissions.posDevices],
	'membership/users': [AppPermissions.users],
	'membership/clients': [AppPermissions.clients],
	'membership/roles': [AppPermissions.roles],
	'rations/admin-adjustments': [AppPermissions.adminAdjustements],
	'rations/balance-history': [AppPermissions.balanceHistory],
	'system/import': [AppPermissions.importHistory],
	'system/export': [AppPermissions.exportArchive],
	'system/update': [AppPermissions.updateTransactions],
	'system/archive': [AppPermissions.exportArchive],
}
