export class BulkAdvanceSettings {
	id!: number;
	headquarterId!: number;
	createDate = new Date();
	month = this.createDate.getMonth() + 2;
	issueMonth = this.createDate.getMonth() + 1;
	enabled = true;

	constructor(data: Partial<BulkAdvanceSettings> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class BulkAdvanceAmisSettings extends BulkAdvanceSettings {
	amisCards = '';
	amisSearchTerm = '';

	constructor(data: Partial<BulkAdvanceAmisSettings> | null = null) {
		if (data) {
			super(data);
			Object.assign(this, data);
		}
	}
}