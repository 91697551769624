import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { PagedQueryParameter, createProxy, toast } from '@nstep-common/utils';
import { NonRationedTransactionModel, NonRationedTransactionModelValidator } from '@nstep-internal/pages';
import { ReportService } from '@nstep-internal/shared';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain } from 'lodash';

@Component({
	selector: 'app-non-rationed-transactions',
	templateUrl: './non-rationed-transactions.component.html'
})
export class NonRationedTransactionsComponent extends BaseComponent implements OnInit {

	tableData: any[] = [];
	tableDataReady = true;
	loading = false;

	isValid = false;

	minDate = new Date('01/01/2014');
	today = new Date(new Date().toDateString());

	calendarSettings = {
		type: 'date',
		minDate: this.minDate,
		maxDate: this.today
	}

	submittedStartDate = ''
	submittedEndDate = '';

	pagedQueryModel: PagedQueryParameter = {
		itemsPerPage: 1000,
		page: 1,
		orderField: '',
		searchBy: '',
		isMultiWordSerch: false
	};

	tableColumns: TableColumn[] = [
		{ name: 'AMISCard Number', key: 'amisCardNumber', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Operator AMISCard Number', key: 'operatorAmisCardNumber', isCellCentered: true, isHeaderCentered: true },
		{ name: 'POSLocation Name', key: 'posLocationName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Entitlement Type Name', key: 'entitlementTypeName', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Timestamp', key: 'timestamp', isCellCentered: true, isHeaderCentered: true }
	];

	nonRationedTransactionModel: NonRationedTransactionModel = createProxy(new NonRationedTransactionModel(), {
		set: () => this.validate(this.nonRationedTransactionModel)
	});

	validation: ValidationErrors<NonRationedTransactionModel> = {};

	validate(value: NonRationedTransactionModel): void {
		const validator = new NonRationedTransactionModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private reportService: ReportService) {
		super();
	}

	ngOnInit(): void {
		this.nonRationedTransactionModel.from = this.minDate;
		this.nonRationedTransactionModel.to = this.today;
	}

	onSubmit(): void {
		this.tableDataReady = false;
		this.tableData = [];

		this.submittedStartDate = formatDate(this.nonRationedTransactionModel.from!, 'yyyy-MM-dd', 'en');
		this.submittedEndDate = formatDate(this.nonRationedTransactionModel.to!, 'yyyy-MM-dd', 'en');

		this.subscriptions.push(
			this.reportService.getNonRationedTransactions(this.submittedStartDate, this.submittedEndDate, this.pagedQueryModel).subscribe({
				next: response => {
					this.tableData = chain(response.body)
						.map(e => ({
							id: e.id,
							amisCardNumber: e.amisCardNumber,
							operatorAmisCardNumber: e.operatorAmisCardNumber,
							posLocationName: e.posLocationName,
							entitlementTypeName: e.entitlementTypeName,
							timestamp: formatDate(new Date(e.timestamp), 'dd/MM/yyyy - h:mm a', 'en'),
						}))
						.value();

					if (!this.tableData.length) {
						toast('Warning', `There is no data for the selected period!`, 'orange');
					}

					this.tableDataReady = true;
				},
				error: () => {
					toast('Error', `Transactions could not be loaded!`, 'red');
					this.tableDataReady = true;
				}
			})
		);
	}

	exportReport(): void {

		if (!this.submittedStartDate || !this.submittedEndDate) {
			toast('Warning', 'Please submit the date before exporting the report', 'orange');
			return;
		}

		this.loading = true;

		this.subscriptions.push(
			this.reportService.exportNonRationedTransactionsReport(this.submittedStartDate, this.submittedEndDate).subscribe({
				next: response => {
					const blob: Blob = response.body as Blob;
					const fileName = response.headers.get('Content-Disposition')?.replace(/['"]+/g, '').split(';')[1].trim().split('=')[1];

					const anchor = document.createElement('a');
					anchor.download = fileName;
					anchor.href = window.URL.createObjectURL(blob)
					anchor.click();

					this.loading = false;
					toast('Success', `File exported successfully!`, 'green');
				},
				error: () => {
					this.loading = false;
					toast('Error', `Failed to export file!`, 'red');
				}
			})
		);
	}

	pageChange(event: any): void { }
}
