import { ApiService } from "@nstep-common/core";
import { AppSource, LogEntry, LogLevel, LogModel, LogPublisher } from "@nstep-common/utils";

import { Observable, of } from "rxjs";

export class LogWebApi extends LogPublisher {

	constructor(private apiService: ApiService) {
		super();
	}

	log(record: LogEntry): Observable<any> {
		//temporary solution because internal does not have log endpoint
		if (record.source === AppSource.Internal) {
			return of();
		}

		const model = new LogModel();

		model.methodName = this.getCaller(5);
		model.parameters = record.buildLogString();
		model.deviceIdentifier = record.source == AppSource.Public ? 'NSTEP Public Web App' : 'NSTEP Internal Web App';
		model.timeStamp = record.entryDate;
		model.isError = record.level === LogLevel.Error;

		return this.apiService.postNoContent('account/post-logs', model);
	}
}