import { JsonProperty } from "@nstep-common/utils";

export class NationDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	nationName!: string;
}

export class RoleDto {
	@JsonProperty({ type: Number })
	roleId!: number;

	@JsonProperty({ type: String })
	roleName!: string;

	constructor(data: Partial<RoleDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class OperatorCardDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	cardNumber!: string;
}

export class HeadquarterWithNationDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Number })
	nationId!: number;
}

export class SubRoleDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	roleId!: number;

	@JsonProperty({ type: String, nullable: true })
	name!: string | null;
}

export class UsersInSubRole {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	userId!: number;

	@JsonProperty({ type: Number })
	subRoleId!: number;
}

export class UserDto {

	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	userName!: string;

	password!: string | null;

	@JsonProperty({ type: String, nullable: true })
	name!: string | null;

	@JsonProperty({ type: String, nullable: true })
	email!: string | null;

	@JsonProperty({ type: String, nullable: true })
	telephone!: string | null;

	@JsonProperty({ type: String, nullable: true })
	other!: string | null;

	@JsonProperty({ type: Date, nullable: true })
	startDate: Date | null = null;

	@JsonProperty({ type: Date, nullable: true })
	endDate: Date | null = null;

	@JsonProperty({ type: Number, nullable: true })
	nationId!: number | null;

	@JsonProperty({ type: Boolean })
	resetted!: boolean;

	@JsonProperty({ type: Number, nullable: true })
	cardOperatorId!: number | null;

	@JsonProperty({ type: String })
	idNumber!: string;

	@JsonProperty({ type: NationDto, nullable: true })
	nation!: NationDto | null;

	@JsonProperty({ type: RoleDto })
	role!: RoleDto;

	@JsonProperty({ type: OperatorCardDto, nullable: true })
	card!: OperatorCardDto | null;

	@JsonProperty({ type: HeadquarterWithNationDto, array: true })
	headquarters!: HeadquarterWithNationDto[];

	headquartersIds: number[] = [];

	@JsonProperty({ type: SubRoleDto, array: true })
	subRoles!: SubRoleDto[];

	subRolesIds!: number[];

	//@JsonProperty({ type: UsersInSubRole, array: true })
	//usersInSubRoles!: UsersInSubRole[];
}

export class NationWithHqDtos extends NationDto {
	@JsonProperty({ type: Number })
	timezoneOffset!: number;

	@JsonProperty({ type: HeadquarterWithNationDto, array: true })
	headquarters!: HeadquarterWithNationDto[];
}

export class DisableUserModel {
	userId!: Number;
	endDate!: Date | null;
}

export class ResetPasswordModel {
	userId!: number;
	password!: string | null;
	email!: string | null;
}


