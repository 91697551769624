import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, BaseComponent } from '@nstep-common/core';
import { AppPermissions, routePermissions } from '@nstep-internal/shared';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrl: './layout.component.css'
})
export class LayoutComponent extends BaseComponent implements OnInit {
	protected permissions = AppPermissions;

	constructor(private authService: AuthService,
		private router: Router) {
		super();
	}
	
	ngOnInit(): void {
		if (this.authService.JWT) {
			for (const route of Object.keys(routePermissions)) {
				const permissions = routePermissions[route];

				if (this.authService.hasAccess(...permissions)) {
					this.router.navigate([`/${route}`]);
					return;
				}
			}
		}
	}
}
