import { ExtraValidator } from "@nstep-common/utils";
import { PosDeviceModel } from "@nstep-internal/pages";

export class PosDeviceModelValidator extends ExtraValidator<PosDeviceModel> {
	constructor() {
		super();

		this.ruleFor('posDeviceName')
			.must(posDeviceName => !!posDeviceName)
				.withMessage('Name is required')
			.notEmpty()
			.matches(/^.{4,50}$/)
				.withMessage('A valid input is required with the length between 4-50 characters');

		this.ruleFor('posLocationId')
			.must(posLocationId => !!posLocationId)
				.withMessage('POS location is required');

		this.ruleFor('deviceIdentifier')
			.must(deviceIdentifier => !!deviceIdentifier)
				.withMessage('Device identifier is required')
			.notEmpty()
			.matches(/^.{10,20}$/)
				.withMessage('A valid input is required with the length between 10-20 characters');
	}
}