
import moment, { Moment } from "moment";

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { UpdateDateHttpInterceptor } from "@nstep-common/core";
import { expandedLog, toastHttpError, toastGenericError, Enviroment, Cache, JsonMapper, toastRequestDenied, Any } from "@nstep-common/utils";
import { Observable, catchError, delay, map, of, shareReplay, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { flatten, isArray } from "lodash";

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	protected apiUrl: string;
	private cache: any = {};

	constructor(private httpClient: HttpClient,
		private environment: Enviroment) {

		let url = this.environment.apiUrl;

		if (!url.startsWith('http')) {
			const hostUrl = window.location.protocol + '//' + window.location.host;
			url = `${hostUrl}${this.environment.apiUrl}`;
		}

		this.apiUrl = url;
		this.environment = this.environment;
	}

	protected getCaller(index: number): string {
		const err = new Error();
		const frames = err.stack ? err.stack.split('\n') : [];

		for (let i = index; i > 0; i--) {
			const caller = frames[i].trim().split(' ')[1];

			if (!caller.startsWith('http')) {
				return caller;
			}
		}

		return 'N/A';
	}

	protected handleResult<T>(url: string, params: any, result: any, startedAt: Moment, caller: string, type: { new(): T }): T {
		this.logRequest(url, params, result, true, startedAt, caller);

		const jsonMapper = new JsonMapper();
		const deserializeObject = jsonMapper.deserializeObject(result, type);

		if (Object.keys(deserializeObject.errors).length) {
			console.error("Bad format received from server.\n", deserializeObject.errors);
		}

		return deserializeObject.value;
	}

	protected handleError(url: string, params: any, resp: any, caller?: string): Observable<never> {
		this.logRequest(url, params, resp, false, undefined, caller);

		if (resp?.error?.errors) {
			return throwError(() => resp.error.errors);
		}
		else if (resp?.error?.detail) {
			toastRequestDenied(resp.error.detail);

			return throwError(() => []);
		}
		else if (resp instanceof HttpErrorResponse) {
			toastHttpError(resp);

			return throwError(() => []);
		}
		else {
			toastGenericError();

			return throwError(() => []);
		}
	}

	private logRequest(target: string, params: any, response: any, isSuccess: boolean, startedAt?: Moment, caller?: string) {
		if (!this.environment.logRequests) {
			return;
		}

		const duration = startedAt ? moment.duration(moment.utc().diff(startedAt)).asSeconds() : null;

		console.groupCollapsed(`REQUEST ${duration ? `(${duration}s)` : ''}%c ${isSuccess ? 'SUCCESS' : 'FAIL'}%c ${target} %c${caller ? `\nfrom ${caller}` : ''}`,
			`color: ${isSuccess ? 'limegreen' : 'red'}`,
			'font-weight: normal; color: orange',
			'font-weight: normal; color: grey');

		const formattedParams = {
			...params
		};

		UpdateDateHttpInterceptor.formatDates(formattedParams);

		if (this.environment.expandedLogRequests) {
			expandedLog(formattedParams, 'PARAMS');
			expandedLog(response, 'RESPONSE');
		}
		else {
			console.info('PARAMS', this.removePrototype(formattedParams));
			console.info('RESPONSE', this.removePrototype(response));
		}

		console.groupEnd();
	}

	private removePrototype(arg: any) {
		if (!arg) {
			return arg;
		}

		if (typeof arg === 'object' && !arg.length) {
			const tempObj = JSON.parse(JSON.stringify(arg));
			tempObj.__proto__ = null;

			return tempObj;
		}

		return arg;
	}

	private getCachedObservable<T>(relativeUrl: string, options: any, observable: Observable<T>) {
		const cache: Cache<T> = this.cache[relativeUrl] ?? new Cache<T>();

		let cachedObservable = cache.getValue(options);

		if (!cachedObservable || cachedObservable == of()) {
			cachedObservable = observable.pipe(delay(100), shareReplay(1));
			cache.setValue(cachedObservable, options);
		}

		return cachedObservable;
	}

	private getHttpObservable<T>(method: <T>(...args: any) => Observable<T>, url: string, body: any | null, options: any, type: { new(): T }): Observable<T> {
		const startedAt = moment.utc();
		const caller = this.getCaller(4);

		const fullUrl = `${method.name.toUpperCase()}:${url}`;

		return method.apply(this.httpClient, [url, body ?? options, options])
			.pipe(
				map(res => this.handleResult<T>(fullUrl, body ?? options, res, startedAt, caller, type)),
				catchError(err => this.handleError(fullUrl, body ?? options, err, caller))
			);
	}

	get<T>(type: { new(): T }, relativeUrl: string, options?: any): Observable<T> {
		const observable = this.getHttpObservable<T>(this.httpClient.get<T>, `${this.apiUrl}/${relativeUrl}`, null, options, type);
		return this.getCachedObservable(relativeUrl, options, observable);
	}

	post<T>(type: { new(): T }, relativeUrl: string, body?: any, options?: any): Observable<T> {
		const observable = this.getHttpObservable<T>(this.httpClient.post<T>, `${this.apiUrl}/${relativeUrl}`, body, options, type);
		return observable;
	}

	postNoContent(relativeUrl: string, body?: any, options?: any): Observable<any> {
		const observable = this.getHttpObservable<any>(this.httpClient.post<any>, `${this.apiUrl}/${relativeUrl}`, body, options, Any);
		return observable;
	}

	put<T>(type: { new(): T }, relativeUrl: string, body?: any, options?: any): Observable<T> {
		const observable = this.getHttpObservable<T>(this.httpClient.put<T>, `${this.apiUrl}/${relativeUrl}`, body, options, type);
		return observable;
	}

	putNoContent(relativeUrl: string, body?: any, options?: any): Observable<any> {
		const observable = this.getHttpObservable<any>(this.httpClient.put<any>, `${this.apiUrl}/${relativeUrl}`, body, options, Any);
		return observable;
	}

	patch<T>(type: { new(): T }, relativeUrl: string, body?: any, options?: any): Observable<T> {
		const observable = this.getHttpObservable<T>(this.httpClient.patch<T>, `${this.apiUrl}/${relativeUrl}`, body, options, type);
		return observable;
	}

	patchNoContent(relativeUrl: string, body?: any, options?: any): Observable<any> {
		return this.httpClient.patch(`${this.apiUrl}/${relativeUrl}`, body, options);
	}

	delete<T>(type: { new(): T }, relativeUrl: string, options?: any): Observable<T> {
		const observable = this.getHttpObservable<T>(this.httpClient.delete<T>, `${this.apiUrl}/${relativeUrl}`, null, options, type);
		return observable;
	}

	deleteNoContent(relativeUrl: string, options?: any): Observable<any> {
		const observable = this.getHttpObservable<any>(this.httpClient.delete<any>, `${this.apiUrl}/${relativeUrl}`, null, options, Any);
		return observable;
	}

	invalidateCache(relativeUrl: string) {
		if (!this.cache[relativeUrl]) {
			return;
		}

		this.cache[relativeUrl].clear();
	}
}