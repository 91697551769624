import { ExtraValidator } from "@nstep-common/utils";
import { LoginModel } from "@nstep-internal/pages";

export class LoginModelValidator extends ExtraValidator<LoginModel> {
	constructor() {
		super();

		this.ruleFor('userName')
			.must(userName => !!userName)
				.withMessage('The User name field is required.');

		this.ruleFor('password')
			.must(password => !!password)
				.withMessage('The Password field is required.')
			.matches(/^.{8,20}$/)
				.withMessage('Password must have between 8 and 20 characters!');
	}
}