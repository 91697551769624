import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidationErrors } from 'fluentvalidation-ts';
import { createProxy } from "@nstep-common/utils";
import { AuthenticationModel, BaseComponent } from "@nstep-common/core";

@Component({
	selector: 'app-authentication',
	templateUrl: './authentication.component.html',
	styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent extends BaseComponent {
	@Input() userNamePlaceHolder: string = '';
	@Input() passwordPlaceHolder: string = '';
	@Input() userNameMaxLength: number | null = null;
	@Input() passwordMaxLength: number | null = null;
	@Input() requires2F = false;

	@Input() validation: ValidationErrors<AuthenticationModel> = {};
	@Input() errors: string[] = [];
	@Input() loading: boolean = false;

	@Output() validateEvent = new EventEmitter<AuthenticationModel>();
	@Output() submitEvent = new EventEmitter<AuthenticationModel>();

	model = createProxy(new AuthenticationModel(), {
		set: () => this.validate()
	});

	validate(): void {
		this.validateEvent.emit(this.model);
	}

	onSubmit() {
		this.submitEvent.emit(this.model);
	}
}
