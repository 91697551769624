import { Injectable } from '@angular/core';
import { Any, PagedQueryParameter } from '@nstep-common/utils';
import { ApiService } from '@nstep-common/core';
import { EntitlementTypeModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EntitlementTypeService {

	constructor(private apiService: ApiService) { }

	getEntitlementTypes(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'entitlement-type', { observe: 'response', params: model });
	}

	getAllEntitlementTypes(): Observable<any> {
		return this.apiService.get(Any, 'entitlement-type/all');
	}

	getEntitlementTypesByHq(ids: number[]): Observable<any> {
		return this.apiService.post(Any, 'entitlement-type/grouped', ids);
	}

	getEnabledEntitlementTypes(): Observable<any> {
		return this.apiService.get(Any, 'entitlement-type/enabled');
	}

	createEntitlementType(model: EntitlementTypeModel): Observable<EntitlementTypeModel> {
		const postModel = {
			name: model.name,
			rationingTypeId: model.rationed ? 1 : 2,
			minBuyAmount: model.minBuyAmount
		};

		return this.apiService.post(EntitlementTypeModel, 'entitlement-type', postModel);
	}

	updateEntitlementType(model: EntitlementTypeModel): Observable<EntitlementTypeModel> {
		const putModel = {
			id: model.id,
			name: model.name,
			rationingTypeId: model.rationed ? 1 : 2,
			minBuyAmount: model.minBuyAmount
		};

		return this.apiService.put(EntitlementTypeModel, `entitlement-type/${model.id}`, putModel);
	}

	enableEntitlementType(id: number): Observable<any> {
		return this.apiService.patchNoContent(`entitlement-type/${id}`);
	}

	disableEntitlementType(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`entitlement-type/${id}`);
	}

	clearGetEntitlementTypesCache(): void {
		this.apiService.invalidateCache('entitlement-type');
	}
}
