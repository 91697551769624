import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { hasChanged } from '@nstep-common/utils';

@Component({
	selector: 'sm-dropdown',
	template: `<ng-content></ng-content>`
})
export class DropdownComponent implements AfterViewInit, OnDestroy, OnChanges {
	jQueryElement: any;

	@Input() allowReselection = false;
	@Input() clearable = false;
	//@Input() preventInitEvents = true;

	@Input() value: any | null = null;
	@Output() valueChange = new EventEmitter<any>();

	@Input() options: DropdownOption[] = [];
	preventCycle = false;

	constructor(private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		this.jQueryElement = $(this.elementRef.nativeElement);

		this.updateOptions();

		setTimeout(() => this.jQueryElement.dropdown({
			clearable: this.clearable,
			values: this.options,
			showOnFocus: false,
			onChange: (value: string) => {
				if (!this.allowReselection) {
					const alreadySelected = this.options.some(d => d.value == value && (d as any).selected);
					if (alreadySelected) {
						return;
					}
				}

				const opt = this.options.find(o => o.value == value);
				if (opt || (this.value && this.clearable)) {
					setTimeout(() => {
						this.preventCycle = true;
						this.value = opt?.value;
						this.updateOptions();
						this.valueChange.emit(this.value)
					});
				}
			}
		}));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (hasChanged(changes['value']) && !this.preventCycle) {
			if (this.value === null || this.value === undefined) {
				this.jQueryElement?.dropdown('clear', true);
			}
			else {
				this.jQueryElement?.dropdown('set selected', this.value);
				this.preventCycle = true;
			}

			//if (!this.preventInitEvents) {
			//	this.preventCycle = true;
			//	setTimeout(() => this.valueChange.emit(this.value));
			//} else {
			//	this.preventInitEvents = false;
			//}

		}
		else {
			this.preventCycle = false;
		}

		if (hasChanged(changes['options'])) {
			this.updateOptions();
			this.jQueryElement?.dropdown('change values', this.options);
		}
	}

	// ! 'selected' flag is used by SemanticUI only on initialization (i.e. it doesn't manage it after that)
	private updateOptions(): void {
		this.options = this.options.map(opt => {
			(opt as any).selected = opt.value == this.value;
			return opt;
		});
	}

	ngOnDestroy(): void {
		this.jQueryElement.dropdown('destroy');
	}
}