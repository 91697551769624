import { Injectable } from '@angular/core';
import { Any, PagedQueryParameter } from '@nstep-common/utils';
import { ApiService } from '@nstep-common/core';
import { PosDeviceModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PosDeviceService {

	constructor(private apiService: ApiService) { }

	getPosDevices(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'pos-device', { observe: 'response', params: model });
	}

	getPosLocations(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'pos-locations', { observe: 'response', params: model });
	}

	createPosDevice(model: PosDeviceModel): Observable<PosDeviceModel> {
		return this.apiService.post(PosDeviceModel, 'pos-device', model);
	}

	updatePosDevice(model: PosDeviceModel): Observable<PosDeviceModel> {
		return this.apiService.put(PosDeviceModel, 'pos-device', model);
	}

	clearGetPosDevicesCache(): void {
		this.apiService.invalidateCache('pos-device');
	}
}
