import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { valueHasChanged } from '@nstep-common/utils';

@Component({
	selector: '[app-field]',
	template: `
		<ng-content></ng-content>

		<div class="ui basic red pointing prompt label"
			*ngIf="error"
			[innerHTML]="error">
		</div>
	`
})
export class FieldComponent implements OnChanges {
	@Input() error: any | null = null;
	@Input() errors: string[] = [];
	@Input() errorHint = false;

	constructor(private el: ElementRef, private renderer: Renderer2) {
		this.updateErrors();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (valueHasChanged(changes['errors'])) {
			this.error = this.errors.filter(e => e).join('<br/>');
		}

		if (valueHasChanged(changes['error']) || valueHasChanged(changes['errorHint'])) {
			this.updateErrors();
		}
	}

	updateErrors() {
		if (this.error || this.errorHint) {
			this.renderer.addClass(this.el.nativeElement, 'error');
		}
		else {
			this.renderer.removeClass(this.el.nativeElement, 'error');
		}
	}
}