<h3 class="ui header">
	<i class="exchange alternate icon"></i>
	<div class="content">
		Non-Rationed Transactions
		<div class="sub header">Reports</div>
	</div>
</h3>
<br />

<div app-loading [enabled]="loading" class="ui form">
	<div class="fields">
		<div class="four wide field" app-field [error]="validation | path: 'from'">
			<label>From</label>
			<sm-calendar class="ui calendar"
				[settings]="calendarSettings"
				[(value)]="nonRationedTransactionModel.from"
				[maxValue]="nonRationedTransactionModel.to">
				<div class="ui fluid input left icon">
					<i class="calendar icon"></i>
					<input type="text" placeholder="Date" />
				</div>
			</sm-calendar>
		</div>

		<div class="twelve wide field" app-field [error]="validation | path: 'to'">
			<label>To</label>
			<div class="ui stackable grid">
				<div class="thirteen wide column">
					<div class="fields">
						<div class="six wide field">
							<sm-calendar class="ui calendar"
								[settings]="calendarSettings"
								[(value)]="nonRationedTransactionModel.to"
								[minValue]="nonRationedTransactionModel.from">
								<div class="ui fluid input left icon">
									<i class="calendar icon"></i>
									<input type="text" placeholder="Date" />
								</div>
							</sm-calendar>
						</div>
						<div class="field">
							<button class="ui fluid primary button"
								[disabled]="!isValid"
								(click)="onSubmit()">
								Submit
							</button>
						</div>
					</div>
				</div>

				<div class="three wide right floated column">
					<div class="field">
						<button class="ui right floated fluid green button"
							[disabled]="!tableData.length"
							(click)="exportReport()">
							<i class="file download icon"></i>
							Export Report
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="field">
		<app-table-view
			[data]="tableData"
			[dataReady]="tableDataReady"
			[columns]="tableColumns"
			[noSearch]="true"
			(pageChangeEvent)="pageChange($event)">
		</app-table-view>
	</div>
</div>