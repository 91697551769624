import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { ClientModel } from '@nstep-internal/pages';
import { Observable, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ClientsService {

	constructor(private apiService: ApiService) { }

	getClients(): Observable<any> {
		return this.apiService.get(Any, 'clients');
	}

	getClient(id: number): Observable<any> {
		return this.apiService.get(Any, `clients/${id}`);
	}

	createClient(model: ClientModel): Observable<any> {
		return this.apiService.post(Any, 'clients', model)
			.pipe(tap(() => this.invalidateCache()));
	}

	updateClient(model: ClientModel): Observable<any> {
		return this.apiService.putNoContent(`clients/${model.id}`, model)
			.pipe(tap(() => this.invalidateCache()));
	}

	deleteClient(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`clients/${id}`)
			.pipe(tap(() => this.invalidateCache()));;
	}

	toggleClient(id: number): Observable<any> {
		return this.apiService.postNoContent(`clients/${id}/toggle`)
			.pipe(tap(() => this.invalidateCache()));;
	}

	invalidateCache() {
		this.apiService.invalidateCache('clients');
	}
}
