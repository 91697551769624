import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any, PagedQueryParameter } from '@nstep-common/utils';
import { EntitlementAssignmentModel, MatrixDeltaModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AssignmentService {

	periodCycles = [{ Value: 7, Text: 'Weekly' }, { Value: 31, Text: 'Monthly' }, { Value: 365, Text: 'Yearly' }];

	constructor(private apiService: ApiService) { }

	getCodes(matrixVersionId: number, model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, `entitlement-assignment/${matrixVersionId}/codes`, { observe: 'response', params: model });
	}

	getTypes(matrixVersionId: number, model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, `entitlement-assignment/${matrixVersionId}/types`, { observe: 'response', params: model });
	}

	getUnassignedCodes(matrixVersionId: number): Observable<any> {
		return this.apiService.get(Any, `entitlement-assignment/${matrixVersionId}/codes/unassigned`);
	}

	getAssignmentsForCodeAndHeadquarter(matrixVersionId: number, codeId: number): Observable<any> {
		return this.apiService.get(Any, `entitlement-assignment/${matrixVersionId}/${codeId}`);
	}

	createMatrixElement(headquarterId: number, codeId: number, matrixVersionId: number, assignments: EntitlementAssignmentModel[]): Observable<any> {
		return this.apiService.post(Any, `entitlement-assignment/${headquarterId}/${codeId}/${matrixVersionId}`, assignments);
	}

	updateMatrixElement(headquarterId: number, codeId: number, matrixVersionId: number, assignments: EntitlementAssignmentModel[]): Observable<any> {
		return this.apiService.put(Any, `entitlement-assignment/${headquarterId}/${codeId}/${matrixVersionId}`, assignments);
	}

	getActiveCards(headquarterId: number, codeId: number): Observable<any> {
		return this.apiService.get(Any, `entitlement-assignment/active-cards/${headquarterId}/${codeId}`);
	}

	clearActiveCardsCache(headquarterId: number, codeId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/active-cards/${headquarterId}/${codeId}`);
	}

	disableMatrixElement(headquarterId: number, codeId: number, matrixVersionId: number | null = null): Observable<any> {
		return this.apiService.delete(Any, `entitlement-assignment/${headquarterId}/${codeId}/${matrixVersionId}`);
	}

	getAllTypes(matrixVersionId: number): Observable<any> {
		return this.apiService.get(Any, `entitlement-assignment/${matrixVersionId}/all-types`);
	}

	clearAllTypesCache(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/all-types`);
	}

	getAllCodes(matrixVersionId: number): Observable<any> {
		return this.apiService.get(Any, `entitlement-assignment/${matrixVersionId}/all-codes`);
	}

	clearAllCodesCache(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/all-codes`);
	}

	updateMatrix(headquarterId: number, matrixVersionId: number, model: MatrixDeltaModel): Observable<any> {
		return this.apiService.patch(Any, `entitlement-assignment/${headquarterId}/${matrixVersionId}`, model);
	}

	clearCodesCache(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/codes`);
	}

	clearAssignmentsForCodeAndHeadquarterCache(matrixVersionId: number, codeId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/${codeId}`);
	}

	clearTypesCache(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/types`);
	}

	clearUnassignedCodes(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/codes/unassigned`);
	}

	getActiveCardsBatches(activeCards: string[]): string[][][] {
		const columns: string[][] = [];
		const impactedActiveCardsBatches: string[][][] = [];

		const rowSize = 10;
		const columnSize = 3;

		while (activeCards.length > 0) {
			columns.push(activeCards.splice(0, rowSize));
		}

		while (columns.length > 0) {
			impactedActiveCardsBatches.push(columns.splice(0, columnSize));
		}

		return impactedActiveCardsBatches;
	}
}
