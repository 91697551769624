import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
	BaiscFilterSection,
	Filter,
	FilterChangeModel,
	FilterComparisonOperator,
	FilterType,
	ReportBaseComponent,
	ReportColumn,
	ReportColumnSortDirection,
	SortDirection
} from '@nstep-common/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { valueHasChanged } from '@nstep-common/utils';
import { chain, range } from 'lodash';

@Component({
	selector: 'app-report-view',
	templateUrl: './report-view.component.html',
})
export class ReportViewComponent extends ReportBaseComponent implements OnChanges {

	@Input() isCollapsing = false;
	@Input() isCelled = false;

	@Input() isSortable = true;
	@Input() isFilterable = true;
	@Input() hideUnhideColumns = true;
	@Input() isExportable = true;
	@Input() isDraggable = true;

	@Input() columns: ReportColumn[] = [];
	@Input() data: any[][] = [];
	@Input() dataReady = true;

	@Input() pageSize = 10;
	@Input() currentPage = 1;
	@Input() totalPages = 1;
	@Output() pageSizeChangeEvent = new EventEmitter<number>();
	@Output() pageChangeEvent = new EventEmitter();
	@Output() filterChangeEvent = new EventEmitter<FilterChangeModel>();
	@Output() exportExcelEvent = new EventEmitter();
	@Output() sortByColEvent = new EventEmitter<ReportColumnSortDirection>();

	filterDropdownSettings = {
		action: 'nothing',
		//direction: 'downward'
	};

	dateCalendarSettings = {
		type: 'date',
		formatter: {
			date: 'DD/MM/YYYY'
		}
	}

	dateTimeCalendarSettings = {
		formatter: {
			datetime: 'DD/MM/YYYY h:mm A'
		}
	}

	shownItems: any[][] = [];
	sortData: any[][] = [];

	pages: number[] = [];
	maxPagesCount = 5;

	itemsPerPageDropdownValues: DropdownOption[] = [
		new DropdownOption({ value: 10, name: "10" }),
		new DropdownOption({ value: 25, name: "25" }),
		new DropdownOption({ value: 50, name: "50" }),
	];

	draggedColumnIndex!: number;

	constructor() {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (valueHasChanged(changes['columns'])) {
			this.columns.filter(c => c.filterType !== this.FilterTypeEnum.Basic)
				.forEach(column => {
					column.allChecked = false;
					column.selectedItemsCount = 0
				});

			this.columns.filter(c => c.filterType === this.FilterTypeEnum.Basic)
				.forEach(column => {
					column.assignedFilters[0].showInput = !this.filtersWithoutValue.includes(column.assignedFilters[0].operator);
					column.assignedFilters[1].showInput = !this.filtersWithoutValue.includes(column.assignedFilters[1].operator);
				});
		}

		if (valueHasChanged(changes['data'])) {
			this.getShownItems();
		}
	}

	sortByCol(col: ReportColumn): void {
		const sortation = new ReportColumnSortDirection({
			name: col.name,
			sortDirection: col.sortDirection === null ? SortDirection.ASC : col.sortDirection === SortDirection.ASC ? SortDirection.DESC : null,
		});

		this.sortByColEvent.emit(sortation);
	}

	onShowColumnValueChange(): void {
		this.getShownItems();
	}

	dragStartColumn(index: number) {
		this.draggedColumnIndex = index;
	}

	dropColumn(index: number) {
		const cutOut = this.columns.splice(this.draggedColumnIndex, 1)[0];
		this.columns.splice(index, 0, cutOut);

		//remake order number
		for (let i = 0; i < this.columns.length; i++) {
			this.columns[i].orderNumber = i + 1;
		}

		this.getShownItems();
	}

	private getShownItems(): void {

		const shownColumnsKeys = chain(this.columns)
			.filter(c => c.isShown)
			.orderBy(c => c.orderNumber)
			.flatMap(c => c.key)
			.value();

		this.shownItems = [];

		this.data.forEach(row => {

			let newRow: any[] = [];

			shownColumnsKeys.forEach(key => {
				row.forEach(cell => {
					if (key === cell.key) {
						const newCell = { ...cell };
						newRow.push(newCell);
					}
				});
			});

			if (newRow.length) this.shownItems.push(newRow);
		});

		this.updatePages();
	}

	changePage(page: number) {
		if (page < 1 || page > this.totalPages) {
			return;
		}

		this.currentPage = page;

		this.pageChangeEvent.emit({ pageSize: this.pageSize, currentPage: this.currentPage, totalPages: this.totalPages });
	}

	private updatePages(): void {
		if (!this.pages.length) {
			this.pages = range(1, Math.min(this.totalPages + 1, this.maxPagesCount));
			return;
		}

		let min = Math.min(... this.pages);
		let max = Math.min(Math.max(... this.pages), this.totalPages);

		if (this.currentPage == min - 1) {
			min = Math.max(this.currentPage - this.maxPagesCount + 1, 1);
			max = this.currentPage;

			if (max - min < this.maxPagesCount) {
				max = Math.min(min + this.maxPagesCount - 1, this.totalPages - 1);
			}
		}

		if (this.currentPage == max + 1 || min == max) {
			min = this.currentPage;
			max = Math.min(this.currentPage + this.maxPagesCount - 1, this.totalPages);

			if (max - min < this.maxPagesCount) {
				min = Math.max(1, max - this.maxPagesCount + 1);
			}
		}

		this.pages = range(min, max + 1);
	}

	setCurrentOperator(col: ReportColumn, operator: FilterComparisonOperator, filterSection: BaiscFilterSection): void {
		switch (filterSection) {
			case BaiscFilterSection.First:
				col.assignedFilters[0].operator = operator;
				col.assignedFilters[0].value = '';
				col.assignedFilters[0].showInput = !this.filtersWithoutValue.includes(operator);
				break;
			case BaiscFilterSection.Second:
				col.assignedFilters[1].operator = operator;
				col.assignedFilters[1].value = '';
				col.assignedFilters[1].showInput = !this.filtersWithoutValue.includes(operator)
				break;
		}
	}

	onCheckAllFiltersValueChange(col: ReportColumn): void {
		col.assignedFilters?.forEach(filter => {
			filter.isChecked = col.allChecked!;
		});

		col.selectedItemsCount = col.assignedFilters!.filter(f => f.isChecked).length;
	}

	onCheckFilterValueChange(col: ReportColumn, filter: Filter): void {
		filter.value = filter.isChecked ? filter.text : '';

		col.allChecked = col.assignedFilters!.every(f => f.isChecked);
		col.selectedItemsCount = col.assignedFilters!.filter(f => f.isChecked).length;
	}

	applyColumnFilters(col: ReportColumn): void {

		if (col.filterType === FilterType.Basic && col.isDate) {
			col.assignedFilters?.forEach(filter => {
				filter.value = filter.dateValue && col.key === 'timestamp' ? formatDate(filter.dateValue, 'yyyy-MM-dd HH:mm', 'en') : filter.dateValue ? formatDate(filter.dateValue, 'yyyy-MM-dd', 'en') : ''
			});
		}

		this.filterChangeEvent.emit(new FilterChangeModel({ col: col }))
	}

	clearColumnFilters(col: ReportColumn): void {
		this.filterChangeEvent.emit(new FilterChangeModel({ col: col, clearColumnFilter: true }))
	}
}
