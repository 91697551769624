import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ExportTransactionsService {

	constructor(private apiService: ApiService) { }

	getMatrixVersions(): Observable<any> {
		return this.apiService.get(Any, 'export-transactions/matrix-versions');
	}

	exportTransactions(startDate: Date | null, endDate: Date | null, posLocationsIds: number[]): Observable<any> {
		const formattedStartDate = formatDate(startDate!, 'yyyy-MM-dd', 'en');
		const formattedEndDate = formatDate(endDate!, 'yyyy-MM-dd', 'en');

		return this.apiService.post(Any, `export-transactions/${formattedStartDate}/${formattedEndDate}`, posLocationsIds, { observe: 'response', responseType: 'blob' });
	}
}