import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ArchiveDataService {

	constructor(private apiService: ApiService) { }

	getAmisCards(searchString: string): Observable<any> {
		return this.apiService.get(Any, 'archive-data/amis-cards', {
			params: {
				searchString
			}
		});
	}

	filterOptions() {
		return this.apiService.get(Any, 'archive-data/filter-options');
	}

	downloadCSV(params: Partial<{ rationed: boolean, year: number, posLocationId: number | null, posOperatorId: number | null, cardId: number | null, entitlementTypeId: number | null, entitlementCodeId: number | null }>): Observable<any> {
		return this.apiService.get(Any, 'archive-data/download-csv', {
			params,
			observe: 'response', 
			responseType: 'blob'
		});
	}
}
