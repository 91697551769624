import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent, ContextMenuAction, ContextMenuItem, LayoutService } from '@nstep-common/core';
import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import {
	AssociationTypeCopyItem,
	CodeCopyItem,
	CompareColumnsModel,
	ConfirmationType,
	ContextMenuItemType,
	ContextMenuType,
	EntitlementAssignmentModel,
	EntitlementCodeDto,
	EntitlementCodeGroupDto,
	EntitlementCodeItem,
	EntitlementMatrixVersionHistoryDto,
	EntitlementMatrixView,
	EntitlementMatrixViewChangeModel,
	EntitlementTypeAssociationDto,
	EntitlementTypeAssociationItem,
	InitialValues,
	MatrixAdministrationCell,
	MatrixAdministrationCellValidator,
	MatrixAdministrationCodeDto,
	MatrixAdministrationGroup,
	MatrixAdministrationModel,
	MatrixAdministrationModelValidator,
	MatrixAdministrationRow,
	MatrixAssignModel,
	MatrixDeltaModel,
	MatrixVersionHistoryDto,
	TypeAssociationModalComponent,
	UnitAssociationDto
} from '@nstep-internal/pages';
import { AssignmentService, EntitlementMatrixService, EntitlementTypeAssociationService } from '@nstep-internal/shared';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten } from 'lodash';
import { Observable, concat, forkJoin, tap } from 'rxjs';

@Component({
	selector: 'app-matrix-administration',
	templateUrl: './matrix-administration.component.html',
})
export class MatrixAdministrationComponent extends BaseComponent implements OnInit {
	@Input() dataPropagation!: EntitlementMatrixViewChangeModel;
	@Output() dataPropagationChange = new EventEmitter<EntitlementMatrixViewChangeModel>();

	@ViewChild('addMatrixAdministrationCodeModal') addMatrixAdministrationCodeModal!: ModalComponent;
	@ViewChild(TypeAssociationModalComponent) typeAssociationModal!: TypeAssociationModalComponent;
	@ViewChild('matrixAdministrationConfirmationModal') matrixAdministrationConfirmationModal!: ModalComponent;
	currentConfirmationType: ConfirmationType | null = null;

	modalIsLoading = false;

	allMatrixVersions: MatrixVersionHistoryDto[] = [];
	activeMatrixVersion: string | null = null;

	previousMatrixVersionsDropdownValues: DropdownOption[] = [];
	selectedPreviousMatrixVersionId: number | null = null;

	entitlementCodesDropdownValues: DropdownOption[] = [];
	unassignedEntitlementCodes: EntitlementCodeDto[] = [];
	selectedUnassignedEntitlementCode: EntitlementCodeDto | undefined = undefined;
	impactedActiveCardsBatches: string[][][] = [];

	entitlementMatrixAdministrationTableData: any[] = [];
	tableDataReady = false;
	continueLoading = false;
	tableSize = '88em';
	isEmptyMatrix = false;

	comparisonColumns: CompareColumnsModel = { activeMatrix: {}, previousMatrix: {} };
	existingEntitlementTypeIds: number[] = [];

	missingActiveVersionColumns: string[] = [];

	activeMatrixVersionData: MatrixAdministrationRow[] = [];
	previousMatrixVersionData: MatrixAdministrationRow[] = [];

	typesToDisable: EntitlementTypeAssociationItem[] = [];
	codesToDisable: MatrixAssignModel[] = [];

	contextMenuItems: ContextMenuItem[] = [];

	contextMenuStyle: {} | null = null;
	shownContextMenu = false;
	contextMenuSelectedItem: ContextMenuItemType | null = null;

	associationTypeCopyItems: AssociationTypeCopyItem[] = [];
	codeCopyItem: CodeCopyItem | null = null;

	validation: ValidationErrors<MatrixAdministrationModel> = {};
	isValid: boolean = false;

	matrixAdministrationModel: MatrixAdministrationModel = createProxy(new MatrixAdministrationModel(), {
		set: () => this.validate(this.matrixAdministrationModel)
	})

	validate(value: MatrixAdministrationModel): void {
		const validator = new MatrixAdministrationModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	amountValidations: { [id: string]: ValidationErrors<MatrixAdministrationCell> } = {};
	validAmounts = true;

	validateAmount(value: MatrixAdministrationCell): void {
		const validator = new MatrixAdministrationCellValidator();
		this.amountValidations[value.validationId] = validator.validate(value);
		this.validAmounts = Object.entries(this.amountValidations).every(([key, value]) => Object.keys(value).length === 0);
	}

	constructor(private assignmentService: AssignmentService,
		private entitlementMatrixService: EntitlementMatrixService,
		private entitlementTypeAssociationService: EntitlementTypeAssociationService,
		private layoutService: LayoutService) {
		super();

		this.layoutService.layoutHasChanged.subscribe(layout => {
			this.tableSize = layout.isLargeMonitor ? '88em' : (layout.isRegularMonitor ? '67em' : '60em');
		});
	}

	ngOnInit(): void {
		this.subscriptions.push(this.init().subscribe());
	}

	private init(): Observable<any> {
		this.codesToDisable = [];

		const getEntitlementMatrixVersion$ = this.entitlementMatrixService.getEntitlementMatrixVersion(this.dataPropagation.headquarterId!, this.dataPropagation.selectedMatrixVersionId!)
			.pipe(tap((response: EntitlementMatrixVersionHistoryDto) => {

				const activeMatrixVersion = new MatrixVersionHistoryDto({
					id: response.id,
					version: `${response.majorVersion}.${response.minorVersion}`,
					startDate: response.startDate,
					endDate: response.endDate!,
				});

				this.activeMatrixVersion = this.entitlementMatrixService.getHeadquarterMatrixVersionFormat(activeMatrixVersion);
			}));

		const getAllEntitlementMatrixVersions$ = this.entitlementMatrixService.getAllEntitlementMatrixVersions(this.dataPropagation.headquarterId!, true)
			.pipe(tap((response) => {

				this.allMatrixVersions = chain(response.body as EntitlementMatrixVersionHistoryDto[])
					.map(e => new MatrixVersionHistoryDto({
						id: e.id,
						version: `${e.majorVersion}.${e.minorVersion}`,
						startDate: e.startDate,
						endDate: e.endDate!,
					}))
					.value();

				this.previousMatrixVersionsDropdownValues = chain(this.allMatrixVersions)
					.map(e => new DropdownOption({
						value: e.id,
						name: this.entitlementMatrixService.getHeadquarterMatrixVersionFormat(e)
					}))
					.value();
			}));

		const observables = [
			this.assignmentService.getAllTypes(this.dataPropagation.selectedMatrixVersionId!),
			this.assignmentService.getAllCodes(this.dataPropagation.selectedMatrixVersionId!),
			getEntitlementMatrixVersion$,
			getAllEntitlementMatrixVersions$
		];

		return forkJoin(observables)
			.pipe(tap((results: any) => {
				this.handleAllTypesResult(results[0]);
				this.handleAllCodesResult(results[1], false, this.continueLoading);
			}));
	}

	private handleAllTypesResult(result: EntitlementTypeAssociationDto[], previousMatrixVersion = false): void {
		const orderedResult = chain(result)
			.orderBy(d => d.entitlementTypeName)
			.value();

		this.comparisonColumns.previousMatrix = {};
		if (!previousMatrixVersion) this.comparisonColumns.activeMatrix = {};

		orderedResult.forEach(item => {
			if (!previousMatrixVersion) {
				this.comparisonColumns.activeMatrix[item.entitlementTypeName] = this.processTypeItem(item);

			} else {
				this.comparisonColumns.previousMatrix[item.entitlementTypeName] = this.processTypeItem(item);
			}
		});

		if (!previousMatrixVersion) {

			this.getExistingEntitlementTypeIds();

		} else {

			this.missingActiveVersionColumns = Object.keys(this.comparisonColumns.previousMatrix)
				.filter(c => !Object.keys(this.comparisonColumns.activeMatrix).includes(c))

			this.missingActiveVersionColumns.forEach(c => {
				const value = this.comparisonColumns.previousMatrix[c];

				this.comparisonColumns.activeMatrix[value.type] = new EntitlementTypeAssociationItem({
					rationingTypeId: value.rationingTypeId,
					entitlementTypeId: value.entitlementTypeId,
					type: value.type,
					entitlementIssuanceTypeId: value.entitlementIssuanceTypeId,
					deleted: true
				});
			})
		}
	}

	private handleAllCodesResult(result: MatrixAdministrationCodeDto[], previousMatrixVersion = false, continueLoading = false): void {

		const rows: MatrixAdministrationRow[] = [];

		result.forEach(resultItem => {

			const row = new MatrixAdministrationRow({
				entitlementCode: resultItem.entitlementCode,
				cells: [],
				isPreviousMatrixVersionRow: previousMatrixVersion,
				canBeAddedToActiveMatrix: previousMatrixVersion ? this.unassignedEntitlementCodes.some(d => d.fullCode === resultItem.entitlementCode.fullCode) : false,
			});

			const currentKeysOrder = Object.keys(this.comparisonColumns.activeMatrix);
			const newKeysOrder = chain(currentKeysOrder).orderBy(d => d).value();

			newKeysOrder.forEach(key => {
				const value = this.comparisonColumns.activeMatrix[key];

				const cell = new MatrixAdministrationCell({
					validationId: crypto.randomUUID(),
					rationingTypeId: value.rationingTypeId,
					entitlementTypeId: value.entitlementTypeId,
					entitlementTypeName: value.type,
					isDeleted: value.deleted
				});

				const currentAssignment = resultItem.assignments.find(d => d.entitlementTypeName === key);

				if (currentAssignment) {
					cell.id = currentAssignment.id;
					cell.checked = true;
					cell.amount = currentAssignment.unitCount ? currentAssignment.unitCount : 0;
				}

				cell.initialValues = new InitialValues({
					checked: cell.checked,
					amount: cell.amount
				})

				if (!previousMatrixVersion) {
					const validationItem: MatrixAdministrationCell = createProxy(cell, {
						set: () => this.validateAmount(cell)
					});

					row.cells.push(validationItem)
				} else {
					row.cells.push(cell);
				}

			});

			rows.push(row);
		});

		if (!previousMatrixVersion) {
			this.activeMatrixVersionData = rows;

			this.entitlementMatrixAdministrationTableData = chain(this.activeMatrixVersionData)
				.groupBy(d => d.entitlementCode.fullCode)
				.map((value, key) => new MatrixAdministrationGroup({
					key: key,
					activeMatrixVersion: value.find(x => !x.isPreviousMatrixVersionRow),
					previousMatrixVersion: value.find(x => x.isPreviousMatrixVersionRow)
				}))
				.orderBy(d => d.key)
				.value();
		} else {
			this.previousMatrixVersionData = rows;

			if (this.missingActiveVersionColumns.length) {

				const currentKeysOrder = Object.keys(this.comparisonColumns.activeMatrix);

				const newKeysOrder = chain(currentKeysOrder).orderBy(d => d).value();
				let rowCellsDataBackup: MatrixAdministrationCell[] = [];

				this.activeMatrixVersionData.forEach(row => {
					rowCellsDataBackup = [...row.cells];
					row.cells = [];

					newKeysOrder.forEach(key => {

						const cellBackup = rowCellsDataBackup.find(c => c.entitlementTypeName === key);

						if (cellBackup) {
							row.cells.push({ ...cellBackup })
						} else {
							const value = this.comparisonColumns.previousMatrix[key];

							row.cells.push(new MatrixAdministrationCell({
								asPlaceholder: true,
								isDeleted: true,
								entitlementTypeName: value.type,
								entitlementTypeId: value.entitlementTypeId,
							}))
						}
					});
				})
			}

			const groups = chain([...this.activeMatrixVersionData, ...this.previousMatrixVersionData])
				.groupBy(d => d.entitlementCode.fullCode)
				.map((value, key) => new MatrixAdministrationGroup({
					key: key,
					activeMatrixVersion: value.find(x => !x.isPreviousMatrixVersionRow),
					previousMatrixVersion: value.find(x => x.isPreviousMatrixVersionRow)
				}))
				.orderBy(d => d.key)
				.value();

			groups.forEach(group => {

				if (group.previousMatrixVersion) {
					group.previousMatrixVersion.expanded = true;
				}

				group.activeMatrixVersion?.cells.forEach(activeCell => {
					group.previousMatrixVersion?.cells.forEach(previousCell => {

						previousCell.asPlaceholder = !this.comparisonColumns.previousMatrix[previousCell.entitlementTypeName];

						if (activeCell.entitlementTypeId === previousCell.entitlementTypeId) {

							//rationingTypeId === 1 (rationed)
							if (activeCell.rationingTypeId === 1) {
								const condition = activeCell.checked != previousCell.checked || activeCell.checked === previousCell.checked && activeCell.amount != previousCell.amount;
								activeCell.isDifferent = condition;
								previousCell.isDifferent = condition;
							} else {
								const condition = activeCell.checked != previousCell.checked;
								activeCell.isDifferent = condition;
								previousCell.isDifferent = condition;
							}

						}
					});
				});
			})

			this.entitlementMatrixAdministrationTableData = groups;
		}

		this.isEmptyMatrix = this.isAdministerMatrixEmpty();

		this.tableDataReady = !continueLoading;
		if (continueLoading) this.continueLoading = false;
	}

	private processTypeItem(item: EntitlementTypeAssociationDto): EntitlementTypeAssociationItem {
		let period;
		if (item.rationingTypeId == 1) {
			switch (item.entitlementIssuanceTypeId) {
				case 1:
					period = item.periodCycle && item.periodCycle < 0 ? 'Once' : item.periodCycle === 0 ? 'Adjusted by Operator' : item.periodCycle;
					break;
				case 2:
					const periodCycle = this.assignmentService.periodCycles.find(p => p.Value === item.periodCycle)
					if (periodCycle) period = periodCycle.Text;
					break;
			}
		}

		return new EntitlementTypeAssociationItem({
			id: item.id,
			entitlementTypeId: item.entitlementTypeId,
			type: item.entitlementTypeName,
			periodsIssuedInAdvance: item.periodsIssuedInAdvance,
			periodsValid: item.periodsValid,
			startDate: item.startDate,
			endDate: item.endDate,
			periodCycle: item.periodCycle,
			period: period,
			entitlementIssuanceTypeId: item.entitlementIssuanceTypeId,
			rationingTypeId: item.rationingTypeId,
			measurementUnitId: item.entitlementMeasurementUnitsAssociation
				? item.entitlementMeasurementUnitsAssociation.entitlementMeasurementUnitId
				: null,
			entitlementMeasurementUnitAssociationId: item.entitlementMeasurementUnitAssociationId,
			unitDescription: item.entitlementMeasurementUnitsAssociation
				? item.entitlementMeasurementUnitsAssociation.entitlementMeasurementUnitDescription
				: null,
			unitOfMeasure: item.entitlementMeasurementUnitsAssociation
				? item.entitlementMeasurementUnitsAssociation.entitlementMeasurementUnitName
				: null,
			isBuyable: item.isBuyable
		});
	}

	compareMatrixVersions(init$: Observable<any> | null = null): void {
		this.tableDataReady = false;
		this.entitlementMatrixAdministrationTableData = [];

		//no need to remove caching for previous version since its in the past and can`t be changed;
		const requests = [
			this.assignmentService.getUnassignedCodes(this.dataPropagation.selectedMatrixVersionId!),
			this.assignmentService.getAllTypes(this.selectedPreviousMatrixVersionId!),
			this.assignmentService.getAllCodes(this.selectedPreviousMatrixVersionId!)
		];

		const fork$ = forkJoin(requests).pipe(tap((results: any) => {
			this.handleUnassignedCodesResult(results[0]);
			this.handleAllTypesResult(results[1], true);
			this.handleAllCodesResult(results[2], true);
		}));

		if (init$) {
			const concat$ = concat(init$, fork$);
			this.subscriptions.push(concat$.subscribe());
		}
		else {
			this.subscriptions.push(fork$.subscribe());
		}
	}

	private handleUnassignedCodesResult(result: EntitlementCodeDto[]): void {
		this.unassignedEntitlementCodes = result;
	}

	onCellValueChange(item: MatrixAdministrationGroup, activeCell: MatrixAdministrationCell): void {

		this.checkIfMatrixVersionCellHasChanged(activeCell);
		activeCell.amount = activeCell.checked === false ? 0 : activeCell.amount;

		if (!item.previousMatrixVersion) {
			return;
		}

		const previousMatrixVersionCell = item.previousMatrixVersion.cells.find(c => c.entitlementTypeId === activeCell.entitlementTypeId);

		if (previousMatrixVersionCell) {

			//rationingTypeId === 1 (rationed)
			if (activeCell.rationingTypeId === 1) {
				const condition = activeCell.checked != previousMatrixVersionCell.checked || activeCell.checked === previousMatrixVersionCell.checked && activeCell.amount != previousMatrixVersionCell.amount;
				activeCell.isDifferent = condition;
				previousMatrixVersionCell.isDifferent = condition;
			} else {
				const condition = activeCell.checked != previousMatrixVersionCell.checked;
				activeCell.isDifferent = condition;
				previousMatrixVersionCell.isDifferent = condition;
			}
		}
	}

	onFocus(item: MatrixAdministrationCell): void {
		if (item.amount === 0) item.amount = null;
	}

	onBlur(item: MatrixAdministrationCell): void {
		if (item.amount === null) item.amount = 0;
	}

	private checkIfMatrixVersionCellHasChanged(cell: MatrixAdministrationCell): void {
		cell.isModified = cell.checked != cell.initialValues?.checked || cell.amount != cell.initialValues.amount
	}

	showCancelChangesConfirmation(): void {
		this.currentConfirmationType = ConfirmationType.CancelChanges;
		this.matrixAdministrationConfirmationModal.toggle();
	}

	cancelChanges(): void {
		this.typesToDisable = [];
		this.codesToDisable = [];

		this.dataPropagationChange.emit(new EntitlementMatrixViewChangeModel({
			entitlementMatrixView: EntitlementMatrixView.Main
		}));

		this.matrixAdministrationConfirmationModal.toggle();
	}

	addToCurrentMatrixVersion(item: MatrixAdministrationGroup): void {

		item.activeMatrixVersion = new MatrixAdministrationRow({
			isNew: true,
			entitlementCode: item.previousMatrixVersion?.entitlementCode
		})

		item.previousMatrixVersion?.cells.forEach(cell => {

			cell.isDifferent = false;

			const activeCell = new MatrixAdministrationCell({
				validationId: crypto.randomUUID(),
				entitlementTypeId: cell.entitlementTypeId,
				entitlementTypeName: cell.entitlementTypeName,
				rationingTypeId: cell.rationingTypeId,
				checked: cell.checked,
				amount: cell.amount,
				isNew: true
			})

			const validationItem: MatrixAdministrationCell = createProxy(activeCell, {
				set: () => this.validateAmount(activeCell)
			});

			item.activeMatrixVersion?.cells.push(validationItem);
		});
	}

	showDeleteColumnConfirmation(): void {
		this.currentConfirmationType = ConfirmationType.DeleteColumn;
		this.matrixAdministrationConfirmationModal.toggle();
	}

	deleteType(column: any): void {
		const entitlementTypeAssociationItem = this.comparisonColumns.activeMatrix[column.key];

		if (entitlementTypeAssociationItem.id) {
			this.typesToDisable.push(entitlementTypeAssociationItem)
		}

		const columnExistsInPreviousVersion = !!this.comparisonColumns.previousMatrix[column.key];

		if (!columnExistsInPreviousVersion) {
			delete this.comparisonColumns.activeMatrix[column.key];
		} else {
			entitlementTypeAssociationItem.deleted = true;
		}

		this.getExistingEntitlementTypeIds();

		if (!columnExistsInPreviousVersion) {
			this.entitlementMatrixAdministrationTableData.forEach((group: MatrixAdministrationGroup) => {
				group.activeMatrixVersion?.cells.forEach((cell, index) => {
					if (cell.entitlementTypeId === entitlementTypeAssociationItem.entitlementTypeId) {
						group.activeMatrixVersion?.cells.splice(index, 1);
					}
				});

				group.previousMatrixVersion?.cells.forEach((cell, index) => {
					if (cell.entitlementTypeId === entitlementTypeAssociationItem.entitlementTypeId) {
						group.previousMatrixVersion?.cells.splice(index, 1);
					}
				});
			});
		} else {
			this.entitlementMatrixAdministrationTableData.forEach((group: MatrixAdministrationGroup) => {
				group.activeMatrixVersion?.cells.forEach(cell => {
					if (cell.entitlementTypeId === entitlementTypeAssociationItem.entitlementTypeId) {
						cell.isDeleted = true;
						cell.asPlaceholder = true;
					}
				});
			});
		}

		this.matrixAdministrationConfirmationModal.toggle();
	}

	private getExistingEntitlementTypeIds(): void {
		this.existingEntitlementTypeIds = [];

		Object.keys(this.comparisonColumns.activeMatrix).forEach(key => {
			const value = this.comparisonColumns.activeMatrix[key];
			if (!value.deleted) this.existingEntitlementTypeIds.push(value.entitlementTypeId);

		});
	}

	showDeleteRowConfirmation(): void {
		this.currentConfirmationType = ConfirmationType.DeleteRow;
		this.getActiveCardsData();
		this.matrixAdministrationConfirmationModal.toggle();
	}

	private getActiveCardsData(): void {

		this.modalIsLoading = true;
		this.impactedActiveCardsBatches = [];
		this.assignmentService.clearActiveCardsCache(this.dataPropagation.headquarterId!, this.contextMenuSelectedItem!.item.activeMatrixVersion.entitlementCode.id);

		this.subscriptions.push(
			this.assignmentService.getActiveCards(this.dataPropagation.headquarterId!, this.contextMenuSelectedItem!.item.activeMatrixVersion.entitlementCode.id).subscribe({
				next: (response: string[]) => {
					this.impactedActiveCardsBatches = this.assignmentService.getActiveCardsBatches(response);
					this.modalIsLoading = false;
				},
				error: () => {
					this.modalIsLoading = false;
				}
			})
		);
	}

	deleteCode(item: MatrixAdministrationGroup): void {

		if (item.activeMatrixVersion && !item.activeMatrixVersion.isNew) {
			this.codesToDisable.push(new MatrixAssignModel({
				codeId: item.activeMatrixVersion.entitlementCode.id,
				assignments: chain(item.activeMatrixVersion.cells)
					.map(cell => new EntitlementAssignmentModel({
						entitlementTypeAssociationId: this.comparisonColumns.activeMatrix[cell.entitlementTypeName].id,
					}))
					.value()
			}))
		}

		item.activeMatrixVersion = null;
		this.isEmptyMatrix = this.isAdministerMatrixEmpty();
		this.matrixAdministrationConfirmationModal.toggle();
	}

	save(): void {

		if (this.checkSaveRequirements()) return;

		this.tableDataReady = false;

		let groupedRequests: { [id: string]: Observable<any>[] } = {};
		groupedRequests['create'] = [];
		groupedRequests['revoke'] = [];

		const columnKeyOrderDictionary: { [id: number]: string } = {};
		let index = 0;

		const currentKeysOrder = Object.keys(this.comparisonColumns.activeMatrix);
		const newKeysOrder = chain(currentKeysOrder).orderBy(d => d).value();

		// Saves all new columns (types)
		newKeysOrder.forEach(key => {
			const entitlementTypeAssociationItem: EntitlementTypeAssociationItem = this.comparisonColumns.activeMatrix[key];

			const model = entitlementTypeAssociationItem.rationingTypeId === 1 ? new EntitlementTypeAssociationDto({
				entitlementTypeId: entitlementTypeAssociationItem.entitlementTypeId,
				entitlementMeasurementUnitsAssociation: new UnitAssociationDto({
					entitlementMeasurementUnitDescription: entitlementTypeAssociationItem.unitDescription!,
					entitlementMeasurementUnitId: entitlementTypeAssociationItem.measurementUnitId!
				})
			}) : new EntitlementTypeAssociationDto({ entitlementTypeId: entitlementTypeAssociationItem.entitlementTypeId })

			if (!entitlementTypeAssociationItem.id && !entitlementTypeAssociationItem.deleted) {

				groupedRequests['create'].push(this.entitlementTypeAssociationService.createTypeAssociation(this.dataPropagation.headquarterId!, this.dataPropagation.selectedMatrixVersionId, model));

				columnKeyOrderDictionary[index] = key;
				index++;
			}
		});

		// Saves all disabled columns (types)
		this.typesToDisable.forEach(type => {
			groupedRequests['revoke'].push(this.entitlementTypeAssociationService.revokeTypeAssociation(this.dataPropagation.headquarterId!, type.id, this.dataPropagation.selectedMatrixVersionId))
		});

		const requests = [...groupedRequests['create'], ...groupedRequests['revoke']];

		this.subscriptions.push(
			forkJoin(requests).subscribe({
				next: (results: any[]) => {

					results.forEach((result, index) => {
						if (index < groupedRequests['create'].length) {
							this.comparisonColumns.activeMatrix[columnKeyOrderDictionary[index]].id = result;
						}
					});

					this.processDataAndUpdateMatrix();
				},
				error: (response: any) => {

					this.tableDataReady = true;

					const errors: string[] = flatten(Object.values(response));
					toast('Error', `Save action encountered a problem! ${errors[0]}`, 'red')
				}
			})
		);

		if (!requests.length) this.processDataAndUpdateMatrix();
	}

	private checkSaveRequirements(): boolean {
		let hasWarnings = false;

		this.entitlementMatrixAdministrationTableData
			.filter(group => group.activeMatrixVersion)
			.every((group: MatrixAdministrationGroup) => {

				group.activeMatrixVersion!.cells.every(cell => {

					if (cell.rationingTypeId === 1 && cell.checked && cell.amount! < 0) {
						toast('Warning', 'Please select a positive amount for the checked rationed entitlement types!', 'orange');
						hasWarnings = true;
						return false;
					}

					return true;
				})

				if (hasWarnings) return false;

				const atleastOneChecked = group.activeMatrixVersion!.cells.some(d => d.checked);

				if (!atleastOneChecked) {
					toast('Warning', `Please check at least one entitlement type for Code ${group.key}`, 'orange');
					hasWarnings = true;
					return false;
				}

				return true;
			});

		return hasWarnings;
	}

	private creatEntitlementAssginmentModel(entitlementCode: EntitlementCodeDto, cell: MatrixAdministrationCell): EntitlementAssignmentModel {
		return new EntitlementAssignmentModel({
			entitlementTypeAssociationId: this.comparisonColumns.activeMatrix[cell.entitlementTypeName].id,
			unitCount: cell.amount,
			entitlementCodeGroup: new EntitlementCodeGroupDto({
				group: entitlementCode.group,
				description: entitlementCode.description
			})
		})
	}

	private processDataAndUpdateMatrix(): void {
		const model = new MatrixDeltaModel();

		model.disabledAssigns = this.codesToDisable;

		this.entitlementMatrixAdministrationTableData
			.filter(group => group.activeMatrixVersion)
			.forEach((group: MatrixAdministrationGroup) => {

				const created = new MatrixAssignModel({
					codeId: group.activeMatrixVersion!.entitlementCode.id,
					assignments: []
				})

				const modified = new MatrixAssignModel({
					codeId: group.activeMatrixVersion!.entitlementCode.id,
					assignments: []
				})

				const deleted = new MatrixAssignModel({
					codeId: group.activeMatrixVersion!.entitlementCode.id,
					assignments: []
				})

				group.activeMatrixVersion!.cells.forEach(cell => {

					if (cell.isNew && cell.checked) {
						created.assignments.push(this.creatEntitlementAssginmentModel(group.activeMatrixVersion!.entitlementCode, cell));
					} else if (cell.isModified && cell.checked) {
						modified.assignments.push(this.creatEntitlementAssginmentModel(group.activeMatrixVersion!.entitlementCode, cell));
					} else if (cell.isModified && !cell.checked) {
						deleted.assignments.push(new EntitlementAssignmentModel({ entitlementTypeAssociationId: this.comparisonColumns.activeMatrix[cell.entitlementTypeName].id }));
					}
				});

				if (created.assignments.length) model.createdAssigns.push(created);
				if (modified.assignments.length) model.modifiedAssigns.push(modified);
				if (deleted.assignments.length) model.disabledAssigns.push(deleted);
			});

		this.subscriptions.push(
			this.assignmentService.updateMatrix(this.dataPropagation.headquarterId!, this.dataPropagation.selectedMatrixVersionId!, model).subscribe({
				next: (response: EntitlementMatrixVersionHistoryDto) => {

					this.typesToDisable = [];
					this.codesToDisable = [];
					this.dataPropagation.selectedMatrixVersionId = response.id;

					this.refreshAdministerMatrixDataForCurrentState();

					toast('Success', 'The changes have been successfully saved!', 'green');
				},
				error: () => {
					this.tableDataReady = true;
				}
			}));
	}

	openAddMatrixAdministrationCodeModal(): void {

		this.matrixAdministrationModel.entitlementCodeId = null;
		this.matrixAdministrationModel.entitlementCodeGroup = null;
		this.matrixAdministrationModel.entitlementCodeDescription = null;

		this.entitlementCodesDropdownValues = [];

		const existingEntitlementCodes = (this.entitlementMatrixAdministrationTableData as MatrixAdministrationGroup[])
			.flatMap(d => d.activeMatrixVersion && d.activeMatrixVersion.entitlementCode.fullCode);

		this.assignmentService.clearUnassignedCodes(this.dataPropagation.selectedMatrixVersionId!);

		this.subscriptions.push(
			this.assignmentService.getUnassignedCodes(this.dataPropagation.selectedMatrixVersionId!).subscribe({
				next: (response: EntitlementCodeDto[]) => {

					this.unassignedEntitlementCodes = chain(response)
						.filter(d => !existingEntitlementCodes.includes(d.fullCode))
						.value();

					if (!this.unassignedEntitlementCodes.length) {
						toast('Warning', 'There are no unassigned codes available. Please go to Entitlement Codes page and create a new code.', 'orange');
						return;
					}

					this.entitlementCodesDropdownValues = chain(this.unassignedEntitlementCodes)
						.map(e => new DropdownOption({
							value: e.id,
							name: `${e.majorCode}.${e.minorCode}`
						}))
						.value();

					this.addMatrixAdministrationCodeModal.toggle();
				}
			})
		);
	}

	onEntitlementCodeValueChange(): void {
		this.selectedUnassignedEntitlementCode = this.unassignedEntitlementCodes.find(d => d.id === this.matrixAdministrationModel.entitlementCodeId)!;

		this.matrixAdministrationModel.entitlementCodeGroup = this.selectedUnassignedEntitlementCode.group;
		this.matrixAdministrationModel.entitlementCodeDescription = this.selectedUnassignedEntitlementCode.description;
	}

	addEntitlementCode(): void {

		const matrixAdministrationGroup = (this.entitlementMatrixAdministrationTableData as MatrixAdministrationGroup[]).find(d => d.key === this.selectedUnassignedEntitlementCode!.fullCode);

		if (!matrixAdministrationGroup) {

			const newGroup = new MatrixAdministrationGroup({
				key: this.selectedUnassignedEntitlementCode!.fullCode,
				activeMatrixVersion: new MatrixAdministrationRow({
					entitlementCode: this.mapEntitlementCodeItem(),
					isNew: true,
					cells: [],
					expanded: true
				})
			});

			const cells = this.getCellsData(this.selectedUnassignedEntitlementCode!.isDependent);
			newGroup.activeMatrixVersion?.cells.push(...cells);

			this.entitlementMatrixAdministrationTableData.push(newGroup);

		} else {
			matrixAdministrationGroup.activeMatrixVersion = new MatrixAdministrationRow({
				entitlementCode: this.mapEntitlementCodeItem(),
				isNew: true,
				cells: [],
				expanded: true
			})

			const cells = this.getCellsData(this.selectedUnassignedEntitlementCode!.isDependent);
			matrixAdministrationGroup.activeMatrixVersion?.cells.push(...cells);
		}

		this.isEmptyMatrix = this.isAdministerMatrixEmpty();

		this.addMatrixAdministrationCodeModal.toggle();
	}

	private mapEntitlementCodeItem(): EntitlementCodeItem {
		return new EntitlementCodeItem({
			id: this.selectedUnassignedEntitlementCode!.id,
			group: this.matrixAdministrationModel.entitlementCodeGroup!,
			description: this.matrixAdministrationModel.entitlementCodeDescription!,
			majorCode: this.selectedUnassignedEntitlementCode!.majorCode,
			minorCode: this.selectedUnassignedEntitlementCode!.minorCode,
			fullCode: this.selectedUnassignedEntitlementCode!.fullCode,
			isDependent: this.selectedUnassignedEntitlementCode!.isDependent,
		})
	}


	private getCellsData(isDependent: boolean): MatrixAdministrationCell[] {

		const cells: MatrixAdministrationCell[] = [];

		const isCopyMode = !!this.codeCopyItem;

		const currentKeysOrder = Object.keys(this.comparisonColumns.activeMatrix);
		const newKeysOrder = chain(currentKeysOrder).orderBy(d => d).value();

		newKeysOrder.forEach(key => {
			const value = this.comparisonColumns.activeMatrix[key];

			const cell = new MatrixAdministrationCell({
				validationId: crypto.randomUUID(),
				rationingTypeId: value.rationingTypeId,
				entitlementTypeId: value.entitlementTypeId,
				entitlementTypeName: value.type,
				isNew: !value.deleted,
				asPlaceholder: value.deleted,
				isDeleted: value.deleted
			});

			if (isCopyMode) {
				const currentCellToCopy = this.codeCopyItem!.cells.find(d => d.entitlementTypeId === cell.entitlementTypeId)!;

				cell.amount = cell.rationingTypeId === 1 && !this.dataPropagation.generateDependentRations && !isDependent ? currentCellToCopy.amount : 0;
				cell.checked = currentCellToCopy.checked;
			}

			cell.initialValues = new InitialValues({
				checked: cell.checked,
				amount: cell.amount
			})

			const validationItem: MatrixAdministrationCell = createProxy(cell, {
				set: () => this.validateAmount(cell)
			});

			cells.push(validationItem)
		})

		return cells;
	}

	onPreviousMatrixVersionValueChange(): void {

		if (!this.selectedPreviousMatrixVersionId) {
			this.entitlementMatrixAdministrationTableData.forEach((group: MatrixAdministrationGroup) => {
				group.previousMatrixVersion = null
			});
		}
	}

	addColumn(item: EntitlementTypeAssociationItem): void {

		const existsAsDeleted = this.comparisonColumns.activeMatrix[item.type] && this.comparisonColumns.activeMatrix[item.type].deleted;

		if (existsAsDeleted) {
			delete this.comparisonColumns.activeMatrix[item.type];
		}

		let currentKeysOrder = Object.keys(this.comparisonColumns.activeMatrix);

		currentKeysOrder.push(item.type);

		const newKeysOrder = chain(currentKeysOrder).orderBy(d => d).value();
		const activeMatrixColumnsBackup = JSON.parse(JSON.stringify(this.comparisonColumns.activeMatrix));

		this.comparisonColumns.activeMatrix = {};

		let currentKeyPosition: number = 0;

		newKeysOrder.forEach((key, index) => {
			if (key === item.type) {
				this.comparisonColumns.activeMatrix[key] = item;
				currentKeyPosition = index;
			} else {
				this.comparisonColumns.activeMatrix[key] = activeMatrixColumnsBackup[key];
			}
		});

		const isCopyMode = !!this.associationTypeCopyItems.length;

		this.entitlementMatrixAdministrationTableData.forEach((group: MatrixAdministrationGroup) => {

			const activeMatrixVersionCell = new MatrixAdministrationCell({
				validationId: crypto.randomUUID(),
				rationingTypeId: item.rationingTypeId,
				entitlementTypeId: item.entitlementTypeId,
				entitlementTypeName: item.type,
				isNew: true
			});

			if (isCopyMode && group.activeMatrixVersion) {
				const currentCellToCopy = this.associationTypeCopyItems.find(d => d.entitlementFullCode === group.activeMatrixVersion!.entitlementCode.fullCode)!

				activeMatrixVersionCell.amount = activeMatrixVersionCell.rationingTypeId === 1 ? currentCellToCopy.cell!.amount : 0;
				activeMatrixVersionCell.checked = currentCellToCopy.cell!.checked;
			}

			activeMatrixVersionCell.initialValues = new InitialValues({
				checked: activeMatrixVersionCell.checked,
				amount: activeMatrixVersionCell.amount
			})

			group.activeMatrixVersion?.cells.splice(currentKeyPosition, existsAsDeleted ? 1 : 0, activeMatrixVersionCell);

			const previousVersionCellValue = group.previousMatrixVersion?.cells.find(c => c.entitlementTypeName === item.type);

			const previousMatrixVersionCell = new MatrixAdministrationCell({
				rationingTypeId: item.rationingTypeId,
				entitlementTypeId: item.entitlementTypeId,
				entitlementTypeName: item.type,
				asPlaceholder: !this.missingActiveVersionColumns.includes(item.type),
				amount: previousVersionCellValue ? previousVersionCellValue.amount : null,
				checked: previousVersionCellValue ? previousVersionCellValue.checked : false
			});

			group.previousMatrixVersion?.cells.splice(currentKeyPosition, existsAsDeleted ? 1 : 0, previousMatrixVersionCell);
		});

		this.associationTypeCopyItems = [];
		this.getExistingEntitlementTypeIds();
		this.typeAssociationModal.isLoading = false;
		this.typeAssociationModal.toggle();
	}

	reset(): void {
		this.selectedPreviousMatrixVersionId = null;
		this.typesToDisable = [];
		this.codesToDisable = [];

		this.subscriptions.push(this.refreshTableData().subscribe());
	}

	refreshAdministerMatrixDataForCurrentState(): void {
		this.continueLoading = !!this.selectedPreviousMatrixVersionId;
		const refreshTableData$ = this.refreshTableData();

		if (this.selectedPreviousMatrixVersionId) {
			this.compareMatrixVersions(refreshTableData$);
		}
		else {
			this.subscriptions.push(refreshTableData$.subscribe());
		}
	}

	refreshTableData(): Observable<any> {
		this.assignmentService.clearAllTypesCache(this.dataPropagation.selectedMatrixVersionId!);
		this.assignmentService.clearAllCodesCache(this.dataPropagation.selectedMatrixVersionId!);

		this.tableDataReady = false;
		this.entitlementMatrixAdministrationTableData = [];

		return this.init();
	}

	openContextMenu(event: any, item: any, isRowItem = false): void {

		this.contextMenuItems = isRowItem ? [
			{ actionName: 'Copy Row', actionValue: ContextMenuAction.Copy },
			{ actionName: 'Delete Row', actionValue: ContextMenuAction.Delete }
		] : [
			{ actionName: 'Copy Column', actionValue: ContextMenuAction.Copy },
			{ actionName: 'Delete Column', actionValue: ContextMenuAction.Delete }
		];

		this.contextMenuSelectedItem = new ContextMenuItemType({
			type: isRowItem ? ContextMenuType.Row : ContextMenuType.Column,
			item: item
		});

		this.contextMenuStyle = {
			position: 'fixed',
			left: `${event.clientX}px`,
			top: `${event.clientY}px`
		}

		this.shownContextMenu = true;
	}

	onContextMenuItemClick(action: ContextMenuAction): void {
		switch (action) {
			case ContextMenuAction.Copy:
				switch (this.contextMenuSelectedItem!.type) {
					case ContextMenuType.Column:
						this.copyType(this.contextMenuSelectedItem!.item);
						break;
					case ContextMenuType.Row:
						this.copyCode(this.contextMenuSelectedItem!.item);
						break;
				}
				break;
			case ContextMenuAction.Delete:
				switch (this.contextMenuSelectedItem!.type) {
					case ContextMenuType.Column:
						this.showDeleteColumnConfirmation();
						break;
					case ContextMenuType.Row:
						this.showDeleteRowConfirmation();
						break;
				}
				break;
		}
	}

	copyType(column: any): void {
		this.associationTypeCopyItems = [];
		this.typeAssociationModal.openAddTypeAssociationModal({}, true);

		this.entitlementMatrixAdministrationTableData.forEach((group: MatrixAdministrationGroup) => {
			group.activeMatrixVersion?.cells.forEach(cell => {
				if (cell.entitlementTypeId === this.comparisonColumns.activeMatrix[column.key].entitlementTypeId) {
					this.associationTypeCopyItems.push(new AssociationTypeCopyItem({
						entitlementFullCode: group.activeMatrixVersion!.entitlementCode.fullCode,
						cell: { ...cell }
					}))
				}
			});
		});
	}

	copyCode(item: MatrixAdministrationGroup): void {
		this.codeCopyItem = null;
		this.openAddMatrixAdministrationCodeModal();

		this.codeCopyItem = new CodeCopyItem({
			entitlementFullCode: item.activeMatrixVersion!.entitlementCode.fullCode,
			cells: [...item.activeMatrixVersion!.cells]
		});
	}

	private isAdministerMatrixEmpty(): boolean {
		return !this.entitlementMatrixAdministrationTableData.length ||
			this.entitlementMatrixAdministrationTableData
				.every((group: MatrixAdministrationGroup) => group.activeMatrixVersion === null && group.previousMatrixVersion === null);
	}

	@HostListener('document:click')
	documentClick(): void {
		this.shownContextMenu = false;
	}
}
