import { Injectable } from '@angular/core';
import { Any, PagedQueryParameter } from '@nstep-common/utils';
import { ApiService } from '@nstep-common/core';
import { EntitlementUnitModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EntitlementUnitService {

	constructor(private apiService: ApiService) { }

	getEntitlementUnits(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'entitlement-unit', { observe: 'response', params: model });
	}

	getEnabledEntitlementUnits(): Observable<any> {
		return this.apiService.get(Any, 'entitlement-unit/enabled');
	}

	createEntitlementUnit(model: EntitlementUnitModel): Observable<EntitlementUnitModel> {
		return this.apiService.post(EntitlementUnitModel, 'entitlement-unit', model);
	}

	updateEntitlementUnit(model: EntitlementUnitModel): Observable<EntitlementUnitModel> {
		return this.apiService.put(EntitlementUnitModel, `entitlement-unit/${model.id}`, model);
	}

	enableEntitlementUnit(id: number): Observable<any> {
		return this.apiService.patchNoContent(`entitlement-unit/${id}`);
	}

	disableEntitlementUnit(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`entitlement-unit/${id}`);
	}

	clearGetEntitlementUnitsCache(): void {
		this.apiService.invalidateCache('entitlement-unit');
	}
}
