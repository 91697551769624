import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { hasChanged } from '@nstep-common/utils';
import { chain } from 'lodash';
import { DropdownOption } from 'projects/common/semantic-ui/components/dropdown/dropdown-option';

@Component({
	selector: 'sm-multiple-select',
	template: `<ng-content></ng-content>`
})
export class MultipleSelectComponent implements AfterViewInit, OnDestroy, OnChanges {
	jQueryElement: any;

	@Input() values: any[] = [];
	@Output() valuesChange = new EventEmitter<any[]>();

	@Input() options: DropdownOption[] = [];

	preventCycle = false;

	constructor(private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		this.jQueryElement = $(this.elementRef.nativeElement);

		setTimeout(() => this.jQueryElement.dropdown({
			values: this.options,
			allowAdditions: true,
			showOnFocus: false,
			onChange: (values: string) => {
				this.preventCycle = true;

				const split = values.split(",");;

				this.values = chain(this.options)
					.filter(o => split.includes(o.value.toString()))
					.map(o => o.value)
					.value();

				this.valuesChange.emit(this.values);
			}
		}));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (hasChanged(changes['values']) && !this.preventCycle) {
			for (const option of this.options) {
				(option as any).selected = this.values.find(v => option.value == v) != undefined;
			}

			this.jQueryElement?.dropdown('set selected', this.values);

			if (!this.values.length || this.values === undefined || this.values === null) {
				this.jQueryElement?.dropdown('clear', true);
			}
		}

		if (hasChanged(changes['options'])) {
			this.jQueryElement?.dropdown('change values', this.options);
		}

		this.preventCycle = false;
	}

	ngOnDestroy(): void {
		this.jQueryElement.dropdown('destroy');
	}
}