import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { AuthService, LogService } from "@nstep-common/core";
import { environment } from "projects/internal/src/environments/environment";

@Injectable({
	providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private logger: LogService, private authService: AuthService, private ngZone: NgZone) { }

	handleError(error: any) {
		if (environment.target == "local") {
			this.logger.warn('Application has crashed (skipping log out because of "local" environment).', error);
			return;
		}
		else {
			this.logger.fatal('Application has crashed.', error);
		}

		this.ngZone.run(() => {
			this.authService.logOut(true);
		});
	}
}