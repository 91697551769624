import { Injectable } from '@angular/core';
import { Any, PagedQueryParameter } from '@nstep-common/utils';
import { ApiService } from '@nstep-common/core';
import { EntitlementCodeModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EntitlementCodeService {

	constructor(private apiService: ApiService) { }

	getEntitlementCodes(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'entitlement-code', { observe: 'response', params: model });
	}

	createEntitlementCode(model: EntitlementCodeModel): Observable<EntitlementCodeModel> {
		const postModel = {
			id: model.id,
			majorCode: Number(model.majorCode),
			minorCode: Number(model.minorCode),
			group: model.group,
			description: model.description,
			isDependent: model.isDependent,
			headquarterId: model.headquarterId
		};

		return this.apiService.post(EntitlementCodeModel, 'entitlement-code', postModel);
	}

	updateEntitlementCode(model: EntitlementCodeModel): Observable<EntitlementCodeModel> {
		const putModel = {
			id: model.id,
			majorCode: Number(model.majorCode),
			minorCode: Number(model.minorCode),
			group: model.group,
			description: model.description,
			isDependent: model.isDependent,
			headquarterId: model.headquarterId
		};

		return this.apiService.put(EntitlementCodeModel, `entitlement-code/${model.id}`, putModel);
	}

	clearGetEntitlementCodesCache(): void {
		this.apiService.invalidateCache('entitlement-code');
	}
}
