export class RoleModel {
	id!: number;
	name!: string;
	description!: string | null;
	isDisabled!: boolean;
	claims: string[] = [];

	constructor(data?: Partial<RoleModel> | null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}