import { ExtraValidator } from "@nstep-common/utils";
import { RoleModel } from "@nstep-internal/pages";

export class RoleValidator extends ExtraValidator<RoleModel> {
	constructor() {
		super();

		this.ruleFor('name')
			.must(name => !!name)
			.withMessage('Name is required')
			.notEmpty();

		this.ruleFor('description')
			.must(description => !!description)
			.withMessage('Description is required')
			.notEmpty();
	}
}