<div *ngIf="hideUnhideColumns || isExportable" class="ui top attached stackable grid segment" style="background: #f9fafb">
	<div class="column">
		<div *ngIf="hideUnhideColumns" class="ui secondary vertical menu" style="margin-bottom: 0; display: -webkit-inline-box;">
			<div sm-simple-dropdown [settings]="filterDropdownSettings" class="ui dropdown item">
				<i class="dropdown icon"></i>
				Display Columns
				<div class="menu">
					<div class="scrolling menu">
						<div *ngFor="let col of columns" class="item">
							<div class="ui checkbox" style="margin-top: 0px;">
								<input type="checkbox" id="report-column-{{col.key}}" [(ngModel)]="col.isShown" (change)="onShowColumnValueChange()">
								<label for="report-column-{{col.key}}">{{col.name}}</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<button *ngIf="isExportable" class="ui basic right floated right labeled icon button"
			(click)="exportExcelEvent.emit()">
			Export Excel
			<i class="file export icon"></i>
		</button>
	</div>
</div>
<div class="ui attached segment" style="overflow-x: auto; padding: 0;">
	<table class="ui table"
		[ngClass]="{
			'collapsing': isCollapsing,
			'celled': isCelled
		}"
		style="font-size: 0.96em; border: 0; white-space: nowrap;">
		<thead>
			<tr>

				<ng-container *ngFor="let col of columns; let i = index">
					<th *ngIf="col.isShown" style="text-align: center;"
						[draggable]="isDraggable"
						(dragstart)="dragStartColumn(i)"
						(dragover)="isDraggable ? $event.preventDefault() : null"
						(drop)="dropColumn(i)">
						<ng-template
							[ngTemplateOutlet]="colTemplateRef"
							[ngTemplateOutletContext]="{ col }">
						</ng-template>
					</th>
				</ng-container>

			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let rows of shownItems">
				<td *ngFor="let cell of rows" style="text-align: center">
					{{cell.value | dash}}
				</td>
			</tr>
			<tr *ngIf="!shownItems.length">
				<td style="text-align: center; padding: 0; height: 300px;"
					[colSpan]="columns.length"
					[ngStyle]="{'padding': dataReady ? '10px' : '0'}"
					app-loading
					[enabled]="!dataReady">
					No data available.
				</td>
			</tr>
		</tbody>
		<tfoot>
			<tr>
			</tr>
		</tfoot>
	</table>
</div>
<div class="ui bottom attached grid segment" style="background: #f9fafb">
	<div class="column">
		<!--<label>Items per page:&nbsp;&nbsp;</label>
		<sm-dropdown class="ui selection dropdown" style="min-width: auto;"
			[value]="pageSize"
			[options]="itemsPerPageDropdownValues"
			(valueChange)="pageSizeChangeEvent.emit($event)">
			<div class="text"></div>
			<i class="dropdown icon"></i>
		</sm-dropdown>-->

		<div class="ui right floated pagination menu">
			<a class="icon item"
				[class.disabled]="currentPage == 1"
				(click)="changePage(currentPage - 1)">
				<i class="left chevron icon"></i>
			</a>
			<a class="item"
				[class.active]="page == currentPage"
				*ngFor="let page of pages"
				(click)="changePage(page)">
				<span class="ui text"
					[class.primary]="page == currentPage"
					[ngStyle]="{'font-weight': page == currentPage ? 'bolder' : 'lighter' }">
					{{page}}
				</span>
			</a>
			<a class="icon item"
				[class.disabled]="currentPage == totalPages"
				(click)="changePage(currentPage + 1)">
				<i class="right chevron icon"></i>
			</a>
		</div>
	</div>
</div>

<ng-template #colTemplateRef let-col="col">
	<strong [ngStyle]="{'cursor': isSortable ? 'pointer' : null }" (click)="isSortable ? sortByCol(col) : null">
		{{ col.name }}&nbsp;&nbsp;
	</strong>

	<ng-container *ngIf="col.key">
		<span *ngIf="isSortable" class="ui text" [ngStyle]="{'cursor': isSortable ? 'pointer' : null }" (click)="isSortable ? sortByCol(col) : null"
			[class.primary]="col.sortDirection !== null">
			{{col.sortOrder}}
			<i class="arrow icon"
				style="font-size: 1.2em; margin-right: 0.25em; width: 1.18em"
				[class.disabled]="!dataReady"
				[ngClass]="{ 
						'down': col.sortDirection === SortDirection.ASC, 
						'up':  col.sortDirection === SortDirection.DESC, 
						'sort':  col.sortDirection === null 
					}">
			</i>
		</span>

		<div *ngIf="isFilterable" sm-simple-dropdown [settings]="filterDropdownSettings" class="ui dropdown" #filterDropdown>
			<i class="filter icon" [class.primary]="col.hasFilterApplied"></i>
			<div *ngIf="col.filterType === FilterTypeEnum.Basic" class="menu" style="width: 16rem">
				<div class="header" style="text-transform: none; font-size: inherit;">
					Show items with value that:
				</div>

				<div class="divider"></div>

				<div class="item">
					<i class="dropdown icon"></i>
					{{col.assignedFilters[0].operator | startCase}}
					<div class="menu">
						<div *ngFor="let operator of col.validOperators" class="item"
							[ngClass]="{
								'active': col.assignedFilters[0].operator === operator,
								'selected': col.assignedFilters[0].operator === operator
							}"
							(click)="setCurrentOperator(col, operator, BaiscFilterSection.First)">
							{{operator | startCase}}
						</div>
					</div>
				</div>

				<div *ngIf="col.assignedFilters[0].showInput"
					class="ui input" style="display: block; margin-top: 0.5em; margin-bottom: 0.5em;">
					<input *ngIf="!col.isDate" type="text" [(ngModel)]="col.assignedFilters[0].value" placeholder="Value" app-trim>

					<sm-calendar *ngIf="col.isDate" class="ui calendar"
						[settings]="col.key === 'timestamp' ? dateTimeCalendarSettings : dateCalendarSettings"
						[(value)]="col.assignedFilters[0].dateValue">
						<div class="ui fluid input left icon">
							<i class="calendar icon"></i>
							<input type="text" placeholder="Value" />
						</div>
					</sm-calendar>
				</div>

				<div class="divider"></div>

				<div class="item">
					<i class="dropdown icon"></i>
					{{col.agregateOperator }}
					<div class="menu">
						<div *ngFor="let operator of agregateOperators" class="item"
							[ngClass]="{
								'active': col.agregateOperator === operator,
								'selected': col.agregateOperator === operator
							}"
							(click)="col.agregateOperator = operator">
							{{operator}}
						</div>
					</div>
				</div>

				<div class="divider"></div>

				<div class="item">
					<i class="dropdown icon"></i>
					{{col.assignedFilters[1].operator | startCase }}
					<div class="menu">
						<div *ngFor="let operator of col.validOperators" class="item"
							[ngClass]="{
								'active': col.assignedFilters[1].operator === operator,
								'selected': col.assignedFilters[1].operator === operator
							}"
							(click)="setCurrentOperator(col, operator, BaiscFilterSection.Second)">
							{{operator | startCase}}
						</div>
					</div>
				</div>

				<div *ngIf="col.assignedFilters[1].showInput"
					class="ui input" style="display: block; margin-top: 0.5em; margin-bottom: 0.5em;">
					<input *ngIf="!col.isDate" type="text" [(ngModel)]="col.assignedFilters[1].value" placeholder="Value" app-trim>

					<sm-calendar *ngIf="col.isDate" class="ui calendar"
						[settings]="dateCalendarSettings"
						[(value)]="col.assignedFilters[1].dateValue">
						<div class="ui fluid input left icon">
							<i class="calendar icon"></i>
							<input type="text" placeholder="Value" />
						</div>
					</sm-calendar>
				</div>

				<div class="divider"></div>

				<div class="ui input" style="margin-top: 0.5em; margin-bottom: 0.5em;">
					<div class="ui primary button" (click)="applyColumnFilters(col); filterDropdown.hide()">Filter</div>
					<div class="ui basic button" (click)="clearColumnFilters(col); filterDropdown.hide()">Clear</div>
				</div>
			</div>

			<div *ngIf="col.filterType === FilterTypeEnum.List" class="menu">
				<ng-container *ngIf="col.assignedFilters && col.assignedFilters.length">
					<div class="item">
						<div class="ui checkbox" style="margin-top: 0px;">
							<input type="checkbox" id="{{col.key}}-filter-all" [(ngModel)]="col.allChecked" (change)="onCheckAllFiltersValueChange(col)">
							<label for="{{col.key}}-filter-all">Select all</label>
						</div>
					</div>
					<div class="scrolling menu" style="max-height: 15rem;">
						<div *ngFor="let filter of col.assignedFilters; let i = index" class="item" style="min-height: 0; padding-top: 0.3rem; padding-bottom: 0.3rem">
							<div class="ui checkbox" style="margin-top: 0px;">
								<input type="checkbox" id="{{col.key}}-filter-{{i}}" [(ngModel)]="filter.isChecked" (change)="onCheckFilterValueChange(col, filter)">
								<label for="{{col.key}}-filter-{{i}}">{{filter.text}}</label>
							</div>
						</div>
					</div>
					<div class="divider"></div>
					<div class="header" style="text-transform: none; font-size: inherit; margin-top: 0;">
						{{col.selectedItemsCount}} items selected
					</div>

					<div class="ui input" style="margin-top: 0.5em; margin-bottom: 0.5em;">
						<div class="ui primary button" (click)="applyColumnFilters(col); filterDropdown.hide()">Filter</div>
						<div class="ui basic button" (click)="clearColumnFilters(col); filterDropdown.hide()">Clear</div>
					</div>
				</ng-container>

				<div *ngIf="!col.assignedFilters.length" class="ui info message">
					<div class="header">Info</div>
					<p>No data available.</p>
				</div>
			</div>

			<div *ngIf="col.filterType === FilterTypeEnum.Bool" class="menu">
				<ng-container *ngIf="col.assignedFilters && col.assignedFilters.length">
					<div class="item">
						<div class="ui checkbox" style="margin-top: 0px;">
							<input type="checkbox" id="{{col.key}}-filter-all" [(ngModel)]="col.allChecked" (change)="onCheckAllFiltersValueChange(col)">
							<label for="{{col.key}}-filter-all">Select all</label>
						</div>
					</div>
					<div class="scrolling menu">
						<div *ngFor="let filter of col.assignedFilters; let i = index" class="item" style="min-height: 0; padding-top: 0.3rem; padding-bottom: 0.3rem">
							<div class="ui checkbox" style="margin-top: 0px;">
								<input type="checkbox" id="{{col.key}}-filter-{{i}}" [(ngModel)]="filter.isChecked" (change)="onCheckFilterValueChange(col, filter)">
								<label for="{{col.key}}-filter-{{i}}">{{filter.text}}</label>
							</div>
						</div>
					</div>
					<div class="divider"></div>
					<div class="header" style="text-transform: none; font-size: inherit; margin-top: 0;">
						{{col.selectedItemsCount}} items selected
					</div>

					<div class="ui input" style="margin-top: 0.5em; margin-bottom: 0.5em;">
						<div class="ui primary button" (click)="applyColumnFilters(col); filterDropdown.hide()">Filter</div>
						<div class="ui basic button" (click)="clearColumnFilters(col); filterDropdown.hide()">Clear</div>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</ng-template>