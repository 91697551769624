<ng-container *ngIf="currentReportingView === ReportingViews.Main">
	<h3 class="ui header">
		<i class="chart pie icon"></i>
		<div class="content">
			Reporting
			<div class="sub header">Reports</div>
		</div>
	</h3>
	<br />

	<div app-loading [enabled]="!tableDataReady" class="ui form">
		<div class="field">
			<app-table-view
				[itemTemplate]="reportingItemTemplate"
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true">
			</app-table-view>
		</div>
	</div>

	<ng-template #reportingItemTemplate let-item="item">
		<tr style="text-align: center;">
			<td>
				<a href="javascript:void(0);" (click)="onEditAction(item)">{{item.name | dash}}</a>
			</td>
			<td>
				<div *ngFor="let role of item.availableRoles">{{role | dash}}</div>
			</td>
			<td>{{item.country | dash}}</td>
			<td>{{item.description | dash}}</td>
		</tr>
	</ng-template>
</ng-container>

<ng-container *ngIf="currentReportingView === ReportingViews.Report">
	<h3 class="ui header">
		<i class="exchange alternate icon"></i>
		<div class="content">
			Report: {{selectedTableItem.name}}
			<div class="sub header">Reports</div>
		</div>
	</h3>
	<br />

	<div class="ui stackable grid">
		<div class="row">
			<div class="column">
				<button class="ui basic labeled icon button"
					[disabled]="loading"
					(click)="currentReportingView = ReportingViews.Main">
					Back
					<i class="arrow left icon"></i>
				</button>
			</div>
		</div>

		<div app-loading [enabled]="loading" class="row">
			<div class="column">
				<app-report-view
					[isCelled]="true"
					[isSortable]="reportSettings.canSort"
					[isFilterable]="reportSettings.canFilter"
					[hideUnhideColumns]="reportSettings.canHideColumns"
					[isExportable]="reportSettings.canExport"
					[isDraggable]="reportSettings.canReorderColumns"
					[pageSize]="reportPagination.pageSize"
					[currentPage]="reportPagination.currentPage"
					[totalPages]="reportPagination.totalPages"
					[data]="reportData"
					[dataReady]="reportDataReady"
					[columns]="reportColumns"
					(pageChangeEvent)="pageChange($event)"
					(exportExcelEvent)="exportExcelTemplate()"
					(pageSizeChangeEvent)="null"
					(sortByColEvent)="sortByCol($event)"
					(filterChangeEvent)="filterChange($event)">
				</app-report-view>
			</div>
		</div>
	</div>
</ng-container>