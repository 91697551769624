import { ExtraValidator } from "@nstep-common/utils";
import { EntitlementCodeModel } from "@nstep-internal/pages";

export class EntitlementCodeModelValidator extends ExtraValidator<EntitlementCodeModel> {
	constructor() {
		super();

		this.ruleFor('majorCode')
			.must(majorCode => !!majorCode)
				.withMessage('Major code is required')
			.matches(/^[1-9][0-9]?[0-9]?[0-9]?$/)
				.withMessage('Maximum 4 digits number starting from code 1');

		this.ruleFor('minorCode')
			.must(minorCode => !!minorCode)
				.withMessage('Minor code is required')
			.matches(/^[1-9][0-9]?[0-9]?$/)
				.withMessage('Maximum 3 digits number starting from code 1');

		this.ruleFor('headquarterId')
			.must(headquarterId => !!headquarterId)
				.withMessage('Headquarter is required');

		this.ruleFor('group')
			.must(group => !!group)
				.withMessage('Group is required')
			.notEmpty()
			.matches(/^.{2,100}$/)
				.withMessage('A valid input is required with the length between 2-100 characters')

		this.ruleFor('description')
			.must(description => !!description)
				.withMessage('Description is required')
			.notEmpty()
			.matches(/^.{2,100}$/)
				.withMessage('A valid input is required with the length between 2-100 characters');
	}
}