import moment from "moment";
import { LogEntry } from "projects/common/utils/logger/log-entry";
import { LogLevel } from "projects/common/utils/logger/log-level";
import { LogPublisher } from "projects/common/utils/logger/log-publisher";
import { Observable, of } from "rxjs";

export class LogConsole extends LogPublisher {
	log(record: LogEntry): Observable<boolean> {
		var caller = this.getCaller(5, true);

		var colors: any = {};
		colors[LogLevel.All] = 'slategray';
		colors[LogLevel.Debug] = 'green';
		colors[LogLevel.Error] = 'crimson';
		colors[LogLevel.Fatal] = 'red';
		colors[LogLevel.Info] = 'blue';
		colors[LogLevel.Warn] = 'orange';

		var level = record.level == LogLevel.All ? '' : LogLevel[record.level].toUpperCase();

		console.groupCollapsed(`LOG (${moment(record.entryDate).format('DD/MM/YY HH:mm:ss')}) %c${level ? level + ' ' : '' }%c${record.message} %c\nfrom ${caller}`,
			`color: ${colors[record.level]}`,
			'font-weight: normal; color: black',
			'font-weight: normal; color: gray');


		var details = record.extraInfo.length == 1 ? record.extraInfo[0] : record.extraInfo;		
		console.info('Details:', details);

		console.groupEnd();

		return of(true);
	}
}