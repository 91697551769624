import { UserSettings } from "@nstep-common/core";
import { ExtraValidator } from "@nstep-common/utils";

export class UserSetingsValidator extends ExtraValidator<UserSettings> {
	constructor() {
		super();

		this.ruleFor('email')
			.emailAddress()
			.withMessage("Invalid email format.")
			.when(m => !!m.email);

		this.ruleFor('telephone')
			.matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
			.withMessage('Phone number is not valid')
			.when(m => !!m.telephone)

		this.ruleFor('newPassword')
			.matches(/^.{8,20}$/)
			.withMessage('Length must be between 8-20 characters')
			.matches(/^(?=(.*\d){2,})(?=.*[$@#-\/:-?{-~!^_'\[\]]{1,})(?=(.*[A-Z]){1,})(?=.*\w).{8,20}$/)
			.withMessage('Your password strength is too low. You must have at least 2 digits, 1 special character and 1 uppercase letter')			
			.when(m => !!m.newPassword);

		this.ruleFor('repeatPassword')
			.must((value, model) => model.newPassword == value)
			.withMessage("Passwords do not match")
			.when(m => !!m.newPassword);
	}
}