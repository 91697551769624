import { JsonProperty } from "@nstep-common/utils";

export class PaginationModel {

	@JsonProperty()
	totalCount!: number;

	@JsonProperty()
	pageSize!: number;

	@JsonProperty()
	currentPage!: number;

	@JsonProperty()
	totalPages!: number;

	constructor(data: Partial<PaginationModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}