import { Any, JsonProperty } from "@nstep-common/utils";
import 'reflect-metadata';

export class CardBalanceOnline {
	@JsonProperty()
	amisCardId!: number;

	@JsonProperty()
	amisCardNumber!: string;

	@JsonProperty()
	headquarterId!: number;

	@JsonProperty()
	headquarter!: string;

	@JsonProperty()
	entitlementCodeId!: number;

	@JsonProperty()
	entitlementCode!: string;

	@JsonProperty()
	entitlementTypeId!: number;

	@JsonProperty()
	entitlementType!: string;

	@JsonProperty({ type: Number, nullable: true })
	measurementUnitId!: number | null;

	@JsonProperty()
	measurementUnit!: string;

	@JsonProperty()
	rationed!: boolean;

	@JsonProperty()
	balance!: string;

	@JsonProperty({ type: Any, nullable: true })
	issuance!: any | null;
}

export class GroupedCardBalanceOnline {
	@JsonProperty()
	cardNumber!: string;

	@JsonProperty({ type: CardBalanceOnline, array: true })
	entitlements!: CardBalanceOnline[];
}

export class UserCardBalanceModel {
	@JsonProperty({ type: GroupedCardBalanceOnline, array: true })
	items!: GroupedCardBalanceOnline[];

	@JsonProperty()
	expirationDate!: string;

	@JsonProperty()
	expirationDays!: number;
}
