import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any, PagedQueryParameter } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ReportService {

	constructor(private apiService: ApiService) { }

	getNonRationedTransactions(startDate: string, endDate: string, model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, `reports/${startDate}/${endDate}`, { observe: 'response', params: model });
	}

	exportNonRationedTransactionsReport(startDate: string, endDate: string): Observable<any> {
		return this.apiService.get(Any, `reports/export/${startDate}/${endDate}`, { observe: 'response', responseType: 'blob' });
	}

	getReportSettings(reportId: number): Observable<any> {
		return this.apiService.get(Any, `reports/${reportId}`);
	}

	getReports(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'reports', { observe: 'response', params: model });
	}
}